import { CopyOutlined } from '@ant-design/icons'
import { addBreadcrumb, convertIsoToLocaleString } from '@gokwik/utilities'
import { formatAmount } from '@library/utilities/helpers/helper'
import { Col, ColumnProps, Row, Tag, Tooltip } from 'gokwik-ui-kit'
import { Link } from 'react-router-dom'
import { handleCopy } from './link-details/data'
import styles from './styles.module.css'

const statusToColorMapping = {
    created: 'default',
    paid: 'success',
    cancelled: 'error',
    expired: 'error',
}

export const transactionColumnsInternal: ColumnProps<any>[] = [
    {
        title: <span>Order #</span>,
        dataIndex: 'order_number',
        width: 180,
        ellipsis: true,
        render: (text: string) => (
            <Link
                to={`/checkout/orders/${text}`}
                onClick={() =>
                    addBreadcrumb({
                        key: 'order-details',
                        href: `/checkout/orders/${text}`,
                        text,
                    })
                }
            >
                <a style={{ width: 'min-content' }}>
                    <Tooltip placement='topLeft' title={text}>
                        {text.toUpperCase()}
                    </Tooltip>
                </a>
            </Link>
        ),
        fixed: 'left',
    },
    {
        title: <span>Payment ID</span>,
        dataIndex: 'payment_id',
        render: (text: string) => {
            return <>{text.toUpperCase()}</>
        },
        width: 200,
        fixed: 'left',
    },
    {
        title: <span>Phone</span>,
        dataIndex: 'phone',
    },
    {
        title: <span>Merchant Name</span>,
        dataIndex: 'Merchant.business_name',
    },
    {
        title: <span>Payment Mode</span>,
        dataIndex: 'payment_method',
    },
    {
        title: <span>Payment Provider</span>,
        dataIndex: 'payment_provider',
    },
    {
        title: <span>Bank Status</span>,
        dataIndex: 'bank_status',
    },
    {
        title: <span>Amount</span>,
        dataIndex: 'amount',
        render: (e) => <p>{formatAmount(e,2)}</p>,
    },
    {
        title: <span>Auto Refund</span>,
        dataIndex: 'is_auto_refund',
        render: (e) => <p>{e.toString()}</p>,
    },
    {
        title: <span>Is Deleted</span>,
        dataIndex: 'is_deleted',
    },
    {
        title: <span>Created At</span>,
        dataIndex: 'created_at',
        render: (e) => convertIsoToLocaleString(e),
    },
    {
        title: <span>Updated At</span>,
        dataIndex: 'updated_at',
        render: (e) => convertIsoToLocaleString(e),
    },
    {
        title: <span>Settlement At</span>,
        dataIndex: 'settlement.bank_timestamp',
        render: (e) => convertIsoToLocaleString(e),
    },
    {
        title: <span>Merchant ID</span>,
        dataIndex: 'merchant_id',
    },
    {
        title: <span>Customer ID</span>,
        dataIndex: 'customer_id',
    },
    {
        title: <span>Cust Ref ID</span>,
        dataIndex: 'cust_ref_id',
    },
    {
        title: <span>Failure Code</span>,
        dataIndex: 'failure_code',
    },
    {
        title: <span>Response Code</span>,
        dataIndex: 'response_code',
    },
    {
        title: <span>Timeout Status</span>,
        dataIndex: 'timeout_status',
    },
    {
        title: <span>Status Description</span>,
        dataIndex: 'status_description',
        render: (text) => (
            <Tooltip title={text}>
                <p>{text?.slice(0, 20)}</p>
            </Tooltip>
        ),
    },
    {
        title: <span>Status</span>,
        dataIndex: 'status',
    },
    {
        title: <span>Is Blocked</span>,
        dataIndex: 'is_blocked',
    },
    {
        title: <span>Short Link</span>,
        dataIndex: 'short_link',
    },
    {
        title: <span>UPI Link</span>,
        dataIndex: 'upi_link',
        render: (text) => (
            <Tooltip title={text}>
                <a className='link'>{text?.slice(0, 10)}</a>
            </Tooltip>
        ),
    },
    {
        title: <span>NPC ID</span>,
        dataIndex: 'npc_id',
    },
    {
        title: <span>Settlement id</span>,
        dataIndex: 'settlement_id',
    },
    {
        title: <span>Merchant mid</span>,
        dataIndex: 'Merchant.m_id',
    },
    {
        title: <span>Card Network</span>,
        dataIndex: 'cardNetwork',
    },
    {
        title: <span>Card Issuer</span>,
        dataIndex: 'cardIssuer',
    },
]

export const paymentLinkColumns: ColumnProps<any>[] = [
    {
        title: <span>Reference ID</span>,
        dataIndex: 'merchant_reference_id',
        width: 180,
        ellipsis: true,
        render: (text: string) => (
            <Link
                to={`/payment/payment-links/${text}`}
                onClick={() =>
                    addBreadcrumb({
                        key: 'payment-link-details',
                        href: `/payment/payment-links/${text}`,
                        text,
                    })
                }
            >
                <a style={{ width: 'min-content' }}>
                    <Tooltip placement='topLeft' title={text}>
                        {text.toUpperCase()}
                    </Tooltip>
                </a>
            </Link>
        ),
        fixed: 'left',
    },
    {
        title: <span>Payment Link ID</span>,
        dataIndex: 'payment_link_id',
    },
    {
        title: <span>Amount</span>,
        dataIndex: 'amount',
        render: (e) => <p>{formatAmount(e)}</p>,
    },

    {
        title: <span>Phone</span>,
        dataIndex: 'masked_phone',
    },
    {
        title: <span>Email</span>,
        dataIndex: 'masked_email',
    },
    {
        title: <span>Payment Link</span>,
        dataIndex: 'url',
        ellipsis: true,
        render: (t, record) =>
            t ? (
                <Row gutter={8} className='text-center flex items-center group'>
                    <Col>
                        <a target='_blank' href={t} className='group-hover:text-[13px]'>
                            {t}
                        </a>
                    </Col>
                    <Col className='hidden group-hover:flex group-hover:flex-row group-hover:top-0 group-hover:right-0'>
                        <CopyOutlined onClick={() => handleCopy(t)} className='text-[#004B8D] fs20' />
                    </Col>
                </Row>
            ) : (
                ''
            ),
    },

    {
        title: <span>Status</span>,
        dataIndex: 'status',
        render: (e) => (
            <Tag
                className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs capitalize w-full text-center`}
                variant={statusToColorMapping[e.toLowerCase()]}
            >
                {e}
            </Tag>
        ),
    },
    {
        title: <span>Created At</span>,
        dataIndex: 'created_at',
        render: (e) => convertIsoToLocaleString(e),
    },
    {
        title: <span>Created By</span>,
        dataIndex: 'created_by',
    },
]
