import {
    Table,
    message,
    ColumnProps,
    Row,
    Button,
    TagTwoTone,
    Tag,
    Tabs,
    Col,
    Select,
    Input,
    PlusCircleOutlined,
    Modal,
    Switch,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'
import AddRouting from './addRouting'
import { MethodsType, RouteInterface, SelectDropdown } from '@library/utilities/interface'

export default function () {
    const [terminalRoute, setTerminalRoute] = useState<RouteInterface[]>([])
    const [methods, setMethods] = useState<MethodsType[]>([])
    const [instruments, setInstruments] = useState<SelectDropdown[]>([])
    const merchantData = useSelector(getMerchantDetails)
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])
    const [openAddDrawer, setOpenAddDrawer] = useState<boolean>(false)
    const [currentTab, setCurrentTab] = useState<string>('')
    const [terminalIdToDelete, setTerminalIdToDelete] = useState<string | null>(null)

    const columns: ColumnProps<any>[] = [
        {
            title: <span>Instrument</span>,
            width: 200,
            fixed: 'left',
            render: (e) => e?.name ?? ''
        },
        {
            title: <span>Status</span>,
            width: 120,
            dataIndex: 'active',
            render: (e) => {
                return (
                    <span className='text-sm'>
                        <Tag
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                            variant={e ? 'success' : 'error'}
                        >
                            {e ? 'Active' : 'Inactive'}
                        </Tag>
                    </span>
                )
            },
        },
        {
            title: <span>Primary Provider</span>,
            width: 200,
            render: (e, record) => {
                const splitIndex = getTerminalIndexByType(e?.terminals, 'primary')
                let terminal = null
                if (splitIndex !== -1) {
                    terminal = e.terminals[splitIndex]
                }
                return (
                    <Select
                        className='w-[90%]'
                        placeholder='Select provider'
                        onChange={(value) => updateProviderName(e.id, value, 'primary')}
                        options={e?.providers ?? []}
                        value={terminal?.provider}
                        disabled={!selectedRowKeys.includes(record.id)}
                    />
                )
            },
        },
        {
            title: <span>Backup Provider</span>,
            width: 200,
            render: (e, record) => {
                const splitIndex = getTerminalIndexByType(e?.terminals, 'backup')
                let terminal = null
                if (splitIndex !== -1) {
                    terminal = e.terminals[splitIndex]
                }
                return (
                    <Select
                        className='w-[90%]'
                        placeholder='Select '
                        onChange={(value) => updateProviderName(e.id, value, 'backup')}
                        options={e?.providers ?? []}
                        value={terminal?.provider}
                        disabled={!selectedRowKeys.includes(record.id)}
                    />
                )
            },
        },
        {
            title: <span>Backup Share</span>,
            width: 120,
            render: (e, record) => {
                const splitIndex = getTerminalIndexByType(e?.terminals, 'backup')
                let terminal = null
                if (splitIndex !== -1) {
                    terminal = e.terminals[splitIndex]
                }
                return (
                    <div className='flex gap-2 items-center'>
                        <Input
                            placeholder='Split percentage'
                            value={terminal?.split ?? ''}
                            onChange={(event) => updateSplitPercentage(e.id, event.target.value, terminal?.provider)}
                            disabled={!selectedRowKeys.includes(record.id)}
                        />
                        %
                    </div>
                )
            },
        },
        {
            title: <span>Actions</span>,
            dataIndex: 'id',
            align: 'center',
            width: 100,
            render: (id, record) => {
                return (
                    <Row className='m-0 flex justify-center items-center'>
                        <Switch
                            checked={record?.active ?? false}
                            onClick={() => handleTerminalUpdation(id, !record?.active, record)}
                            disabled={selectedRowKeys.includes(record?.id)}
                        />
                    </Row>
                )
            },
        },
    ]

    useEffect(() => {
        getMethods()
    }, [])

    useEffect(() => {
        if (methods.length > 0) {
            setCurrentTab(methods[0].method)
            getRoutes(methods[0].method)
            getInstruments(methods[0].method)
        }
    }, [methods.length])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/payment/settings',
                text: 'Payment Settings',
            },
            {
                key: 'routing',
                href: '/payment/settings/routing',
                text: 'Routing',
            },
        ])
    }, [])

    async function handleTerminalUpdation(id: string, status: boolean, route: RouteInterface) {
        if (status) {
            const { id, merchant_id, providers, name, ...restOtherDetails } = route
            const updatedTerminals = restOtherDetails.terminals.map((terminal) => {
                const { type, name, ...terminalWithoutType } = terminal
                if (type === 'backup') {
                    // Ensure backup split is a number
                    const backupSplit = parseFloat(terminal.split as string)
                    return { ...terminalWithoutType, split: backupSplit / 100, deleted: false }
                } else if (type === 'primary') {
                    // Calculate primary split as 1 - backup split
                    const backupTerminal = route.terminals.find((t) => t.type === 'backup')
                    const backupSplit = backupTerminal ? parseFloat(backupTerminal.split as string) : 0
                    return { ...terminalWithoutType, split: (100 - backupSplit) / 100, deleted: false }
                }
            })
            const payload = { ...restOtherDetails, deleted: false, terminals: updatedTerminals, active: true }
            try {
                let response = await makeAPICall({
                    method: 'put',
                    url:
                        process.env.REACT_APP_AUX_BASE_URL +
                        APIEndPoints.routing.updateRoute(merchantData.m_id, route.id),
                    payload,
                })
                if (response.data?.success) {
                    message.success('Routes updated successfully')
                    getRoutes(route.method)
                    onSelectChange([])
                } else {
                    message.error(response?.response?.data?.error?.message ?? 'Failed to update routes')
                }
            } catch (error) {
                handleError(error)
            }
        } else {
            setTerminalIdToDelete(id)
        }
    }

    function updateSplitPercentage(routeId: number, newSplit: string, provider: string) {
        if (!provider) {
            message.error('Please select the provider')
        }
        setTerminalRoute((prevRoutes) =>
            prevRoutes.map((route) => {
                if (route.id === routeId) {
                    let updatedTerminals = [...route.terminals]
                    const splitIndex = route.terminals.findIndex((terminal) => terminal.provider === provider)
                    if (splitIndex !== -1) {
                        updatedTerminals[splitIndex].split = newSplit
                    } else {
                        updatedTerminals.push({
                            provider: '',
                            split: newSplit,
                        })
                    }
                    return { ...route, terminals: updatedTerminals }
                }
                return route
            }),
        )
    }

    function updateProviderName(routeId: number, newProvider: string, type: 'primary' | 'backup') {
        setTerminalRoute((prevRoutes) =>
            prevRoutes.map((route) => {
                if (route.id === routeId) {
                    let updatedTerminals = [...route.terminals]
                    const splitIndex = getTerminalIndexByType(updatedTerminals, type)
                    // Check if the new provider is not the same as the primary terminal's provider
                    const primaryTerminal = updatedTerminals.find((t) => t.type === 'primary')
                    let isNotAllowed = false
                    if (type === 'backup' && primaryTerminal && primaryTerminal.provider === newProvider) {
                        message.error('Backup provider cannot be the same as the primary provider')
                        isNotAllowed = true
                    }
                    if (type === 'primary' || splitIndex !== -1) {
                        updatedTerminals[splitIndex].provider = isNotAllowed ? '' : newProvider
                    } else {
                        updatedTerminals.push({
                            provider: isNotAllowed ? '' : newProvider,
                            split: '',
                            type: 'backup',
                        })
                    }
                    return { ...route, terminals: updatedTerminals }
                }
                return route
            }),
        )
    }

    function getTerminalIndexByType(terminals = [], type = 'primary') {
        if (terminals.length === 0 || (terminals.length === 1 && type === 'backup')) return -1
        const terminalIndex = terminals.findIndex((t) => t.type === type)
        return terminalIndex
    }

    async function updateRoute() {
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        // Update the split values for primary and backup terminals
        let routeId
        const updatedTerminalRoute = terminalRoute
            .filter((route) => selectedRowKeys.includes(route.id))
            .map((route) => {
                routeId = route.id
                const { id, merchant_id, providers, name, ...restOtherDetails } = route
                const updatedTerminals = restOtherDetails.terminals.map((terminal) => {
                    const { type, name,  ...terminalWithoutType } = terminal
                    if (type === 'backup') {
                        // Ensure backup split is a number
                        const backupSplit = parseFloat(terminal.split as string)
                        return { ...terminalWithoutType, split: backupSplit / 100, deleted: false }
                    } else if (type === 'primary') {
                        // Calculate primary split as 1 - backup split
                        const backupTerminal = route.terminals.find((t) => t.type === 'backup')
                        const backupSplit = backupTerminal ? parseFloat(backupTerminal.split as string) : 0
                        return { ...terminalWithoutType, split: (100 - backupSplit) / 100, deleted: false }
                    }
                    return terminalWithoutType
                })
                return { ...restOtherDetails, deleted: false, terminals: updatedTerminals }
            })
        try {
            let response = await makeAPICall({
                method: 'put',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.routing.updateRoute(merchantData.m_id, routeId),
                payload: updatedTerminalRoute[0],
            })
            if (response.data?.success) {
                message.success('Routes updated successfully')
                getRoutes(updatedTerminalRoute[0].method)
                onSelectChange([])
            } else {
                message.error(response?.response?.data?.error?.message ?? 'Failed to update routes')
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function getInstruments(method: string) {
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        try {
            let response = await makeAPICall({
                method: 'get',
                url:
                    process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.routing.getInstruments(merchantData.m_id, method),
            })
            if (response.data?.data?.length > 0) {
                const formattedInstruments = response.data.data.map((item) => ({
                    value: item.instrument,
                    label: item.name,
                }))
                setInstruments(formattedInstruments)
            } else {
                setInstruments([])
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function getRoutes(method: string) {
        setTerminalRoute([])
        onSelectChange([])
        setCurrentTab(method)
        getInstruments(method)
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.routing.getRoutes(merchantData.m_id, method),
            })
            if (response.data?.data?.length > 0) {
                const updatedData = response.data.data.map((route) => {
                    if (route?.terminals?.length > 1) {
                        const sortedTerminals = [...route.terminals].sort((a, b) => b.split - a.split)
                        return {
                            ...route,
                            terminals: sortedTerminals.map((terminal, index) => ({
                                ...terminal,
                                type: index === 0 ? 'primary' : 'backup',
                                split: (terminal.split * 100).toFixed(2),
                            })),
                        }
                    }
                    return {
                        ...route,
                        terminals: route?.terminals?.map((terminal) => ({
                            ...terminal,
                            type: 'primary',
                            split: (terminal.split * 100).toFixed(2),
                        })),
                    }
                })
                setTerminalRoute(updatedData)
                getProvidersForRoutes(updatedData)
            } else {
                setTerminalRoute([])
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function getMethods() {
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.routing.getMethods(merchantData.m_id),
            })
            if (response.data?.data?.length > 0) {
                setMethods(response.data.data)
            } else {
                setMethods([])
            }
        } catch (error) {
            handleError(error)
        }
    }

    function getProvidersForRoutes(routes: RouteInterface[]) {
        routes.forEach((route) => {
            if (route?.id && route?.method && route?.instrument) {
                getSupportedProviders(route.id, route.method, route.instrument)
            }
        })
    }

    async function getSupportedProviders(id: number, methodName: string, instrumentName: string) {
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        try {
            let response = await makeAPICall({
                method: 'get',
                url:
                    process.env.REACT_APP_AUX_BASE_URL +
                    APIEndPoints.routing.supportedProviders(merchantData?.m_id, methodName, instrumentName),
            })
            if (response.data?.data?.length > 0) {
                const activeProviders = response.data.data
                const options = activeProviders.map(({ name, provider }) => ({
                    label: name,
                    value: provider,
                }))
                setTerminalRoute((prevRoutes) =>
                    prevRoutes.map((route) =>
                        route.id === id ? { ...route, instrument: instrumentName, providers: options } : route,
                    ),
                )
            } else {
                const errMessage = response?.response?.data?.error?.reference?.message ?? 'Failed to fetch providers'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => onSelectChange(selectedRowKeys),
        getCheckboxProps: (record) => ({
            disabled: selectedRowKeys.length > 0 && !selectedRowKeys.includes(record.id),
        }),
        hideSelectAll: true,
    }
    function addNewRoute() {
        setOpenAddDrawer(true)
    }

    async function deactivateRoute() {
        if (!merchantData?.m_id || !terminalIdToDelete) {
            message.error('Merchant ID and Terminal ID are required')
            return
        }
        try {
            const response = await makeAPICall({
                method: 'post',
                url:
                    process.env.REACT_APP_AUX_BASE_URL +
                    APIEndPoints.routing.deactivateRoute(merchantData.m_id, terminalIdToDelete),
            })
            if (response?.status === 201) {
                message.success('Route deactivated successfully')
                setTerminalIdToDelete(null)
                // Refresh the routes after deactivation
                getRoutes(currentTab)
            } else {
                message.error('Failed to deactivate route')
            }
        } catch (error) {
            handleError(error)
        }
    }

    function handleSuccess() {
        setOpenAddDrawer(false)
        getRoutes(currentTab)
    }

    return (
        <div className='w-full h-full'>
            <div className='flex justify-between items-center'>
                <div>
                    <div className='flex items-baseline mb-1'>
                        <span className='pr-2'>
                            <TagTwoTone size={12} className='border-2 rounded-full w-6 p-1 bg-blue-500 bg-opacity-10' />
                        </span>
                        <p className='text-base font-medium mb-1'>Routing</p>
                    </div>
                    <p className='text-xs text-gray-400 font-normal'>Map the desired payment channel for routing</p>
                </div>
            </div>
            <div className='mt-4 bg-white rounded overflow-clip box-border'>
                <Row className='box-border'>
                    <Col span={20}>
                        <Tabs
                            defaultActiveKey='1'
                            tabPosition={'top'}
                            style={{ height: 34, paddingLeft: 12, marginBottom: 12 }}
                            items={methods.map((method) => ({
                                label: method.name,
                                key: method.method,
                                children: ``,
                            }))}
                            onChange={getRoutes}
                        />
                    </Col>
                    <Col span={4} className='box-border h-full flex items-center justify-center mt-2'>
                        <Button
                            onClick={() => updateRoute()}
                            variant='primary'
                            disabled={!selectedRowKeys.length}
                            className='flex items-center'
                        >
                            Save
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Table
                            rowKey='id'
                            columns={columns}
                            dataSource={terminalRoute}
                            style={{
                                width: '100vw',
                            }}
                            pagination={false}
                            scroll={{ x: 'max-content' }}
                            rowSelection={rowSelection}
                        />
                    </Col>
                    <Col span={24} className='p-2 flex items-center justify-center'>
                        <Button
                            disabled={selectedRowKeys.length > 0}
                            onClick={addNewRoute}
                            type='text'
                            className='flex items-center text-[#004B8D]'
                        >
                            Add Routing <PlusCircleOutlined />
                        </Button>
                    </Col>
                </Row>
            </div>
            {openAddDrawer && (
                <AddRouting
                    open={openAddDrawer}
                    onCancel={() => setOpenAddDrawer(false)}
                    method={methods?.find((method) => method?.method === currentTab)}
                    instruments={instruments}
                    mId={merchantData?.m_id ?? ''}
                    onSuccess={handleSuccess}
                />
            )}
            {terminalIdToDelete !== null && (
                <Modal
                    title={'Deactivate Route ?'}
                    footer={null}
                    onCancel={() => setTerminalIdToDelete(null)}
                    open={true}
                    centered
                    width={'450px'}
                >
                    <p>Are you sure you want to deactivate this Route?</p>
                    <Row className='flex justify-end gap-2 mt-4'>
                        <Col>
                            <Button className='w-40' onClick={() => setTerminalIdToDelete(null)}>
                                Close
                            </Button>
                        </Col>
                        <Col>
                            <Button variant='primary' className='w-40' onClick={deactivateRoute}>
                                Deactivate Route
                            </Button>
                        </Col>
                    </Row>
                </Modal>
            )}
        </div>
    )
}
