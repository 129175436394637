import dayjs from 'dayjs'
import styles from './styles.module.css'

import utc from 'dayjs/plugin/utc'
import { ReactApexChart, ApexOptions, apexChartConfig } from 'gokwik-ui-kit'
import { numberFormatter } from '@library/utilities/helpers/numberFormatter'
dayjs.extend(utc)

const DistributionChart = ({ data, dates, paymentMethod }) => {
    const datanew = data.stats
    let noData = !datanew || !datanew.length

    const diff = dates.to.diff(dates.from, 'days')
    const xAxisKey = data.resolution === 'h' ? 'hour' : 'date'
    const xAxisLabelsFormat = data.resolution === 'h' ? 'hh:mm A' : diff < 91 ? 'DD MMM' : 'MMM YYYY'
    const top3Methods = data?.top_3_methods
    const allMethods = data?.all_methods
    const paymentMethods = {
        upi: 'UPI',
        cod: 'COD',
        bnpl: 'BNPL',
        card: 'Card',
        netbanking: 'Netbanking',
        wallet: 'Wallet',
        snapmint: 'Snapmint',
        credpay: 'Credpay',
        ppcod: 'PPCOD',
        bharatxpay: 'Bharatxpay',
    }

    allMethods?.length && allMethods.forEach(method => {
        if (!paymentMethods[method]) {
            paymentMethods[method] = method;
        }
    });

    const tooltipData = datanew?.map((item) => ({
        xPrimary: data.resolution === 'h' ? item.hour : item.date,
        total: Object.keys(paymentMethods).reduce((sum, method) => sum + (item[method] || 0), 0),
        details: item,
    }))

    const totalSum = tooltipData.reduce((sum, item) => sum + item.total, 0)
    noData = totalSum === 0

    const formattedTooltip = {
        shared: true,
        intersect: false,
        y: {
            formatter: function (val) {
                return val
            },
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const dateFormat = 'DD MMM YYYY' + (data.resolution === 'h' ? ', hh:mm A' : '')
            const total = tooltipData[dataPointIndex].total
            const details = tooltipData[dataPointIndex].details
            const paymentMethodDetails = Object.keys(paymentMethods)
                .filter((method) => details[method] && details[method] !== 0)
                .sort((a, b) => details[b] - details[a])
                .map((method, index) => {
                    const count = details[method]
                    const percentage = ((count / total) * 100).toFixed(1)
                    const color = index < 3 ? options.colors[index] : options.colors[3]

                    return `
            <div class='flex justify-between gap-x-1'>
                  <div class='flex w-1/2 items-center'>
                      <span class='${styles.circle}' style='background-color:${color}'></span>
                      <span class='ml-1 text-[10px] text-gray-400'>${paymentMethods[method]}</span>
                  </div>
                  <div class='flex w-1/2 justify-end gap-x-1'>
                      <span class='text-[10px] w-1/2 text-right'>${numberFormatter(count)}</span>
                      <span class='text-[10px] w-1/2 text-right' style='min-width:30px'>${percentage}%</span>
                  </div>
            </div>`
                })
            return `
          <div class='charts-tool-tip bg-white flex flex-col gap-y-2 inter p-2 w-40'>
            <span class='text-[10px] text-gray-500 w-1/2 items-center gap-x-1 mb-1'>
             ${tooltipData[dataPointIndex].xPrimary ? dayjs(tooltipData[dataPointIndex].xPrimary).format(dateFormat) : ''}
            </span>
          <div class='flex items-center w-full justify-between gap-x-1 mb-1'>
           <div class='flex w-1/2 items-center'>
            <span class='text-[10px]  text-gray-500'>Method</span>
           </div>
           <div class='flex w-1/2 justify-end gap-x-1'>
            <span class='text-[10px]  w-1/2 text-right text-gray-500'>Count</span>
            <span class='text-[10px]  w-1/2 text-center text-gray-500' style='min-width:30px'>%</span>
           </div>
          </div> 
          ${paymentMethodDetails.join('')}
          <div class='flex items-center bg-primary-25 pl-3 py-1 w-full justify-between gap-x-1'>
            <div class='flex w-1/2 items-center'>
              <span class='font-medium text-[10px] '>Total </span>
            </div>
            <div class='flex w-1/2 justify-end gap-x-1'>
              <span class='font-medium text-[10px] text-right w-1/2'>${numberFormatter(total)}</span>
              <span class='font-medium text-[10px] text-right w-1/2' style='min-width:30px'>100% </span>
            </div>
          </div>
        </div>`
        },
    }

    const calculateOthers = (entry) => {
        const others = Object.entries(entry)
            .filter(
                ([method]) =>
                    !top3Methods.includes(method) && method !== 'hour' && method !== 'date' && method !== 'days',
            )
            .reduce((acc, [method, amount]: any) => acc + amount, 0)
        return others
    }
    const series = top3Methods?.map((method) => ({
        name: paymentMethods[method],
        data: datanew.map((item) => item[method] || 0),
    }))

    if (paymentMethod === 'All') {
        series.push({
            name: 'Others',
            data: datanew?.map((item) => calculateOthers(item)),
        })
    }
    const standardConfig: any = apexChartConfig(data)
    const options: ApexOptions = {
        grid: standardConfig.grid,
        legend: standardConfig.legend,
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        tooltip: formattedTooltip,
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        yaxis: {
            ...standardConfig.yaxis,
            tickAmount: 3,
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            labels: {
                ...standardConfig.yaxis?.labels!,
                formatter: (val) => numberFormatter(+val),
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: datanew.map((item) => item[xAxisKey]),
            tickAmount: diff === 2 ? 2 : 3,
            tooltip: {
                enabled: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                ...standardConfig.xaxis.labels!,
                formatter: function (value) {
                    return dayjs(value).format(xAxisLabelsFormat)
                },
            },
        },
        fill: {
            opacity: 1,
        },
        colors: ['#C273B8', '#E383AE', '#FDA4AB', '#FFCAA8'],
    }

    return (
        <div className='width-100'>
            <ReactApexChart options={options} series={series} type='bar' height='315' />
        </div>
    )
}
export default DistributionChart
