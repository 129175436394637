import { addBreadcrumb, convertIsoToLocaleString, makeAPICall,downloadFromURL } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { ColumnProps, Tag, Tooltip,message } from 'gokwik-ui-kit'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { formatAmount } from '@library/utilities/helpers/helper'

const downloadReport = async (url: string) => {
    const res = await makeAPICall({
        method: 'get',
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.downloadBulkRefund,
        params: { file_name: url },
    })
    if (res.success) {
        downloadFromURL(res.data.data, url.split('/')[2])
    } else message.error('Unable to download')
}

export const internalUserColumns: ColumnProps<any>[] = [
    {
        title: <span>Order #</span>,
        dataIndex: 'order_number',
        render: (text: string) => (
            <Link
                to={`/checkout/orders/${text}`}
                onClick={() =>
                    addBreadcrumb({
                        key: 'order-details',
                        href: `/checkout/orders/${text}`,
                        text,
                    })
                }
            >
                <a style={{ width: 'min-content' }}>
                    <Tooltip placement='topLeft' title={text}>
                        {text.toUpperCase()}
                    </Tooltip>
                </a>
            </Link>
        ),
        width: 220,
        fixed: 'left',
    },
    {
        title: <span>Merchant Name</span>,
        dataIndex: 'Merchant.business_name',
    },
    {
        title: <span>Payment ID</span>,
        dataIndex: 'payment_id',
    },
    {
        title: <span>Refund ID</span>,
        dataIndex: 'gokwik_rid',
    },
    {
        title: <span>Customer Reference ID</span>,
        dataIndex: 'cust_ref_id',
    },
    {
        title: <span>Shopify Order Number</span>,
        dataIndex: 'order_name',
    },
    {
        title: <span>Amount</span>,
        dataIndex: 'amount',
        render: (e) => <p>₹ {e}</p>,
        align: 'right',
    },
    {
        title: <span>Auto Refund</span>,
        dataIndex: 'is_auto_refund',
        render: (val) => <span className='capitalize'>{`${val}`}</span>,
    },
    {
        title: <span>Refunded At</span>,
        dataIndex: 'refunded_at',
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
    },
    {
        title: <span>Created At</span>,
        dataIndex: 'created_at',
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: <span>Refund Type</span>,
        dataIndex: 'refund_type',
    },
    {
        title: <span>Status</span>,
        dataIndex: 'status',
    },
    {
        title: <span>Initiated By</span>,
        dataIndex: 'initiator',
    },
    {
        title: <span>Source</span>,
        dataIndex: 'source',
    },
    {
        title: <span>Refund Req Desc</span>,
        dataIndex: 'refund_request_description',
    },
    {
        title: <span>Refund Req Doc</span>,
        render: (text:string,record:any) => (
            <span
                className='file-name text-blue-400 cursor-pointer' 
                onClick={() => {
                    downloadReport(text);
                }}
                >
                    {text}
            </span>
        ),
        dataIndex: 'refund_request_file',
    },
    {
        title: <span>Status Desc</span>,
        dataIndex: 'bank_status_description',
    },
    {
        title: <span>Gateway Response</span>,
        dataIndex: 'status_description',
    },

    {
        title: <span>Refund Status</span>,
        dataIndex: 'status',
        render: (text: string) => (
            <div>
                {text === 'Completed' ? (
                    <Tag
                        className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        variant='success'
                    >
                        Completed
                    </Tag>
                ) : text === 'Initiated' ? (
                    <Tag
                        className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        variant='warning'
                    >
                        Initiated
                    </Tag>
                ) : (
                    <Tag
                        className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        variant='error'
                    >
                        Failed
                    </Tag>
                )}
            </div>
        ),

        align: 'center',
    },
]

export const merchantUserColumns: ColumnProps<any>[] = [
    {
        title: <span>Order #</span>,
        dataIndex: 'order_number',
        render: (text: string) => (
            <Link
                to={`/checkout/orders/${text}`}
                onClick={() =>
                    addBreadcrumb({
                        key: 'order-details',
                        href: `/checkout/orders/${text}`,
                        text,
                    })
                }
            >
                <a style={{ width: 'min-content' }}>
                    <Tooltip placement='topLeft' title={text}>
                        {text.toUpperCase()}
                    </Tooltip>
                </a>
            </Link>
        ),
        width: 220,
        fixed: 'left',
    },
    {
        title: <span>Refund Payment ID</span>,
        dataIndex: 'payment_id',
    },
    {
        title: <span>Transaction Payment ID</span>,
        dataIndex: 'transaction_payment_id',
    },
    {
        title: <span>Refund Status</span>,
        dataIndex: 'bank_status',
        render: (text: string) => (
            <div>
                {text === 'payment_successful' ? (
                    <Tag
                        className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        variant='success'
                    >
                        Completed
                    </Tag>
                ) : text === 'payment_failed' ? (
                    <Tag
                        className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        variant='error'
                    >
                        Failed
                    </Tag>
                ) : (
                    <Tag
                        className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        variant='warning'
                    >
                        Initiated
                    </Tag>
                )}
            </div>
        ),

        align: 'center',
    },
    {
        title: <span>Payment Mode</span>,
        dataIndex: 'payment_method',
    },
    {
        title: <span>Reference ID</span>,
        dataIndex: 'cust_ref_id',
    },
    {
        title: <span>Customer Phone</span>,
        dataIndex: 'phone_no',
    },
    {
        title: <span>Amount</span>,
        dataIndex: 'amount',
        render: (e) => <p>{formatAmount(e,2)}</p>,
        align: 'right',
    },
    {
        title: <span>Created At</span>,
        dataIndex: 'created_at',
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
    },

    {
        title: <span>Auto Refund</span>,
        dataIndex: 'is_auto_refund',
        render: (val) => <span className='capitalize'>{`${val}`}</span>,
    },
    {
        title: <span>Refund Type</span>,
        dataIndex: 'refund_type',
    },
    {
        title: <span>Status Description</span>,
        dataIndex: 'bank_status_description',
    },
    {
        title: <span>Gateway Response</span>,
        dataIndex: 'status_description',
    },
    {
        title: <span>Refund Request Description</span>,
        dataIndex: 'refund_request_description',
    },
]
