import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { Button, Col, Drawer, Input, Row, Select, Space, TextArea, Tooltip, message } from 'gokwik-ui-kit'
import { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isMerchantSelector,getMerchantDetails, getUserDetails } from '@store/user/selectors'
interface Props {
    open: boolean
    title: string
    onClose: (refetch?: boolean) => void
    recordData: any
    type: string
    heading: string | ReactNode
}

interface PaymentDetails {
    bank_status: string | null
    created_at: string
    cust_ref_id: string | null
    failure_code: string | null
    id: string
    npc_id: string | null
    response_code: string | null
    status_description: string | null
    timeout_status: boolean | null
    updated_at: string
    reason: string
}

const EditStatusDrawer = ({ open, title, onClose, heading, type, recordData }: Props) => {
    
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    console.log("Task Check user",userDetails)
    const [data, setData] = useState<PaymentDetails>({
        bank_status: 'payment_initiated',
        created_at: '',
        cust_ref_id: null,
        failure_code: null,
        id: '',
        npc_id: null,
        response_code: null,
        status_description: null,
        timeout_status: null,
        updated_at: '',
        reason: '',
    })

    const getData = async (id: string) => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + '/api/dashboard/' + type + '/' + id,
            })
            if (response.success) {
                setData({ ...response?.data?.data, bank_status: null, reason: '' })
            }
            else
            {   
                message.error(response?.response?.data?.message)
                onClose(true)
            }
        } catch (err) {
            console.log(err)
        }
    }
    const onEditSubmit = async () => {
        if (!data?.reason) {
            message.warning('Reason is Required')
            return
        }
        if (!data?.bank_status) {
            message.warning('Please select a bank status')
            return
        }
        const tempPayload = {
            statusDescription: data?.status_description, // optional
            npcId: data?.npc_id, // optional
            custRefId: data?.cust_ref_id, // optional
            failureCode: data?.failure_code, // optional
            responseCode: data?.response_code, // optional
        }
        const payload = Object.entries(tempPayload).reduce((acc, [key, val]) => {
            if (val) {
                acc = { ...acc, [key]: val }
            }
            return acc
        }, {})
        if (Object.keys(payload).length < 1) {
            message.warning('Please fill at least one input with bank status and reason.')
            return
        }
        let finalPayload = {};
        if(type === 'refund') {
            finalPayload = {
                id: data?.id,
                reason: data?.reason,
                payload: {
                    ...payload,
                    bankStatus: data?.bank_status,
                },
                source: 'portal',
                initiator: userDetails?.role?.id
            }
        }
        else if(type === 'settlement') {
        finalPayload = {
            id:data?.id,
            reason: data?.reason,
            payload: {
                ...payload,
                bankStatus: data?.bank_status,
            }
        }
        }
        else {
            finalPayload = {
                id: data?.id,
                reason: data?.reason, 

                bankStatus: data?.bank_status,
                ...payload,
            }
        }
        const response = await makeAPICall({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.updateStatus(type)}`,

            payload: finalPayload,
        })
        if (response?.data?.status_code === 200) {
            message.success(response.data.message)
            onClose(true)
        } else {
            message.error(response?.response?.data.message)
        }
    }
    useEffect(() => {
        getData(recordData.id)
    }, [])

    return (
        <Drawer title={title} className='pt-0' placement='right' onClose={() => onClose()} open={open}>
            {heading}
            <Col className='w-full'>
                <Space direction='vertical' className='w-full'>
                    <Select
                        className='w-full'
                        value={data?.bank_status}
                        onChange={(e) => {
                            setData((prev) => ({ ...prev, bank_status: e }))
                        }}
                        options={[
                            { value: 'payment_successful', label: 'Success' },
                            { value: 'payment_failed', label: 'Failed' },
                        ]}
                        placeholder='Select status'
                        label='Bank Status*'
                    />
                    <p className='mt-2'>Status Description </p>
                    <TextArea
                        // type='text'
                        value={data?.status_description}
                        placeholder='Status Description'
                        onChange={(e) => {
                            setData((prev) => ({ ...prev, status_description: e.target.value }))
                        }}
                    />
                    <p className='mt-2'>NPC ID</p>
                    <Input
                        type='text'
                        value={data?.npc_id}
                        placeholder='NPC ID'
                        onChange={(e) => {
                            setData((prev) => ({ ...prev, npc_id: e.target.value }))
                        }}
                    />
                    <p className='mt-2'>Customer Reference ID </p>
                    <Input
                        type='text'
                        value={data?.cust_ref_id}
                        placeholder='Customer Reference Id'
                        onChange={(e) => {
                            setData((prev) => ({ ...prev, cust_ref_id: e.target.value }))
                        }}
                    />
                    <p className='mt-2'>Failure Code</p>
                    <Input
                        type='text'
                        value={data?.failure_code}
                        placeholder='Failure Code'
                        onChange={(e) => {
                            setData((prev) => ({ ...prev, failure_code: e.target.value }))
                        }}
                    />
                    <p className='mt-2'>Response Code</p>
                    <Input
                        type='text'
                        value={data?.response_code}
                        placeholder='Response Code'
                        onChange={(e) => {
                            setData((prev) => ({ ...prev, response_code: e.target.value }))
                        }}
                    />
                    <p className='mt-2'>Reason</p>
                    <TextArea
                        // type='text'
                        value={data?.reason}
                        placeholder='Reason'
                        onChange={(e) => {
                            setData((prev) => ({ ...prev, reason: e.target.value }))
                        }}
                    />
                </Space>
            </Col>
            <Row className='mt-4'>
                <Col span={12}>
                    <Button onClick={onEditSubmit} variant='primary' className='w-[99%]'>
                        Submit
                    </Button>
                </Col>
                <Col span={12}>
                    <Button onClick={() => onClose()} variant='default' className='w-[99%] ml-1'>
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Drawer>
    )
}

export default EditStatusDrawer
