import { SearchFilters, FilterCreatorModel } from '../interface'

const getDynamicSearchFilters = (searchFilters: SearchFilters[]): FilterCreatorModel => {
    const filterCreatorState: FilterCreatorModel = {
        title: 'Add More Filters +',
        options: searchFilters
            ?.filter((item) => item?.is_dynamic)
            ?.map((filter) => {
                return {
                    id: filter?.id,
                    text: filter?.label,
                }
            }),
    }
    return filterCreatorState
}

const formatAmount = (
    val: number,
    minFractionDigits: number = 0,
    maxFractionDigits: number = 2,
    notation: 'standard' | 'scientific' | 'engineering' | 'compact' = 'standard',
) =>
    val === undefined || typeof val === 'string' || val === null
        ? ''
        : Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              notation,
              minimumFractionDigits: minFractionDigits,
              maximumFractionDigits: maxFractionDigits,
          })
              .format(val)
              .replace('T', 'K')

function deepCopy(obj) {
  try {
    // Check if the input is not an object or is null
    if (obj === null || typeof obj !== 'object') {
        return obj
    }

    // Handle Array
    if (Array.isArray(obj)) {
        const copy = []
        for (let i = 0; i < obj.length; i++) {
            copy[i] = deepCopy(obj[i])
        }
        return copy
    }

    // Handle Object
    if (obj instanceof Object) {
        const copy = {}
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                copy[key] = deepCopy(obj[key])
            }
        }
        return copy
    }
  } catch (error) {
    return obj;
  }
}

const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export { getDynamicSearchFilters, formatAmount, deepCopy, capitalizeFirstLetter }
