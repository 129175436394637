import React, { useState, ReactNode, useMemo, useRef, useEffect, useCallback } from 'react'
import { Layout, NavigationMenu, Select } from 'gokwik-ui-kit'
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom'
import PaymentMethodsConfig from './configs/paymentMethods'
import Routing from './routing/index'
import Providers from './providers'
import { useSelector } from 'react-redux'
import { isMerchantSelector, getMerchantDetails, getUserDetails } from '@store/user/selectors'
import NetbankingMapping from './netbanking-mapping'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
import PaymentTesting from './payment-testing'

const { Sider } = Layout

interface MenuItem {
    key: string
    label: ReactNode
    icon?: ReactNode
    children?: MenuItem[]
    type?: 'group'
}

function createMenuItem(label: ReactNode, key: string, children?: MenuItem[], type?: 'group'): MenuItem {
    return {
        key,
        children,
        label,
        type,
    }
}
export default function () {
    const route = useLocation()
    const navigate = useNavigate()
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantData = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const previousOpenedItems = useRef({
        openedKey: '',
        selectedKey: '',
    })

    const settingsList = [
        {
            title: 'Payment Configs',
            path: '/configs',
            component: PaymentMethodsConfig,
        },
        {
            title: 'Terminals',
            path: '/providers',
            component: Providers,
            adminOnly: true,
        },
        {
            path: '/netbanking-mapping',
            title: 'Netbanking Mapping',
            component: NetbankingMapping,
            adminOnly: true,
        },
        {
            path: '/routing',
            title: 'Routing',
            component: Routing,
            adminOnly: true,
        },
        {    
            path: '/payment-testing',
            title: 'Payment Testing',
            component: PaymentTesting,
            adminOnly: true,
        },
    ]

    const menuItems = useCallback(
        () =>
            settingsList
                .filter((item) => (isMerchantUser ? !item?.adminOnly : true))
                .reduce((result, item) => [...result, createMenuItem(item.title, `/payment/settings${item.path}`)], []),
        [isMerchantUser],
    )

    const openItems = useMemo(() => {
        const menu = menuItems()
        const openedKey = menu.find((item) => {
            if (item.key === route.pathname) {
                return true
            }
            if (item.children) {
                return item.children.some((child) => child.key === route.pathname)
            }
            return false
        })

        if (openedKey) {
            previousOpenedItems.current = {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
            return {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
        } else if (previousOpenedItems.current.openedKey) {
            return {
                ...previousOpenedItems.current,
            }
        } else {
            let similarSelectedKey: MenuItem | undefined
            const similarKey = menu.find(
                (item) =>
                    !!item.children?.find((child) => {
                        if (route.pathname.includes(child.key)) {
                            similarSelectedKey = child
                            return true
                        } else return false
                    }),
            )
            previousOpenedItems.current = {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
            return {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
        }
    }, [route.pathname])

    const handleMenuSelection = ({ key }: any) => {
        navigate(key)
    }

    useEffect(() => {
        if (route.pathname === '/payment/settings') {
            navigate('/payment/settings' + settingsList[0].path)
        }
        logEvent('kwik_payment_setting_clicked', 'click', 'Kwik Payment Settings', user_details?.email, merchantData?.m_id, merchantData?.short_name, user_details?.name);
    }, [route.pathname])

    return (
        <div className='w-full bg-white'>
            <Layout className='bg-white h-full '>
                <Sider className='!bg-white h-full border-0 border-gray-100 border-r-2 fixed overflow-hidden'>
                    <div className='setting-menu-custom-css'>
                        <NavigationMenu
                            onClick={handleMenuSelection}
                            openKeys={[openItems.openedKey]}
                            selectedKeys={[openItems.selectedKey]}
                            className=''
                            items={menuItems()}
                            theme='light'
                            inlineCollapsed={true}
                            mode='inline'
                        />
                    </div>
                </Sider>
                <Layout className='pl-5 overflow-auto'>
                    <Routes>
                        {settingsList.map((item, index) => (
                            <Route key={index} path={item.path} element={<item.component />} />
                        ))}
                    </Routes>
                </Layout>
            </Layout>
        </div>
    )
}
