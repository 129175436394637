import { HTMLProps, useEffect, useState } from 'react'
import { apexChartConfig, ApexOptions, ReactApexChart } from 'gokwik-ui-kit'
import { numberFormatter } from '@library/utilities/helpers/numberFormatter'
import dayjs from 'dayjs'
import styles from './styles.module.css'

interface ChartProps {
    dates: { from: Dayjs; to: Dayjs; label?: string }
    data: any
    chartType: any
    hideComparison?: boolean
    seriesOptions?: any[]
    resolution?: string
    isAmount?: boolean
    wrapperClassName?: HTMLProps<HTMLElement>['className']
    isStacked?: boolean
    colors?: string[]
    dashArray?: number[]
    labelType?: 'amount' | 'percentage' | 'number'
    stackType?: 'normal' | '100%'
    chartKey?: string
}

const yAxisFormatter = (val, type) => {
    let result = ''
    const formattedValue = numberFormatter(val?.toFixed(0))
    if (type === 'amount') {
        result = `₹${numberFormatter(val?.toFixed(0))}`
    } else if (type === 'percentage') result = `${numberFormatter(val?.toFixed(0))}%`
    else result = formattedValue
    return result
}

const chartConfig = (values) => {
    const addonToolTipKeys = Object.keys(values.addtionalToolTipsData || {})
    const xAxisLabelsFormat = values.resolution === 'h' ? 'hh:mm A' : values.datesDiff < 91 ? 'DD MMM' : 'MMM YYYY'
    const standardOptions = apexChartConfig(values)
    return {
        ...standardOptions,
        ...(values.colors && {colors: values.colors}),
        grid: {
            show: true,
            padding: {
                left: 0,
                right: 0,
                bottom: 0,
                top: -16,
            },
        },
        chart: {
            height: 100,
            stacked: !!values.isStacked,
            ...(values.stackType && { stackType: values.stackType }),
            width: '100%',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            opacity: 1,
        },
        stroke: {
            width: [1.5, 1.5, 1.5],
            curve: 'smooth',
            dashArray: values.dashArray,
        },
        xaxis: {
            ...standardOptions.xaxis,
            tickAmount: values.datesDiff === 2 ? 2 : 3,
            type: 'number',
            tooltip: {
                enabled: false,
            },
            axisTicks: {
                show: false,
            },
            categories: values.xPrimary,
            labels: {
                rotate: 0,
                formatter:
                    values.xAxisFormat ||
                    function (value) {
                        return dayjs(value).format(xAxisLabelsFormat)
                    },
            },
        },
        yaxis: {
            tickAmount: 2,
            min: 0,
            max: 100,
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            labels: {
                offsetX: -16,
                offsetY: 2,
                formatter: (val) => yAxisFormatter(val, values.labelType),
                style: {
                    colors: 'rgba(26, 25, 25, 0.5)',
                    fontSize: '12px',
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 400,
                },
            },
        },
        legend: {
            ...(values.chartKey === 'paymentFunnel'
                ? {
                      show: false,
                  }
                : {
                      show: true,
                      horizontalAlign: 'left',
                      showForSingleSeries: true,
                  }),
        },
        tooltip: {
            ...(values.chartType === 'bar'
                ? {
                      sharedL: false,
                      intersect: true,
                  }
                : {
                      shared: true,
                  }),
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const dateFormat = 'DD MMM YYYY' + (values.resolution === 'h' ? ', hh:mm A' : '')
                return ` 
                <div class='charts-tool-tip bg-white flex flex-col inter p-2 flex-grow'>
                 <div class='flex flex-col items-center font-medium text-xs'>
                        <div class='flex items-center w-full justify-between'>
                            <div class='flex items-center'>
                                <span class='text-[9px] text-gray-500'>
                                    ${dayjs(values.xPrimary[dataPointIndex]).format(dateFormat)}
                                </span>
                            </div>
                            <div class='flex justify-end'>
                                ${
                                    addonToolTipKeys.includes('intent_drop_count') || addonToolTipKeys.includes('failure_drop_count')
                                        ? `<span class='ml-6 text-[9px] text-gray-500' style='min-width:30px'>Count</span>`
                                        : addonToolTipKeys.includes('overall_attempts') || addonToolTipKeys.includes('method_attempts')
                                        ? `<span class='ml-4 text-[9px] text-gray-500' style='min-width:30px'>Attempts</span>`
                                        : ''
                                }
                            </div>
                        </div>
                        ${w.globals.seriesNames.reduce(
                            (result, seriesName, i) =>
                                result +
                                `<div class='flex items-center w-full justify-between'>
                                    <div class='flex items-center'>
                                        <div class='${styles.circle}' style='background-color:${
                                    w.config.colors[i]
                                } '></div>
                                        <span class='ml-1 text-[9px] text-gray-400 '>
                                            ${seriesName}
                                        </span>
                                    </div>
                                    <div class='flex justify-end'>
                                        <span class='${
                                            addonToolTipKeys.length ? 'ml-2' : 'mr-2'
                                        } font-medium text-[9px] w-1/2 text-right'>${yAxisFormatter(
                                    series[i][dataPointIndex],
                                    values.labelType,
                                )}</span>
                                        ${
                                            addonToolTipKeys.length
                                                ? `<span class='mr-1 font-medium text-[9px] w-1/2 text-right' style='min-width:30px' >
                                                    ${values.isAmount ? '₹' : ''}${numberFormatter(
                                                      values.addtionalToolTipsData[addonToolTipKeys[i]][dataPointIndex],
                                                  )}
                                                </span>`
                                                : ''
                                        }
                                    </div>
                                </div>`,
                            '',
                        )}
                    </div>
                </div>`
            },
            x: {
                show: true,
                formatter: (current, { dataPointIndex, series, seriesIndex, w }) => {
                    return `${w.globals.categoryLabels[dataPointIndex]} <br />
              <span class='fw700' >
              Total Requests: ${numberFormatter(
                  w.globals?.columnSeries?.reduce((sum, current) => sum + current[dataPointIndex], 0),
              )}
                </span>
              `
                },
            },
        },
        markers: {
            size: values.xPrimary.length === 1 ? 5 : 0,
            hover: {
                size: 6,
            },
        },
    }
}

const Chart = ({
    dates,
    isStacked,
    data,
    chartType,
    isAmount,
    seriesOptions,
    colors,
    labelType,
    dashArray,
    stackType,
    resolution,
    wrapperClassName,
    chartKey,
}: ChartProps) => {
    const [series, setSeries] = useState([])
    const [options, setOptions] = useState({})
    useEffect(() => {
        if (!data) return
        const diff = dates.to.diff(dates.from, 'days')
        const xAxisKey = resolution === 'h' ? 'hour' : 'date'
        let addtionalToolTipsData = {}
        const chartData = data.reduce(
            (result, current, i) => ({
                ...seriesOptions.reduce((seriesData, option) => {
                    if (option.addOnTooltip) {
                        const tooltipValue = addtionalToolTipsData[option.addOnTooltip]
                        if (tooltipValue) addtionalToolTipsData[option.addOnTooltip].push(current[option.addOnTooltip])
                        else addtionalToolTipsData[option.addOnTooltip] = [current[option.addOnTooltip]]
                    }

                    return {
                        ...seriesData,
                        [option.dataKey]: [...(result?.[option.dataKey] || []), +current[option.dataKey]],
                    }
                }, {}),
                [xAxisKey]: [...(result?.[xAxisKey] || []), current[xAxisKey]?.split(' +')[0]],
            }),
            {},
        )
        const minYValues = seriesOptions.map((option) => Math.min(...chartData[option.dataKey]) - 10)
        const maxYValues = seriesOptions.map((option) => Math.max(...chartData[option.dataKey]) + 10)
        const minYValue = Math.max(Math.min(...minYValues), 0)
        const maxYValue = Math.min(Math.max(...maxYValues), 100)
        setSeries(
            seriesOptions.map((option) => ({
                name: option.name,
                data: chartData[option.dataKey],
            })),
        )
        setOptions(
            chartConfig({
                xPrimary: chartData[xAxisKey],
                datesDiff: diff,
                isAmount,
                colors,
                chartType,
                isStacked,
                labelType,
                resolution,
                stackType,
                dashArray,
                minYValue,
                maxYValue,
                ...(!!Object.keys(addtionalToolTipsData).length && {
                    addtionalToolTipsData,
                }),
                chartKey,
            }),
        )
    }, [dates, data])

    return (
        <div className={wrapperClassName}>
            <ReactApexChart options={options} type={chartType} series={series} height={'100%'} />
        </div>
    )
}
export default Chart
