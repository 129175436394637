import { Button, Card, CloudUploadOutlined, Col, Drawer, Input, message, Row, Select } from 'gokwik-ui-kit'
import { SelectDropdown } from '@library/utilities/interface'
import { useEffect, useState } from 'react'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { makeAPICall } from '@gokwik/utilities'
import { handleError } from '@library/utilities/helpers/handleError'

interface Props {
    mId: string
    invoiceId: string
    handleClose: (shouldOpen: boolean) => void
}

interface LogEntry {
    diff: {
        [key: string]:
            | {
                  old_value: number | string | null
                  new_value: number | string | null
              }
            | undefined
    }
    updatedAt: number
}

export default function ({ mId, invoiceId, handleClose }: Props) {
    const [logs, setLogs] = useState<LogEntry[]>([])

    const getKeyNames = (key:string) => {
       switch(key) {
            case 'collection':
                return "Split Percentage";
            case 'max_deduction_per_day':
                return "Max Collection Per day"
            default:
                return key;    
       }
    }

    useEffect(() => {
        getAllLogs()
    }, [])

    async function getAllLogs() {
        if (!mId) {
            message.error('Merchant is required')
            return
        }
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.splitSettlements.getLogs(mId, invoiceId),
            })
            if (response.data?.data) {
                const logs = response.data.data.map((log) => ({
                    diff: log.diff,
                    updatedAt: new Date(log.updated_at).toLocaleString(),
                }))
                setLogs(logs)
            } else {
                setLogs([])
            }
        } catch (error) {
            handleError(error)
        }
    }
    return (
        <>
            <Drawer title={'Change logs'} open={true} onClose={() => handleClose(false)} width={600}>
                <Row gutter={[4, 4]} className='mt-2'>
                    {logs.map((log, index) => (
                        <Col key={index} span={24}>
                            <Card
                                title={<span className='font-medium text-sm'>{`Updated At: ${log.updatedAt}`}</span>}
                                className='mt-2 mb-2'
                            >
                                <Row gutter={[6, 6]}>
                                    <Col span={10} className='text-gray-500'>
                                        <span>Key</span>
                                    </Col>
                                    <Col span={7} className='text-gray-500'>
                                        <span>Old Value</span>
                                    </Col>
                                    <Col span={7} className='text-gray-500'>
                                        <span>New Value</span>
                                    </Col>
                                </Row>
                                {Object.entries(log.diff).map(([key, value]) => (
                                    <Row key={key} gutter={[6, 6]} className='mt-3'>
                                        <Col span={10}>
                                            <strong className='break-words max-w-full text-regular'>{getKeyNames(key)}</strong>
                                        </Col>
                                        <Col span={7}>
                                            <span className='break-words max-w-full text-sm'>
                                                {String(value?.old_value ?? 'N/A')}
                                            </span>
                                        </Col>
                                        <Col span={7}>
                                            <span className='break-words max-w-full text-sm'>
                                                {String(value?.new_value ?? 'N/A')}
                                            </span>
                                        </Col>
                                    </Row>
                                ))}
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Drawer>
        </>
    )
}
