import {
    Button,
    ColumnProps,
    CopyOutlined,
    Dropdown,
    EditOutlined,
    EyeOutlined,
    MenuProps,
    MoreOutlined,
    Popover,
    ShopOutlined,
    Switch,
    Tag,
    Tooltip,
} from 'gokwik-ui-kit'
import { useContext, useMemo, useState } from 'react'
import { formatPaymentMethod, OfferContext, OfferListing, OfferPageParent, OfferStatus } from './constants'
import { convertIsoToLocaleDateString } from '@gokwik/utilities'
import logo from '@library/images/common/gk-gray.svg'
import { getStatusTooltip, getStatusVariant } from './offer.service'

function getMenuItems(row: OfferListing, isMerchantUser: boolean, openAddRemoveMerchant): MenuProps['items'] {
    return [
        {
            key: '1',
            label: 'Edit',
            icon: <EditOutlined />,
            disabled: true,
        },
        {
            key: '2',
            label: 'Duplicate',
            icon: <CopyOutlined />,
            disabled: true,
        },
        {
            key: '3',
            label: 'View Offer Construct',
            icon: <EyeOutlined />,
            disabled: true,
        },
        isMerchantUser
            ? undefined
            : {
                  key: '4',
                  label: 'Update Merchants',
                  icon: <ShopOutlined />,
                  onClick: () => openAddRemoveMerchant(),
              },
    ]
}

function RenderStatus({ status, rec, isMerchant }) {
    const offerContext = useContext(OfferContext)
    const cannotUpdateStatus = useMemo(() => {
        if (status === OfferStatus.EXPIRED) {
            return 'Cannot update expired offer'
        }
        if (offerContext?.parent === OfferPageParent.PAYMENT) {
            if (rec?.merchant_offer_config?.is_deleted === true) {
                return isMerchant ? 'Offer disabled by admin' : 'Offer disabled for this merchant'
            }
            if (rec?.show_status === OfferStatus.AVAILABLE) {
                return isMerchant ? 'Reach out to admin to avail this offer' : 'Add merchant to enable this offer'
            }
            if (rec?.status === OfferStatus.PAUSED) {
                return isMerchant ? 'Offer paused by admin' : 'Enable offer from global settings'
            }
            if (rec?.status === OfferStatus.DRAFT) {
                return 'Cannot update offer in draft stage'
            }
        }
        return null
    }, [status])
    const [loading, setLoading] = useState(false)
    const switchChecked = useMemo(() => {
        if (offerContext?.parent === OfferPageParent.PAYMENT) {
            return rec?.merchant_offer_config?.is_enabled === true && rec?.status === OfferStatus.PUBLISHED
        }
        return rec?.status === OfferStatus.PUBLISHED
    }, [isMerchant, rec, offerContext])

    const change = () => {
        setLoading(true)
        offerContext.updateStatus(
            offerContext?.parent === OfferPageParent.PAYMENT
                ? !rec?.merchant_offer_config?.is_enabled
                : rec.status !== OfferStatus.PUBLISHED,
            rec?.id,
            () => setLoading(false),
            () => setLoading(false),
        )
    }

    return (
        <div className='flex items-center gap-x-3'>
            <Tooltip title={cannotUpdateStatus}>
                <Switch
                    size='small'
                    checked={!cannotUpdateStatus && switchChecked}
                    onChange={change}
                    loading={loading}
                    disabled={!!cannotUpdateStatus}
                />
            </Tooltip>
            {status?.length && (
                <Tooltip
                    title={getStatusTooltip(status, !isMerchant && offerContext?.parent === OfferPageParent.PAYMENT)}
                >
                    <Tag
                        variant={getStatusVariant(status)}
                        className='!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs'
                    >
                        {status}
                    </Tag>
                </Tooltip>
            )}
        </div>
    )
}

export function getColumns(isMerchant): ColumnProps<any>[] {
    return [
        {
            title: <span>Offer Code</span>,
            width: 150,
            fixed: 'left',
            dataIndex: 'coupon_code',
            ellipsis: true,
            render: (coupon_code, rec, i) => {
                const offerContext = useContext(OfferContext)
                return (
                    <div className='flex items-center gap-x-2'>
                        <Tooltip title={coupon_code}>
                            <Button
                                className='px-0 !outline-0 truncate'
                                block
                                type='link'
                                onClick={() => offerContext.viewOffer(i)}
                            >
                                <span className='text-left truncate w-full'>{coupon_code}</span>
                            </Button>
                        </Tooltip>
                        {rec.is_global_offer && (
                            <Tooltip title="This offer is made by GoKwik. You're allowed to duplicate and change active status.">
                                <div className='ms-auto flex items-center justify-center h-6 w-8 rounded-full bg-[#F0F0F0]'>
                                    <img className='h-4' src={logo} />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                )
            },
        },
        {
            title: <span>Status</span>,
            dataIndex: 'show_status',
            width: 150,
            render: (status, rec) => <RenderStatus status={status} rec={rec} isMerchant={isMerchant} />,
        },
        {
            title: <span>Offer Name</span>,
            width: 150,
            dataIndex: 'title',
            ellipsis: true,
            render: (title) => <Tooltip title={title} children={<p className='truncate w-full'>{title}</p>} />,
        },
        {
            title: <span>Start date</span>,
            width: 120,
            dataIndex: 'start_date',
            render: (start_date) => convertIsoToLocaleDateString(start_date),
        },
        {
            title: <span>End date</span>,
            width: 120,
            dataIndex: 'end_date',
            render: (end_date) => convertIsoToLocaleDateString(end_date),
        },
        {
            title: <span>Offer Type</span>,
            width: 150,
            dataIndex: 'offer_category',
            render: (offer_category) => <p className='capitalize'>{offer_category?.replace(/_/g, ' ')}</p>,
        },
        {
            title: <span>Payment Method</span>,
            width: 180,
            dataIndex: 'valid_payment_methods',
            ellipsis: true,
            render: (valid_payment_methods) => {
                const methods = (valid_payment_methods?.map((method) => formatPaymentMethod(method)) as string[]) ?? []
                const content = methods.at(1) && (
                    <ul className='p-0 pe-2 m-0 list-none overflow-scroll max-h-32'>
                        {methods.slice(1).map((m, i) => (
                            <li key={i}>{m}</li>
                        ))}
                    </ul>
                )
                return (
                    <>
                        {methods.at(0) && <Tag>{methods.at(0)}</Tag>}
                        {content && (
                            <Popover content={content}>
                                <Tag>+{methods.length - 1}</Tag>
                            </Popover>
                        )}
                    </>
                )
            },
        },
        {
            title: '',
            fixed: 'right',
            width: 50,
            render: (offerData, _, i) => {
                const offerContext = useContext(OfferContext)
                const items = getMenuItems(offerData, isMerchant, () => offerContext.addRemoveMerchant(i))
                return (
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <MoreOutlined className='p-2 rounded-full hover:bg-gray-300' />
                    </Dropdown>
                )
            },
        },
    ]
}
