import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'
import { OfferMerchantActions, OfferPageParent, OfferStatus, type TagProp } from './constants'
import { message } from 'gokwik-ui-kit'

export function getStatusVariant(status: string): TagProp['variant'] {
    switch (status) {
        case OfferStatus.DRAFT:
            return 'warning'

        case OfferStatus.ACTIVE:
        case OfferStatus.PUBLISHED:
            return 'success'

        case OfferStatus.EXPIRED:
            return 'error'

        default:
            return 'default'
    }
}

const statusText = (status: string) => {
    switch (status) {
        case OfferStatus.DRAFT:
            return 'Offer not yet published'

        case OfferStatus.ACTIVE:
            return 'Offer published and active'

        case OfferStatus.AVAILABLE:
            return 'Offer available'

        case OfferStatus.INACTIVE:
            return 'Offer published but inactive'

        case OfferStatus.PUBLISHED:
            return 'Offer published'

        case OfferStatus.EXPIRED:
            return 'Offer expired'

        default:
            return null
    }
}

export function getStatusTooltip(status: string, adminAsMerchant: boolean): string {
    return statusText(status) + (adminAsMerchant ? ' for this merchant' : '')
}

export const getOfferListing = async (params, callback: CallableFunction, parent?: OfferPageParent) => {
    try {
        params = Object.keys(params).reduce((prev, curr) => {
            if (![null, undefined, ''].includes(params[curr])) prev[curr] = params[curr]
            return prev
        }, {})
        let response = await makeAPICall({
            method: 'get',
            url:
                process.env.REACT_APP_AUX_BASE_URL +
                (parent === OfferPageParent.PAYMENT
                    ? APIEndPoints.offers.merchantOffers
                    : APIEndPoints.offers.getAllOffers),
            params,
        })
        if (response?.data?.data?.documents?.length > 0) {
            let docs = response?.data?.data?.documents ?? []
            if (parent === OfferPageParent.PAYMENT) {
                docs = docs.map((row) => {
                    return { ...row, ...row.offer }
                })
            }
            callback(docs, response.data.data.count ?? 0)
        } else {
            callback([], 0)
            !response?.success && handleError(response)
        }
    } catch (error) {
        callback([], 0)
        handleError(error)
    }
}

export const getOfferDetails = async (id: string, callback: CallableFunction, errorHandle?: CallableFunction) => {
    try {
        let response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.offers.offerDetails(id),
        })
        if (response?.success) {
            callback(response.data.data)
        } else {
            callback(null)
            errorHandle?.()
            handleError(response)
        }
    } catch (error) {
        callback(null)
        errorHandle?.()
        handleError(error)
    }
}

export const addRemoveMerchants = async (action: OfferMerchantActions, payload, errorCallback) => {
    try {
        const endpoint = APIEndPoints.offers[action]
        let response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_AUX_BASE_URL + endpoint,
            payload,
        })

        if (!response?.success) {
            errorCallback()
            handleError(response)
        } else {
            message.success('Merchant list updated successfully')
        }
    } catch (err) {
        errorCallback()
        handleError(err)
    }
}

export const updateOffer = async (options: {
    id: string
    payload
    success?: CallableFunction
    errorCallback?: CallableFunction
}) => {
    try {
        let response = await makeAPICall({
            method: 'patch',
            url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.offers.offerDetails(options.id),
            payload: options.payload,
        })

        if (response?.success) {
            options.success?.()
            message.success('Offer updated successfully')
        } else {
            options.errorCallback?.()
            handleError(response)
        }
    } catch (err) {
        options.errorCallback?.()
        handleError(err)
    }
}

export const updateMerchantOfferConfig = async (options: {
    payload
    success?: CallableFunction
    errorCallback?: CallableFunction
}) => {
    try {
        let response = await makeAPICall({
            method: 'patch',
            url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.offers.merchantOffers,
            payload: options.payload,
        })

        if (response?.success) {
            options.success?.()
            message.success('Offer updated successfully')
        } else {
            options.errorCallback?.()
            handleError(response)
        }
    } catch (err) {
        options.errorCallback?.()
        handleError(err)
    }
}
