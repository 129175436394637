import styles from './Chargeback.module.css'
import { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import {
    SearchOutlined,
    RedoOutlined,
    CloseCircleOutlined,
    PlusOutlined,
    InfoCircleOutlined,
    CreditCardOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import {
    addBreadcrumb,
    convertIsoToLocaleString,
    dashboardStore,
    filterDateFormatter,
    makeAPICall,
    updateBreadcrumbs,
} from '@gokwik/utilities'
import {
    Table,
    Select,
    Button,
    DatePicker,
    Col,
    Row,
    Space,
    Tooltip,
    Drawer,
    Upload,
    Form,
    Input,
    TextArea,
    ColumnProps,
    message,
} from 'gokwik-ui-kit'
import filters, { saveFiltersData } from '@store/filters'
import { useDispatch, useSelector } from 'react-redux'
import { previousRefundsFilters } from '@store/filters/selectors'
import { Link } from 'react-router-dom'
import RenderSearchFilters from '@library/components/search-filter'
import { formatAmount } from '@library/utilities/helpers/helper'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { getMerchantDetails } from '@store/user/selectors'
const today = dayjs()
const formattedDate = filterDateFormatter(today)

const { Option } = Select
const columns: ColumnProps<any>[] = [
    {
        title: <span>Order #</span>,
        dataIndex: 'order_number',
        render: (text: string) => (
            <Link
                to={`/checkout/orders/${text}`}
                onClick={() =>
                    addBreadcrumb({
                        key: 'order-details',
                        href: `/checkout/orders/${text}`,
                        text,
                    })
                }
            >
                <a style={{ width: 'min-content' }}>
                    <Tooltip placement='topLeft' title={text}>
                        {text.toUpperCase()}
                    </Tooltip>
                </a>
            </Link>
        ),
        width: 220,
        fixed: 'left',
    },
    {
        title: <span>Status</span>,
        dataIndex: 'status',
        render: (text: string) => (
            <div>
                {text === 'Completed' ? (
                    <span
                        style={{
                            color: '#079455',
                            borderRadius: '15px',
                            border: '0.6px solid var(--success-200, #ABEFC6)',
                            background: 'var(--success-25, #F6FEF9)',
                            padding: '4px',
                        }}
                    >
                        Completed
                    </span>
                ) : text === 'Initiated' ? (
                    <span
                        style={{
                            color: '#DC6803',
                            borderRadius: '15px',
                            border: '0.6px solid var(--warning-200, #FEDF89)',
                            background: 'var(--warning-25, #FFFCF5)',
                            padding: '4px',
                        }}
                    >
                        Initiated
                    </span>
                ) : (
                    <span
                        style={{
                            color: '#DC6803',
                            borderRadius: '15px',
                            border: '0.6px solid var(--error-200, #FECDCA)',
                            background: 'var(--error-25, #FFFBFA);',
                            padding: '4px',
                        }}
                    >
                        Failed
                    </span>
                )}
            </div>
        ),

        align: 'center',
    },
    {
        title: <span>Merchant Name</span>,
        dataIndex: 'Merchant.business_name',
    },
    {
        title: <span>Payment Id</span>,
        dataIndex: 'payment_id',
    },
    {
        title: <span>Chargeback Id</span>,
        dataIndex: 'payment_id',
    },
    {
        title: <span>Created At</span>,
        dataIndex: 'created_at',
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,

        sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: <span>Customer Reference Id</span>,
        dataIndex: 'cust_ref_id',

        align: 'right',
    },
    {
        title: <span>Amount</span>,
        dataIndex: 'amount',
        render: (e) => <p>{formatAmount(e,2)}</p>,
    },
    {
        title: <span>Auto Chargeback</span>,
        dataIndex: 'is_auto_refund',
    },

    {
        title: <span>Refunded At</span>,
        dataIndex: 'refunded_at',
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
    },
    {
        title: <span>Initiated At</span>,
        dataIndex: 'created_at',
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
    },
    {
        title: <span>Source</span>,
        dataIndex: 'source',
    },
    {
        title: <span>Chargeback Req Desc</span>,
        dataIndex: 'refund_request_description',
    },
    {
        title: <span>Status Desc</span>,
        dataIndex: 'status_description',
    },
    {
        title: <span>Payment At</span>,
        dataIndex: 'payment_at',
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
    },
]

export default function () {

    const prevFilters = useSelector(previousRefundsFilters)
    const merchantDetails = useSelector(getMerchantDetails)
    const [total, setTotal] = useState(0)
    const [Chargeback, setRefund] = useState([])
    const dispatch = useDispatch()
    const [editOpen, setEditOpen] = useState(false)
    const [chargebackData, setChargebackData] = useState({
        payment_id: '',
        payment_provider: 'ybl',
        new: 'false',
        cust_ref_id: '',
        npc_id: '',
        refund_request_description: '',
        refund_request_file: '',
        id: '',
    })

    const [parameters, setParameters] = useState({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        st_date: formattedDate,
        end_date: formattedDate,
        // mid: null,
        ...prevFilters,
    });
    
      const handleFiles = async (files,name) => {
        if(files){
        let fileName = 'chargeback'+ new Date().getTime();
        let url = await getSignedUrl(fileName);

        const awsResponse = await fetch(url,{
            method: 'PUT',
            body: files,
        })

        if(awsResponse.status == 200)
        {
            setChargebackData((prev) => ({ ...prev, refund_request_file: `merchant/${merchantDetails?.id}/refund/chargeback/${fileName}.pdf`}))
            return true;
        }
        else
        {
            message.warning("Failed to upload the File")
            return false;
        }
        }
        else {
            message.warning("Add Files")
            return false;
        }
      };
    
      function downloadPDF(pdf) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement('a');
        const fileName = 'document.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    
      function downloadDoc(pdf) {
        const linkSource = `data:application/msword;base64,${pdf}`;
        const downloadLink = document.createElement('a');
        const fileName = 'document.doc';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }

    useEffect(() => {
        // const filters = fetchFilters()
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'chargebacks',
                href: '/payment/chargebacks',
                text: 'Chargebacks',
            },
        ])
    }, [])

    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters))
            setParameters({ ...prevFilters })
    }, [prevFilters])

    const reset = (e) => {
        e.preventDefault()
        setParameters({
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            st_date: formattedDate,
            end_date: formattedDate,
            // mid: null,
        })
        dispatch<any>(saveFiltersData('chargebacks', {}))
        setTotal(0)
    }

    async function makeChargeBackCall(reset, merchantId = null) {
        // if (!parameters.merchant_id) return

        try {
            // let response = dummyOrders?.data;
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + '/api/dashboard/refund/all',
                params: parameters,
            })

            if (response.data?.data?.refund?.length > 0) {
                setRefund(response.data?.data?.refund)
                setTotal(response.data?.data?.totalCount)
            } else {
                setRefund([])
                setTotal(0)
            }
            dispatch<any>(saveFiltersData('chargebacks', { ...parameters }))
        } catch (err) {
            console.warn(err)
        }
    }

    useEffect(() => {
        makeChargeBackCall(null, null)
    }, [parameters.page])

    const handleSearchClick = (e) => {
        e?.preventDefault()
        setParameters((prev) => ({ ...prev, page: 1 }))
        makeChargeBackCall(null, null)
    }

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({
            ...prev,
            page: current,
            pageSize,
        }))
    }

    const validateChargeback = (data) => {
        if (data.payment_id === '')
          return {
            msg: 'Enter payment id',
            status: false,
          };
        if (data.payment_provider === '')
          return {
            msg: 'Select payment provider',
            status: false,
          };
      
        if (data.cust_ref_id === '')
          return {
            msg: 'Enter custom reference id',
            status: false,
          };
        if (data.npc_id === '' && data.payment_provider == 'ybl')
          return {
            msg: 'Enter npc id',
            status: false,
          };
        if (!data.refund_request_description && !data.refund_request_file)
          return {
            msg: `Enter Chargeback Request Description 
            Or
           Upload Chargeback Request Doc
             `,
            status: false,
          };
      
        return {
          msg: 'Chargeback created successfully',
          status: true,
        };
      };

    const submitChargeback = async () => {

        try {
            // let response = dummyOrders?.data;
            let submit = validateChargeback(chargebackData)
            if(submit.status) {
            let merchantId = merchantDetails?.m_id
            let params = {
                payment_id: '',
                payment_provider: '',
                new: chargebackData?.new === 'true' ? true : false,
                data: { cust_ref_id: '', ...(chargebackData.payment_provider === 'icici' ? {} : { npc_id: '' }) },
                refund_request_description: '',
                refund_request_file: '',
                id: '',
              };
              for (let k in params) {
                if (k == 'data') {
                  params.data['cust_ref_id'] = chargebackData['cust_ref_id'];
                  chargebackData.payment_provider === 'icici' ? null : (params.data['npc_id'] = chargebackData['npc_id']);
                } else if (chargebackData[k]) {
                  params[k] = chargebackData[k];
                  if (k == 'id' && chargebackData.id.length <= 0) {
                    delete params[k];
                  }
                } else delete params[k];
              }
              let finalParams = {
                ...params,
                new: chargebackData?.new === 'true' ? true : false,
              }
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.triggerChargeback + `/${merchantId}`,
                payload: finalParams,
            })
            if (response.data?.data?.Chargeback?.length > 0) {
                message.success("Chargeback Triggered Successfully")
                setRefund(response.data?.data?.Chargeback)
                setTotal(response.data?.data?.totalCount)
            } else {
                message.error(response?.response?.data?.message || response?.data?.message || 'Something went wrong')
                setRefund([])
                setTotal(0)
            }
            dispatch<any>(saveFiltersData('chargebacks', { ...parameters }))
        }
        else {
            message.error(submit.msg);
        }
        } catch (err) {
            console.warn(err)
        }
    }

    const getSignedUrl = async (fileName) => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.refundPresignedUrl,
            params: { file_name: fileName, url:`merchant/${merchantDetails?.id}/refund/chargeback/${fileName}.pdf`},
        })
        if (response.success) {
            return response.data.data
        }
    }

    return (
        <>
            <div className='w-full h-full inter '>
                <div style={{ marginTop: 0 }}>
                    <div className='bg-white flex justify-between p-2 mb-5'>
                        <Row className='items-center'>
                            <Tooltip title='Chargebacks are a consumer protection tool that allow consumers to get their money back for fraudulent charges or purchases that don’t live up to standards by submitting a dispute with their card issuer.'>
                                <InfoCircleOutlined className='text-blue fs20 cursor-pointer' />
                            </Tooltip>
                            <p className='ml-2'>
                                Chargebacks are different from refunds,a chargeback comes from your card issuer
                            </p>
                        </Row>
                        <Button
                            onClick={() => {
                                setEditOpen(true)
                            }}
                            variant='primary'
                            className='bg-[#2C9CFF]'
                        >
                            <CreditCardOutlined />
                            Trigger Chargeback
                        </Button>
                    </div>
                    <div className='overflow-auto'>
                        <div className='bg-white '>
                            <RenderSearchFilters
                                values={parameters}
                                setValues={(data, reset) =>
                                    setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))
                                }
                                page='chargebacks'
                                onSearch={handleSearchClick}
                                onReset={reset}
                            >
                                <>
                                    <Col className='flex items-center gap-x-3'>
                                        <Button onClick={handleSearchClick} variant='primary'>
                                            <SearchOutlined />
                                            Search
                                        </Button>
                                        <Button onClick={reset} variant='default'>
                                            <RedoOutlined />
                                            Reset
                                        </Button>
                                    </Col>
                                </>
                            </RenderSearchFilters>
                        </div>

                        <div className={'mt-5 bg-white rounded overflow-clip'}>
                            <Table
                                columns={columns}
                                dataSource={Chargeback || []}
                                style={{
                                    width: '100vw',
                                }}
                                pagination={{
                                    current: parameters.page, // Current page number
                                    pageSize: parameters.pageSize, // Number of items to display per page
                                    total: total, // Total number of items in the data array
                                    showSizeChanger: false,
                                    onChange: handlePaginationChange,
                                    position: ['topLeft', 'bottomLeft'],
                                    showTotal: (total, range) => (
                                        <p className='ml-4'>{`Showing ${range[0]}-${range[1]} of ${total} Records`}</p>
                                    ),
                                }}
                                scroll={{ x: 'max-content' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Drawer
                title='Chargeback Form'
                placement='right'
                className='pt-0'
                onClose={() => setEditOpen(false)}
                open={editOpen}
            >
                <div className='mt-0'>
                    {/* <h4 className='leading-5 mt-0 inter'>
                        Chargebacks are a consumer protection tool that allow consumers to get their money back for
                        fraudulent charges or purchases that don’t live up to standards by submitting a dispute with
                        their card issuer.
                    </h4> */}
                    <h5 className='leading-5  inter text-amber-400'>
                        *Chargebacks are different from refunds,a chargeback comes from your card issuer
                    </h5>
                </div>
                <Col className='w-full'>
                    <Space direction='vertical' className='w-full'>
                        <p className='mt-2'>*File Upload </p>
                        <Form.Item
                            className='mb-0'
                            // label='Upload your file'
                            valuePropName='fileList'
                            getValueFromEvent={() => {}}
                            // onChange = {() => setChargebackData((prev) => ({ ...prev, refund_request_file: '' }))}

                        >
                            <Upload action='/upload.do' listType='picture-card' maxCount={1}
                                onRemove={() => setChargebackData((prev) => ({ ...prev, refund_request_file: null }))}              
                                customRequest={async (options) => {
                                            const { onSuccess, onError, file, onProgress } = options
                                            const { size, name } = file as any
                                                const data = new FormData()
                                                data.append('file', file)
                                                let check = await handleFiles(data, name)
                                                if(check) onSuccess(true)
                                                // else onError();
                                        }}
                                accept='.jpg, .jpeg , .jpe, .tiff, .png, .webp, .bmp, .pdf, .doc, .docx, .docm, .dot, .dotx'>
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </div>
                            </Upload>
                        </Form.Item>
                        <p className='mt-2'>*Payment ID </p>
                        <Input
                            type='text'
                            value={chargebackData['payment_id']}
                            onChange={(e) => {
                                setChargebackData((prev) => ({ ...prev, payment_id: e.target.value }))
                            }}
                            placeholder='Payment ID*'
                        />
                        <p className='mt-2'>*Payment Provider </p>
                        <Select
                            className='w-full'
                            value={chargebackData['payment_provider']}
                            onChange={(e) => {
                                setChargebackData((prev) => ({ ...prev, payment_provider: e }))
                            }}
                            options={[
                                { value: 'ybl', label: 'YBL' },
                                { value: 'icici', label: 'ICICI' },
                            ]}
                            placeholder='Payment Provider'
                        />
                        {chargebackData.payment_provider !== 'icici' && (
                            <>
                                <p className='mt-2'>*NPC ID</p>
                                <Input
                                    type='text'
                                    value={chargebackData['npc_id']}
                                    placeholder='NPC ID'
                                    onChange={(e) => {
                                        setChargebackData((prev) => ({ ...prev, npc_id: e.target.value }))
                                    }}
                                />
                            </>
                        )}
                        <p className='mt-2'>New Transaction </p>
                        <Select
                            value={chargebackData['new']}
                            placeholder='New Transaction'
                            onChange={(e) => {
                                setChargebackData((prev) => ({ ...prev, new: e}))
                            }}
                            options={[
                                { value: 'true', label: 'True' },
                                { value: 'false', label: 'False' },
                            ]}
                        />
                        <p className='mt-2'>Customer Ref ID </p>
                        <Input
                            type='text'
                            value={chargebackData['cust_ref_id']}
                            placeholder='Customer Refrence Id'
                            onChange={(e) => {
                                setChargebackData((prev) => ({ ...prev, cust_ref_id: e.target.value }))
                            }}
                        />
                        <p className='mt-2'>ID </p>
                        <Input
                            type='text'
                            value={chargebackData['id']}
                            placeholder='ID'
                            onChange={(e) => {
                                setChargebackData((prev) => ({ ...prev, id: e.target.value }))
                            }}
                        />
                        <p className='mt-2'>*Chargeback request Description </p>
                        <TextArea
                            // type='text'
                            value={chargebackData['refund_request_description']}
                            placeholder='Chargeback Request Description*'
                            onChange={(e) => {
                                setChargebackData((prev) => ({ ...prev, refund_request_description: e.target.value }))
                            }}
                        />
                    </Space>
                </Col>
                <Row className='mt-4'>
                    <Col span={12}>
                        <Button onClick={() => {submitChargeback()}} variant='primary' className='w-[99%]'>
                            Submit
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            onClick={() => {
                                setEditOpen(false)
                            }}
                            variant='default'
                            className='w-[99%] ml-1'
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Drawer>
        </>
    )
}
