import { convertIsoToLocaleString } from '@gokwik/utilities'
import { ColumnProps, Tag, Tooltip } from 'gokwik-ui-kit'
import dayjs from 'dayjs'

export const internalUserColumns: ColumnProps<any>[] = [
    {
        title: <span>Settlement ID</span>,
        dataIndex: 'settlement_id',
        width: 180,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
        align: 'left',
        fixed: 'left',
    },
    {
        title: <span>Merchant Name</span>,
        dataIndex: 'Merchant.business_name',
        width: 150,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
        fixed: 'left',
    },

    {
        title: <span>To Settle</span>,
        dataIndex: 'to_settle_amount',
        width: 150,
        ellipsis: true,
        render: (text: string) => <div>₹ {text}</div>,
        align: 'right',
    },
    {
        title: <span>To Pay</span>,
        dataIndex: 'to_pay',
        width: 150,
        ellipsis: true,
        render: (text: string) => <div>₹ {text}</div>,
        align: 'right',
    },
    {
        title: <span>Final Settled Amount</span>,
        dataIndex: 'final_settled_amount',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>₹ {text}</div>,
        align: 'right',
    },
    {
        title: <span>Commission</span>,
        dataIndex: 'total_commission',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>₹ {text}</div>,
        align: 'right',
    },
    {
        title: <span>Payment Id</span>,
        dataIndex: 'payment_id',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'left',
    },

    {
        title: <span>Transaction Date</span>,
        dataIndex: 'bank_timestamp',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{convertIsoToLocaleString(text)}</div>,
        align: 'left',
    },

    {
        title: <span>Settlement Description</span>,
        dataIndex: 'status_description',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'left',
    },
    {
        title: <span>Settlement Status</span>,
        dataIndex: 'status',
        width: 180,
        ellipsis: true,
        render: (text: string) => (
            <div>
                <>
                    {' '}
                    {text === 'success' && (
                        <Tag
                            variant={'success'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Success
                        </Tag>
                    )}
                </>
                <>
                    {text === 'initiated' && (
                        <Tag
                            variant={'warning'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Initiated
                        </Tag>
                    )}
                </>
                <>
                    {text === 'failed' && (
                        <Tag
                            variant={'error'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Failed
                        </Tag>
                    )}
                </>
            </div>
        ),
        align: 'center',
    },
    {
        title: <span>Created At</span>,
        dataIndex: 'created_at',
        width: 200,
        ellipsis: true,
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: <span>Updated At</span>,
        dataIndex: 'updated_at',
        width: 200,
        ellipsis: true,
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: <span>Response Code</span>,
        dataIndex: 'response_code',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'left',
    },
    {
        title: <span>NPC Id</span>,
        dataIndex: 'npc_id',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'left',
    },
    {
        title: <span>Customer Reference Id</span>,
        dataIndex: 'cust_ref_id',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'left',
    },
    {
        title: <span>Failure Code</span>,
        dataIndex: 'failure_code',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'left',
    },
    {
        title: <span>UPI Commission</span>,
        dataIndex: 'upi_commission',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>₹ {text}</div>,
        align: 'right',
    },
    {
        title: <span>Refund Amount</span>,
        dataIndex: 'refund_amount',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>₹ {text}</div>,
        align: 'right',
    },
    {
        title: <span>Refund Commission</span>,
        dataIndex: 'refund_commission',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>₹ {text}</div>,
        align: 'right',
    },
    {
        title: <span>Total UPI Transactions</span>,
        dataIndex: 'total_upi_transactions',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'right',
    },
    {
        title: <span>Total Refund Transactions</span>,
        dataIndex: 'total_refund_transactions',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'right',
    },
    {
        title: <span>Total UPI Amount</span>,
        dataIndex: 'total_upi_amount',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>₹ {text}</div>,
        align: 'right',
    },

    {
        title: <span>Master Settlement Id</span>,
        dataIndex: 'master_settlement_id',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'left',
    },
    {
        title: <span>Payment Provider</span>,
        dataIndex: 'payment_provider',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'left',
    },
    {
        title: <span>Manual Deduction</span>,
        dataIndex: 'manual_deduction',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'left',
    },
    {
        title: <span>Payout Remark</span>,
        dataIndex: 'payout_remark',
        width: 180,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip title={text} placement='topLeft'>
                {text}
            </Tooltip>
        ),
        align: 'left',
    },
    {
        title: <span>GoKwik Settlement Id</span>,
        dataIndex: 'gokwik_settlement_id',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'left',
    },
    {
        title: <span>TimeOut Status</span>,
        dataIndex: 'timeout_status',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'left',
    },
    {
        title: <span>Is Deleted</span>,
        dataIndex: 'is_deleted',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'left',
    },
    {
        title: <span>Payout Type</span>,
        dataIndex: 'payout_type',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'left',
    },
    {
        title: <span>Instant Refund Fee</span>,
        dataIndex: 'instant_refund_fee',
        width: 180,
        ellipsis: true,
        render: (text: string) => <div>₹ {text}</div>,
        align: 'right',
    },
    {
        title: <span>Previous Unsettled Amount</span>,
        dataIndex: 'prev_unsettled_amount',
        width: 210,
        ellipsis: true,
        render: (text: string) => <div>₹ {text}</div>,
        align: 'right',
    },
]

export const merchantUserColumns: ColumnProps<any>[] = [
    {
        title: <span>Settlement ID</span>,
        dataIndex: 'settlement_id',
        width: 100,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
        align: 'left',
        fixed: 'left',
    },
    {
        title: <span>Final Settled Amount</span>,
        dataIndex: 'final_settled_amount',
        width: 80,
        ellipsis: true,
        render: (text: string) => <div>₹ {text}</div>,
        align: 'right',
    },
    {
        title: <span>Settlement Status</span>,
        dataIndex: 'status',
        width: 100,
        ellipsis: true,
        render: (text: string) => (
            <div>
                <>
                    {' '}
                    {text === 'success' && (
                        <Tag
                            variant={'success'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Success
                        </Tag>
                    )}
                </>
                <>
                    {text === 'initiated' && (
                        <Tag
                            variant={'warning'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Initiated
                        </Tag>
                    )}
                </>
                <>
                    {text === 'failed' && (
                        <Tag
                            variant={'error'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Failed
                        </Tag>
                    )}
                </>
            </div>
        ),
        align: 'center',
    },
    {
        title: <span>Transaction Amount</span>,
        dataIndex: 'total_upi_amount',
        width: 80,
        ellipsis: true,
        render: (text: string) => <div>₹ {text}</div>,
        align: 'right',
    },
    {
        title: <span>Refund Amount</span>,
        dataIndex: 'refund_amount',
        width: 80,
        ellipsis: true,
        render: (text: string) => <div>₹ {text}</div>,
        align: 'right',
    },
    {
        title: <span>Commission</span>,
        dataIndex: 'total_commission',
        width: 80,
        ellipsis: true,
        render: (text: string) => <div>₹ {text}</div>,
        align: 'right',
    },
    {
        title: <span>Payment Provider</span>,
        dataIndex: 'payment_provider',
        width: 100,
        ellipsis: true,
        render: (text: string) => <div>{text}</div>,
        align: 'left',
    },

    {
        title: <span>Created At</span>,
        dataIndex: 'created_at',
        width: 100,
        ellipsis: true,
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
    },
]
