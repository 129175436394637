import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'
import { makeAPICall } from '@gokwik/utilities'
import {
    Button,
    Checkbox,
    Col,
    Collapse,
    DeleteOutlined,
    Drawer,
    Input,
    Modal,
    PlusCircleOutlined,
    Row,
    Select,
    Switch,
    Tag,
    message,
    Tooltip,
} from 'gokwik-ui-kit'
import {
    Method,
    ProviderField,
    SelectDropdown,
    SelectedProvider,
    TerminalsInputValues,
} from '@library/utilities/interface'
import { PaymentMethods, SuccessStatusCodes } from '@library/utilities/constants/constants'
import { deepCopy } from '@library/utilities/helpers/helper'
import { SyncOutlined, UndoOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { PaymentProviderEnum } from '../payment-testing/payment-testing.enum'

interface Props {
    isEdit?: boolean
    id?: string
    open: boolean
    onCancel?: (e) => void
}

const inputValues: TerminalsInputValues = {
    subMerchantId: '',
    salt: '',
    merchantKey: '',
    merchantEmail: '',
    clientId: '',
    clientSecret: '',
    merchantToken: '',
    tmsMerchantKey: '',
    tmsSalt: '',
    tmsSKey: '',
    tmsVector: '',
}

const providerFields: Record<string, ProviderField[]> = {
    easebuzz: [
        { label: 'Sub Merchant ID', value: 'subMerchantId', key: 'sub_merchant_id', isRequired: true },
        { label: 'Salt', value: 'salt', key: 'easebuzz_salt', isRequired: true },
        { label: 'Merchant Key', value: 'merchantKey', key: 'easebuzz_merchant_key', isRequired: true },
        { label: 'Merchant Email', value: 'merchantEmail', key: 'easebuzz_merchant_email', isRequired: true },
        { label: 'Tms Merchant Key', value: 'tmsMerchantKey', key: 'tms_merchant_key', isRequired: false },
        { label: 'Tms Salt', value: 'tmsSalt', key: 'tms_salt', isRequired: false },
        { label: 'Tms SKey', value: 'tmsSKey', key: 'tms_s_key', isRequired: false },
        { label: 'Tms Vector', value: 'tmsVector', key: 'tms_vector', isRequired: false },
    ],
    payu: [
        { label: 'Sub Merchant ID', value: 'subMerchantId', key: 'sub_merchant_id', isRequired: true },
        { label: 'Salt', value: 'salt', key: 'payu_salt', isRequired: true },
        { label: 'Merchant Key', value: 'merchantKey', key: 'payu_merchant_key', isRequired: true },
    ],
    credpay: [
        { label: 'Sub Merchant ID', value: 'subMerchantId', key: 'sub_merchant_id', isRequired: true },
        { label: 'Client ID', value: 'clientId', key: 'credpay_client_id', isRequired: true },
        { label: 'Client Secret', value: 'clientSecret', key: 'credpay_client_secret', isRequired: true },
    ],
    snapmint: [
        { label: 'Sub Merchant ID', value: 'subMerchantId', key: 'sub_merchant_id', isRequired: true },
        { label: 'Merchant Token', value: 'merchantToken', key: 'snapmint_merchant_token', isRequired: true },
        { label: 'Merchant Key', value: 'merchantKey', key: 'snapmint_merchant_key', isRequired: true },
    ],
    billdesk: [{ label: 'Sub Merchant ID', value: 'subMerchantId', key: 'sub_merchant_id', isRequired: true }],
    bharatx: [{ label: 'Sub Merchant ID', value: 'subMerchantId', key: 'sub_merchant_id', isRequired: true }],
    gokwik: []
}

const tabs = {
    INPUT_FIELDS: 'inputFields',
    CONFIRM_CHANGES: 'confirmChanges',
}

interface Account {
    id?: string
    account?: string
    name: string
    pg_account_id: string
    active: boolean
    created_at?: number
    updated_at?: number
    deleted: boolean
    newlyAdded?: boolean
}

interface SplitSettlements {
    supported: boolean
    enabled: boolean
    isEnabledUpdated?: boolean
    isAccountsUpdated?: boolean
    accounts: Account[]
}

interface SplitSettlementsInfo {
    initialData: SplitSettlements | null
    updatedData: SplitSettlements | null
}

export default function ({ isEdit = false, id = '', open, onCancel }: Props) {
    const [selectedProvider, setSelectedProvider] = useState<SelectedProvider>(null)
    const [providers, setProviders] = useState<SelectDropdown[]>([])
    const [collapseItems, setCollapseItems] = useState([])
    const [bluePrint, setBluePrint] = useState<Method[]>([])
    const [initialBluePrint, setInitialBluePrint] = useState<Method[]>([])
    const [inputData, setInputData] = useState<TerminalsInputValues>({ ...inputValues })
    const [initialInputData, setInitialInputData] = useState<TerminalsInputValues>({ ...inputValues })
    const [updatedValues, setUpdatedValues] = useState<{
        input: TerminalsInputValues | {}
        bluePrint: Method[]
        splitCollection: SplitSettlements | null
    }>({
        input: {},
        bluePrint: [],
        splitCollection: null,
    })
    const [initialTerminalStatus, setInitialTerminalStatus] = useState<boolean>(false)
    const [isTerminalActive, setTerminalState] = useState<boolean>(false)
    const [currentTab, setCurrentTab] = useState<string>(tabs.INPUT_FIELDS)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [skipDuplicateCredentials, setSkipDuplicateCredentials] = useState<boolean>(false)
    const [splitSettlementsInfo, setSplitSettlementsInfo] = useState<SplitSettlementsInfo>({
        initialData: null,
        updatedData: null,
    })
    const [accounts, setAccounts] = useState<SelectDropdown[]>([])
    const merchantData = useSelector(getMerchantDetails)
    const isDevEnv = process.env?.NODE_ENV === 'development'
    const [showSyncModal, setShowSyncModal] = useState<boolean>(false);
    const [syncDiffData, setSyncDiffData] = useState<ISyncTerminalResponse['data'] | null>(null);
    const [syncMode, setSyncMode] = useState<string>('');
    const [lastSyncDate, setLastSyncDate] = useState<string>('');
    const userDetails = useSelector(getUserDetails)
    const [showRestoreModal, setShowRestoreModal] = useState<boolean>(false);
    const [isSyncing, setIsSyncing] = useState<boolean>(false);
    const [isRestoring, setIsRestoring] = useState<boolean>(false);

    useEffect(() => {
        getSupportedProviders()
    }, [])

    useEffect(() => {
        if (id && isEdit && providers.length > 0) {
            getTerminal(id)
        }
    }, [id, isEdit, providers.length])

    async function getSupportedProviders() {
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.supportedProviders,
            })
            if (response.data?.data?.length > 0) {
                const activeProviders = response.data.data
                const options = activeProviders.map(({ name, provider }) => ({
                    label: name,
                    value: provider,
                }))
                setProviders(options)
            } else {
                setProviders([])
                const errMessage = response?.response?.data?.error?.reference?.message ?? 'Failed to fetch providers'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function getBluePrints(provider: string, option) {
        setSelectedProvider(option)
        clearInputValues()
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.terminalConfigs.getBluePrints(provider),
            })
            if (SuccessStatusCodes.includes(response?.data?.status_code) && response.data?.data) {
                const resData = response.data.data
                setSelectedProvider({
                    label: resData?.name ?? resData.provider,
                    value: resData.provider,
                    type: resData.type,
                })
                const bluePrintData = resData.methods.map((method) => {
                    let isInstrumentsVisible = true
                    const instruments = method.instruments.map((instrument) => {
                        if (instrument?.visible === false) isInstrumentsVisible = false
                        return {
                            ...instrument,
                            enabled: instrument.default_enabled,
                        }
                    })
                    return {
                        ...method,
                        enabled: method.default_enabled,
                        instruments,
                        visible: isInstrumentsVisible,
                    }
                })
                setBluePrint(bluePrintData)
                updateCollapseItems(bluePrintData)
            } else {
                setBluePrint([])
                const errMessage = response?.response?.data?.error?.reference?.message ?? 'Failed to fetch blueprint'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function getAccounts() {
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.terminalConfigs.supportedAccounts(),
            })
            if (SuccessStatusCodes.includes(response?.data?.status_code) && response?.data?.data) {
                const resData =
                    response.data.data?.map((acc) => ({
                        label: acc?.name,
                        value: acc?.account,
                    })) ?? []
                setAccounts(resData)
            } else {
                setAccounts([])
                const errMessage =
                    response?.response?.data?.error?.reference?.message ?? 'Failed to fetch Split settlement accounts'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function createTerminal() {
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        try {
            const credentials = providerFields[selectedProvider.value].reduce((acc, field) => {
                if (field.key !== 'sub_merchant_id') {
                    return {
                        ...acc,
                        ...(inputData[field.value] && { [field.key]: inputData[field.value] }),
                    }
                }
                return acc
            }, {})
            const body: any = {
                merchant_id: merchantData.m_id,
                provider: selectedProvider.value,
                type: selectedProvider.type,
                methods: bluePrint,
            }

            // Only include this for dev env
            if (skipDuplicateCredentials) {
                body['skip_duplicate_credentials_verification'] = true
            }

            // Only include credentials if it's not empty
            if (Object.keys(credentials).length > 0) {
                body.credentials = credentials
            }

            // Only include subMerchantId if it's required by the selected provider
            if (providerFields[selectedProvider.value].some((field) => field.value === 'subMerchantId')) {
                body.sub_merchant_id = inputData.subMerchantId
            }

            if (
                splitSettlementsInfo?.initialData?.supported &&
                splitSettlementsInfo?.updatedData &&
                Object.keys(splitSettlementsInfo?.updatedData).length > 0 &&
                (splitSettlementsInfo?.updatedData?.enabled || !!splitSettlementsInfo?.updatedData?.accounts?.length)
            ) {
                body.split_settlements = {
                    enabled: true,
                    accounts: splitSettlementsInfo?.updatedData?.accounts.map((acc) => ({
                        name: acc.account,
                        pg_account_id: acc.pg_account_id,
                        active: acc.active ?? true,
                        deleted: false,
                    })),
                }
            }

            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.terminalConfigs.createTerminal,
                payload: body,
            })
            if (SuccessStatusCodes.includes(response?.data?.status_code)) {
                message.success('Terminal created successfully')
                setTimeout(() => onCancel(true), 200)
            } else {
                const errMessage =
                    (response?.response?.data?.error?.reference?.message || response?.response?.data?.error?.message) ??
                    'Failed to create terminal'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    const deepCopySplitSettlements = (splitSettlements) => ({
        ...splitSettlements,
        accounts: splitSettlements.accounts.map((account) => ({ ...account })),
    })

    async function getTerminal(id: string) {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.terminalConfigs.getTerminal(id),
            })
            if (SuccessStatusCodes.includes(response?.data?.status_code) && response.data?.data) {
                const terminalData = response.data.data
                setSelectedProvider({
                    label: terminalData?.name ?? terminalData.provider,
                    value: terminalData.provider,
                    type: terminalData?.type,
                })
                setLastSyncDate(terminalData?.last_sync_date)
                const providerCredentials = providerFields[terminalData.provider].reduce((acc, field) => {
                    return {
                        ...acc,
                        [field.value]: terminalData?.credentials?.[field.key],
                    }
                }, {})
                setInitialInputData((prevState) => ({
                    ...prevState,
                    ...providerCredentials,
                    subMerchantId: terminalData.sub_merchant_id,
                }))
                setInputData((prevState) => ({
                    ...prevState,
                    ...providerCredentials,
                    subMerchantId: terminalData.sub_merchant_id,
                }))
                setTerminalState(terminalData.active)
                setInitialTerminalStatus(terminalData.active)
                const updatedMethods = terminalData.methods.map((method) => {
                    const isInstrumentsNotVisible = method?.instruments?.some((inst) => inst.visible === false)
                    return {
                        ...method,
                        visible: !isInstrumentsNotVisible,
                    }
                })
                if (isEdit) setInitialBluePrint(updatedMethods)
                setBluePrint(updatedMethods)
                updateCollapseItems(updatedMethods)
                if (terminalData?.split_settlements?.supported) {
                    getAccounts()
                }
                const initialSplitSettlements = terminalData?.split_settlements
                    ? deepCopySplitSettlements(terminalData.split_settlements)
                    : null
                const updatedSplitSettlements = terminalData?.split_settlements
                    ? deepCopySplitSettlements(terminalData.split_settlements)
                    : null

                setSplitSettlementsInfo({
                    initialData: initialSplitSettlements,
                    updatedData: updatedSplitSettlements,
                })
            } else {
                const errMessage = response?.response?.data?.error?.reference?.message ?? 'Failed to fetch terminal'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function updateTerminal() {
        try {
            const credentials = providerFields[selectedProvider.value].reduce((acc, field) => {
                if (field.key !== 'sub_merchant_id') {
                    return {
                        ...acc,
                        ...(inputData[field.value] && { [field.key]: inputData[field.value] }),
                    }
                }
                return acc
            }, {})
            const methods = bluePrint.map((method) => {
                if ('visible' in method) {
                    delete method.visible
                }
                return method
            })
            const body: any = {
                provider: selectedProvider.value,
                methods,
                active: isTerminalActive,
            }

            // Only include credentials if it's not empty
            if (Object.keys(credentials).length > 0) {
                body.credentials = credentials
            }
            if (
                splitSettlementsInfo?.initialData?.supported &&
                splitSettlementsInfo?.updatedData &&
                Object.keys(splitSettlementsInfo?.updatedData).length > 0 &&
                (splitSettlementsInfo?.updatedData?.enabled || !!splitSettlementsInfo?.updatedData?.accounts?.length)
            ) {
                body.split_settlements = {
                    enabled: splitSettlementsInfo?.updatedData.enabled,
                    accounts: splitSettlementsInfo?.updatedData?.accounts.map((acc) => ({
                        name: acc.account,
                        pg_account_id: acc.pg_account_id,
                        active: acc.active ?? true,
                        deleted: false,
                    })),
                }
            }

            // Only include subMerchantId if it's required by the selected provider
            if (providerFields[selectedProvider.value].some((field) => field.value === 'subMerchantId')) {
                body.sub_merchant_id = inputData.subMerchantId
            }

            let response = await makeAPICall({
                method: 'put',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.terminalConfigs.updateTerminal(id),
                payload: body,
            })
            if (SuccessStatusCodes.includes(response?.data?.status_code)) {
                message.success('Terminal updated successfully')
                setTimeout(() => onCancel(true), 200)
            } else {
                const errMessage =
                    (response?.response?.data?.error?.reference?.message || response?.response?.data?.error?.message) ??
                    'Failed to update terminal'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    function updateCollapseItems(methods = []) {
        let items = []
        try {
            items = methods.map((blueprint, index) => {
                return {
                    key: `${blueprint.method}-${index}`,
                    label: (
                        <section className='flex space-around'>
                            <p className='flex-1'>{blueprint.name}</p>
                            <div className='flex-1'>
                                <Switch
                                    checked={blueprint.enabled}
                                    onChange={(value, event) =>
                                        updateBluePrint(event, blueprint.method, '', value, false)
                                    }
                                />
                            </div>
                        </section>
                    ),
                    children:
                        blueprint?.instruments && blueprint.instruments?.length > 0 ? (
                            <>
                                <section className='border border-solid border-gray-200 rounded-lg bg-white'>
                                    <Row>
                                        <Col span={24}>
                                            <div className='flex justify-between bg-black/[.06] p-3 pl-4 pr-4'>
                                                <p className='text-sm flex-1 text-gray-500'>{blueprint.name} Type</p>
                                                <span className='text-sm text-gray-500'>Status</span>
                                            </div>
                                        </Col>
                                        {blueprint.instruments.map((instrument, key) => {
                                            return (
                                                <Col span={24} key={`${instrument.name}-${key}`}>
                                                    <div className='flex justify-between p-4 border-b border-gray-300'>
                                                        <span className='text-sm flex-1'>{instrument.name}</span>
                                                        <Switch
                                                            disabled={instrument?.visible === false}
                                                            checked={instrument.enabled}
                                                            onChange={(value, event) =>
                                                                updateBluePrint(
                                                                    event,
                                                                    blueprint.method,
                                                                    instrument.instrument,
                                                                    value,
                                                                    false,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </section>
                                {PaymentMethods.CREDIT_CARD === blueprint.method &&
                                    blueprint?.instruments?.length > 0 && (
                                        <section className='mt-3 flex gap-2'>
                                            <span className='text-sm'>International</span>
                                            <Switch
                                                checked={blueprint.instruments[0].data?.international_enabled ?? false}
                                                onChange={(value, event) =>
                                                    updateBluePrint(event, blueprint.method, 'all', value, true)
                                                }
                                            />
                                        </section>
                                    )}
                            </>
                        ) : (
                            <p>No methods available</p>
                        ),
                    configkey: blueprint.method,
                }
            })
        } catch (error) {
            items = []
        }
        setCollapseItems(items)
    }

    function updateBluePrint(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        method: string,
        instrument: string,
        value: boolean,
        isInternational: boolean,
    ) {
        event?.stopPropagation()
        setBluePrint((prevBlueprint) => {
            try {
                const updatedBlueprint = prevBlueprint.map((item) => {
                    if (item?.method !== method) return item
                    if (instrument && item?.instruments?.length) {
                        let updatedInstruments = item.instruments?.map((inst) => {
                            if (inst?.instrument !== instrument && !isInternational) return inst
                            if (isInternational) {
                                return { ...inst, data: { international_enabled: value } }
                            }
                            return { ...inst, enabled: value }
                        })
                        const isAnyChildEnabled = updatedInstruments.some((inst) => inst.enabled)
                        if (item?.visible === false) {
                            updatedInstruments = updateAllNonVisibleInstruments(updatedInstruments, isAnyChildEnabled)
                        }
                        return { ...item, instruments: updatedInstruments, enabled: isAnyChildEnabled }
                    } else {
                        let updatedInstruments = item.instruments ?? []
                        if (item?.visible === false) {
                            updatedInstruments = updateAllNonVisibleInstruments(updatedInstruments, value)
                        }
                        return { ...item, enabled: value, instruments: updatedInstruments }
                    }
                })
                updateCollapseItems(updatedBlueprint)
                return updatedBlueprint
            } catch (error) {
                return prevBlueprint
            }
        })
    }

    function updateAllNonVisibleInstruments(instruments = [], value) {
        return instruments?.map((inst) => {
            if (inst?.visible === false) {
                return { ...inst, enabled: value }
            }
            return inst
        })
    }

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>, field: string) {
        const { value } = event.target
        setInputData((prevInputData) => ({
            ...prevInputData,
            [field]: value,
        }))
    }

    function validateInputFields() {
        if (!selectedProvider?.value) {
            message.error(`Please select provider`)
            return false
        }
        const isValid = providerFields[selectedProvider.value].every((field) => {
            if (field.isRequired && !inputData[field.value]) {
                message.error(`${field.label} is required`)
                return false
            }
            return true
        })
        return isValid
    }

    function validateSplitInputFields() {
        try {
            const invalidAccounts = splitSettlementsInfo?.updatedData?.accounts?.filter((updatedAccount) => {
                if (!updatedAccount.name) {
                    message.error('Split Collection Account name is missing')
                    return true
                }
                if (!updatedAccount.pg_account_id) {
                    message.error('Split Collection Account ID is missing')
                    return true
                }
                return false
            })
            return splitSettlementsInfo?.updatedData?.accounts?.length > 0 && invalidAccounts?.length === 0
        } catch (error) {
            return false
        }
    }

    function getUpdatedSplitSettlementsInfo(
        initialData: SplitSettlements | null,
        updatedData: SplitSettlements | null,
    ) {
        if (!initialData || !updatedData) {
            return {}
        }

        const differences: any = { isEnabledUpdated: false, isAccountsUpdated: false }

        // Compare the 'enabled' status
        if (initialData.enabled !== updatedData.enabled) {
            differences.enabled = updatedData.enabled
            differences.isEnabledUpdated = true
        }

        // Compare accounts
        const updatedAccounts = updatedData.accounts.filter((updatedAccount, index) => {
            const initialAccount = initialData.accounts[index]
            return (
                !initialAccount ||
                initialAccount.name !== updatedAccount.name ||
                initialAccount.pg_account_id !== updatedAccount.pg_account_id ||
                initialAccount.active !== updatedAccount.active
            )
        })

        if (updatedAccounts.length > 0) {
            differences.accounts = updatedAccounts
            differences.isAccountsUpdated = true
        }
        return differences
    }

    function validateDetails() {
        const valid = validateInputFields()
        const isSplitSettlementSupported = splitSettlementsInfo?.initialData?.supported
        const numOfAccounts = splitSettlementsInfo?.updatedData?.accounts?.length ?? 0
        if (!valid) {
            return false
        }
        if(isSplitSettlementSupported && numOfAccounts > 0) {
            const isValidSplitDetails = validateSplitInputFields()
            if(!isValidSplitDetails) return false;
        }
        if (isEdit && id) {
            const updatedData = getUpdatedBluePrintValues(initialBluePrint, bluePrint)
            const updatedInputData = getUpdatedInputValues(initialInputData, inputData)
            const updatedSplitSettlementsInfo = getUpdatedSplitSettlementsInfo(
                splitSettlementsInfo?.initialData,
                splitSettlementsInfo?.updatedData,
            )
            if (
                updatedData.length > 0 ||
                Object.keys(updatedInputData).length > 0 ||
                initialTerminalStatus !== isTerminalActive ||
                (isSplitSettlementSupported &&
                    (updatedSplitSettlementsInfo?.isAccountsUpdated === true ||
                        updatedSplitSettlementsInfo?.isEnabledUpdated === true))
            ) {
                setUpdatedValues({
                    input: updatedInputData,
                    bluePrint: updatedData,
                    splitCollection: updatedSplitSettlementsInfo,
                })
                setCurrentTab(tabs.CONFIRM_CHANGES)
            } else {
                message.info(`No changes have been made`)
            }
        } else {
            createTerminal()
        }
    }

    function getUpdatedBluePrintValues(initialState: Method[], newState: Method[]) {
        try {
            const updatedValues = []
            for (const currItem of newState) {
                let newItem = deepCopy(currItem)
                let isItemUpdated = false
                const initialItem = initialState.find((item) => item.method === newItem.method)
                if (initialItem.enabled !== newItem.enabled) {
                    newItem['isUpdated'] = true
                    isItemUpdated = true
                }
                for (const newInstrument of newItem.instruments) {
                    const initialInstrument = initialItem.instruments.find(
                        (instrument) => instrument.instrument === newInstrument.instrument,
                    )
                    const isInstrumentEnabledChanged = initialInstrument.enabled !== newInstrument.enabled
                    const isInternationalEnabledChanged =
                        initialInstrument?.data?.international_enabled !== newInstrument?.data?.international_enabled
                    if (isInstrumentEnabledChanged) {
                        newInstrument['isUpdated'] = true
                        newItem['isInstrumentsUpdated'] = true
                        isItemUpdated = true
                    }
                    if (isInternationalEnabledChanged && !newItem['isInternationalUpdated']) {
                        newItem['isInternationalUpdated'] = true
                        isItemUpdated = true
                    }
                }
                if (isItemUpdated) updatedValues.push(newItem)
            }
            return updatedValues
        } catch (error) {
            return []
        }
    }

    function getUpdatedInputValues(initialState: TerminalsInputValues, newState: TerminalsInputValues) {
        const updatedInputValues = {}
        for (const key in newState) {
            if (newState[key] !== initialState?.[key]) {
                updatedInputValues[key] = newState[key]
            }
        }
        return updatedInputValues
    }

    function clearInputValues() {
        setInputData(inputValues)
    }

    function moveToInputTab() {
        setCurrentTab(tabs.INPUT_FIELDS)
    }

    function getTitle() {
        if (currentTab === tabs.CONFIRM_CHANGES) {
            return 'Verify information'
        } else if (isEdit) {
            return 'Edit Terminal'
        } else {
            return 'Add Terminal'
        }
    }

    function getFooter() {
        try {
            return (
                <Row gutter={12}>
                    <Col span={12}>
                        <Button
                            className='w-full'
                            variant='primary'
                            onClick={currentTab === tabs.INPUT_FIELDS ? validateDetails : updateTerminal}
                        >
                            {currentTab === tabs.INPUT_FIELDS ? 'Save' : 'Confirm Changes'}
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            className='w-full'
                            onClick={() => (currentTab === tabs.INPUT_FIELDS ? onCancel(false) : moveToInputTab())}
                        >
                            {currentTab === tabs.INPUT_FIELDS ? 'Discard' : 'Back'}
                        </Button>
                    </Col>
                </Row>
            )
        } catch (_) {
            return <></>
        }
    }

    function handleStatusChange(status) {
        if (!status) {
            setShowModal(true)
        } else {
            setTerminalState(true)
        }
    }

    function deactivateTerminal() {
        setShowModal(false)
        setTerminalState(false)
    }

    function getTag(isSuccess = true, successText = 'Activated', failText = 'Deactivated') {
        try {
            return (
                <Tag
                    className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                    variant={isSuccess ? 'success' : 'error'}
                >
                    {isSuccess ? successText : failText}
                </Tag>
            )
        } catch (_) {
            return <></>
        }
    }

    function handleSplitSettlementStatusChange(enabled: boolean) {
        setSplitSettlementsInfo((prevInfo) => ({
            ...prevInfo,
            updatedData: {
                ...prevInfo.updatedData,
                enabled,
            },
        }))
    }

    function addNewAccount() {
        const newAccount = {
            name: '',
            pg_account_id: '',
            active: true,
            deleted: false,
            newlyAdded: true,
        }
        setSplitSettlementsInfo((prevInfo) => {
            return {
                ...prevInfo,
                updatedData: {
                    ...prevInfo.updatedData,
                    accounts: [...(prevInfo.updatedData?.accounts ?? []), newAccount],
                },
            }
        })
    }

    function handleAccountInputChange(value: string | boolean, field: string, index: number, option?: SelectDropdown) {
        setSplitSettlementsInfo((prevInfo) => {
            const updatedAccounts = prevInfo.updatedData.accounts.map((account, idx) => {
                if (idx === index) {
                    return {
                        ...account,
                        [field]: value,
                        ...(field === 'name' ? { account: option.value, name: option.label } : {}),
                    }
                }
                return account
            })
            return {
                ...prevInfo,
                updatedData: {
                    ...prevInfo.updatedData,
                    accounts: updatedAccounts,
                },
            }
        })
    }

    function deleteAccount(index: number) {
        setSplitSettlementsInfo((prevInfo) => {
            const updatedAccounts = prevInfo.updatedData.accounts.filter((_, idx) => idx !== index)
            return {
                ...prevInfo,
                updatedData: {
                    ...prevInfo.updatedData,
                    accounts: updatedAccounts,
                },
            }
        })
    }

    const NoChangesToSync = () => (
        <div className="text-center py-6">
            <p className="text-gray-600 mb-2">
                No changes to sync
            </p>
            <p className="text-gray-500 text-sm">
                Your terminal configuration is already up to date.
            </p>
            <Row className='flex justify-center mt-6'>
                <Col>
                    <Button className='w-40' onClick={() => setShowSyncModal(false)}>
                        Close
                    </Button>
                </Col>
            </Row>
        </div>
    )

    async function resyncTerminal() {
        if (!id) {
            message.error('Terminal ID is required')
            return
        }
        try {
            setIsSyncing(true)
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.syncTerminal + `/${selectedProvider.value}`,
                payload: {
                    merchantId: merchantData.m_id,
                    source: 'dashboard',
                    email: userDetails.email,
                }
            })
            if (SuccessStatusCodes.includes(response?.data?.status_code)) {
                message.success('Terminal synced successfully')
                getTerminal(id)
            } else {
                const errMessage = response?.response?.data?.error?.reference?.message ?? 'Failed to sync terminal'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsSyncing(false)
            setShowSyncModal(false)
        }
    }

    async function fetchSyncDiff() {
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_AUX_BASE_URL + '/v1/methods-onboarding/diff',
                payload: {
                    merchantId: merchantData.m_id,
                    source: 'dashboard',
                    email: userDetails.email,
                    provider: selectedProvider.value
                }
            })
            if (SuccessStatusCodes.includes(response?.data?.status_code)) {
                setSyncDiffData(response.data.data?.diff_data)
                setSyncMode(response.data.data?.mode)
                setShowSyncModal(true)
            } else {
                const errMessage = response?.response?.data?.error?.reference?.message ?? 'Failed to fetch sync differences'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    function handleSyncClick() {
        fetchSyncDiff()
    }

    async function restoreTerminal() {
        if (!id) {
            message.error('Terminal ID is required')
            return
        }
        try {
            setIsRestoring(true)
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_AUX_BASE_URL + `/v1/methods-onboarding/restore-terminal`,
                payload: {
                    merchantId: merchantData.m_id,
                    source: 'dashboard',
                    email: userDetails.email,
                    provider: selectedProvider.value
                }
            })
            if (SuccessStatusCodes.includes(response?.data?.status_code)) {
                message.success('Terminal restored successfully')
                getTerminal(id)
            } else {
                const errMessage = response?.response?.data?.error?.reference?.message ?? 'Failed to restore terminal'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsRestoring(false)
            setShowRestoreModal(false)
        }
    }

    async function fetchRestoreDiff() {
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_AUX_BASE_URL + '/v1/methods-onboarding/revert-diff',
                payload: {
                    merchantId: merchantData.m_id,
                    source: 'dashboard',
                    email: userDetails.email,
                    provider: selectedProvider.value
                }
            })
            if (SuccessStatusCodes.includes(response?.data?.status_code)) {
                setSyncDiffData(response.data.data?.diff_data)
                setShowRestoreModal(true)
            } else {
                const errMessage = response?.response?.data?.error?.reference?.message ?? 'Failed to fetch restore differences'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    function handleRestoreClick() {
        fetchRestoreDiff()
    }

    function renderInstrumentChanges(config: InstrumentConfig, method: string) {
        if (!config) return null;
        
        // Helper function to get instrument label from blueprint
        const getInstrumentLabel = (instrumentKey: string) => {
            const methodConfig = bluePrint.find(m => m.method.toLowerCase() === method.toLowerCase());
            const instrument = methodConfig?.instruments?.find(
                i => i.instrument.toLowerCase() === instrumentKey.toLowerCase()
            );
            return instrument?.name || instrumentKey.toLowerCase().replace(/_/g, ' ');
        };

        // Combine enabled and disabled instruments
        const allChanges = [
            ...config.instruments_enabled.map(inst => ({ name: inst, action: 'enable' })),
            ...config.instruments_disabled.map(inst => ({ name: inst, action: 'disable' }))
        ];
        
        if (allChanges.length === 0) return null;

        return (
            <div className="w-full">
                <table className="w-full">
                    <thead>
                        <tr className="bg-gray-50">
                            <th className="text-left py-2 px-4 text-sm font-medium text-gray-600">Instrument</th>
                            <th className="text-right py-2 px-4 text-sm font-medium text-gray-600">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allChanges.map(({ name, action }) => (
                            <tr key={name} className="border-b border-gray-100">
                                <td className="py-2 px-4 text-sm">{getInstrumentLabel(name)}</td>
                                <td className="text-right py-2 px-4">
                                    <Tag
                                        className="!px-2 !py-0.5 rounded-full text-xs"
                                        variant={action === 'enable' ? "success" : "error"}
                                    >
                                        {action === 'enable' ? 'Enable' : 'Disable'}
                                    </Tag>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    const LogModeMessage = () => (
        <div className="flex items-center gap-2 mt-4 mb-2 bg-blue-50 p-3 rounded-lg">
            <div className="w-2 h-2 rounded-full bg-blue-500"></div>
            <p className="text-blue-700 text-sm m-0">
                This is a log mode. No changes will be applied to the terminal.
            </p>
        </div>
    );

    return (
        <>
            <Drawer title={getTitle()} open={open} onClose={() => onCancel(false)} width={600} footer={getFooter()}>
                {currentTab === tabs.INPUT_FIELDS ? (
                    <>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Select
                                    className='w-full'
                                    label='Provider*'
                                    placeholder='Please select'
                                    onChange={(value, option) => getBluePrints(value, option)}
                                    options={providers}
                                    value={selectedProvider?.value}
                                    disabled={isEdit}
                                />
                            </Col>
                            {selectedProvider?.value &&
                                providerFields[selectedProvider.value]?.map((field) => (
                                    <Col span={8} key={field.value}>
                                        <Input
                                            placeholder={`Enter ${field.label}`}
                                            label={`${field.label}${field.isRequired ? '*' : ''}`}
                                            value={inputData?.[field.value]}
                                            onChange={(e) => handleInputChange(e, field.value)}
                                        />
                                    </Col>
                                ))}
                            {isDevEnv && (
                                <Col span={24} className='flex items-center'>
                                    <Checkbox
                                        checked={skipDuplicateCredentials}
                                        onChange={(e) => setSkipDuplicateCredentials(e.target.checked)}
                                    >
                                        Skip duplicate credentials verification
                                    </Checkbox>
                                </Col>
                            )}
                        </Row>
                        <Row gutter={[8, 8]}>
                            {collapseItems.length > 0 && (
                                <>
                                    <Col span={24}>
                                        <div className='flex justify-between items-end'>
                                            <h4 className='text-base font-medium mb-1'>Methods</h4>
                                            {selectedProvider?.value === PaymentProviderEnum.EASEBUZZ && (
                                                <div className="flex items-center gap-1">
                                                    {lastSyncDate && (
                                                        <span className="text-gray-500 text-xs">
                                                            Last synced: {lastSyncDate ? new Date(lastSyncDate).toLocaleString() : 'Never'}
                                                        </span>
                                                    )}
                                                    <Button 
                                                        type="link"
                                                        className='px-1 pr-0'
                                                        onClick={handleSyncClick}
                                                        icon={<SyncOutlined />}
                                                    >
                                                        Sync Terminal
                                                    </Button>
                                                    <div className="flex items-center">
                                                        <Button 
                                                            type="link"
                                                            className='px-0 text-red-500'
                                                            onClick={handleRestoreClick}
                                                            icon={<UndoOutlined />}
                                                        >
                                                            Restore
                                                        </Button>
                                                        <Tooltip 
                                                            title="This will restore last synced state for the terminal"
                                                            placement="bottom"
                                                        >
                                                            <InfoCircleOutlined className="text-gray-400 ml-1 text-sm" />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className='bg-white overflow-y-auto rounded'>
                                            <Collapse
                                                className='rounded-lg'
                                                items={collapseItems}
                                                expandIconPosition='end'
                                            />
                                        </div>
                                    </Col>
                                </>
                            )}
                        </Row>
                        {isEdit && id && (
                            <Row className='mt-2'>
                                <div className='flex items-center gap-x-2'>
                                    <h4 className='text-base font-medium pr-2'>Terminal Status</h4>
                                    <Switch
                                        checked={isTerminalActive}
                                        onChange={(value) => handleStatusChange(value)}
                                    />
                                </div>
                            </Row>
                        )}
                        {!!selectedProvider?.value && !!splitSettlementsInfo?.initialData?.supported && (
                            <>
                                <Row className='mt-6'>
                                    <div className='flex items-center gap-x-2'>
                                        <h4 className='text-base font-medium pr-2 mt-0 mb-0'>Split Collection</h4>
                                        {isEdit && (
                                            <Switch
                                                checked={splitSettlementsInfo?.updatedData?.enabled}
                                                onChange={handleSplitSettlementStatusChange}
                                            />
                                        )}
                                    </div>
                                </Row>
                                <Row className='pl-1 pr-1'>
                                    {!!splitSettlementsInfo?.updatedData?.accounts?.length && (
                                        <>
                                            <Col span={24} className='mt-4'>
                                                <Row gutter={[24, 24]}>
                                                    <Col span={10}>
                                                        <p className='text-[14px] pr-2 mt-0 mb-0'>Name</p>
                                                    </Col>
                                                    <Col span={10}>
                                                        <p className='text-[14px] pr-2 mt-0 mb-0'>Account Id</p>
                                                    </Col>
                                                    <Col span={4}>
                                                        <p className='text-[14px] pr-2 mt-0 mb-0'>Status</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                {splitSettlementsInfo?.updatedData?.accounts.map((field, key) => (
                                                    <Row key={key} gutter={[24, 24]} className='mt-3'>
                                                        <Col span={10}>
                                                            <Select
                                                                className='w-full'
                                                                placeholder={`Enter Label`}
                                                                onChange={(value, option) =>
                                                                    handleAccountInputChange(
                                                                        value,
                                                                        'name',
                                                                        key,
                                                                        option as SelectDropdown,
                                                                    )
                                                                }
                                                                options={accounts}
                                                                value={field?.account}
                                                            />
                                                        </Col>
                                                        <Col span={10}>
                                                            <Input
                                                                placeholder={`Enter Account Id`}
                                                                value={field?.pg_account_id}
                                                                onChange={(e) =>
                                                                    handleAccountInputChange(
                                                                        e.target.value,
                                                                        'pg_account_id',
                                                                        key,
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                        <Col span={4} className='flex items-center'>
                                                            {field?.newlyAdded !== undefined ? (
                                                                <>
                                                                    <Button
                                                                        type='link'
                                                                        onClick={() => deleteAccount(key)}
                                                                        disabled={!!field.id}
                                                                        className='p-0'
                                                                    >
                                                                        <DeleteOutlined
                                                                            className={`text-xl ${
                                                                                !!field.id
                                                                                    ? 'text-gray-500'
                                                                                    : 'text-red-500'
                                                                            }`}
                                                                        />
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                <div
                                                                    className={`text-sm text-center ${
                                                                        field?.active
                                                                            ? 'text-green-500'
                                                                            : 'text-red-500'
                                                                    }`}
                                                                >
                                                                    {field?.active ? 'Active' : 'Inactive'}
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </Col>
                                        </>
                                    )}
                                    <Col span={24} className='mt-2'>
                                        <Button onClick={addNewAccount} className='mt-2'>
                                            <PlusCircleOutlined /> Add Collection Account
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Row className='mb-4'>
                            <span className='front-normal'>Are you sure you want to update the below information?</span>
                        </Row>
                        <Row gutter={[16, 16]}>
                            {selectedProvider?.value &&
                                providerFields[selectedProvider.value]
                                    ?.filter((field) => field.value in updatedValues.input)
                                    ?.map((field) => (
                                        <Col span={8} key={field.value}>
                                            <div className='border border-solid border-gray-200 rounded-lg bg-white flex-column p-2'>
                                                <p className='text-sm text-gray-500 pb-1'>{field.label}</p>
                                                <span className='text-sm block truncate'>
                                                    {inputData?.[field.value] ? inputData[field.value] : '-'}
                                                </span>
                                            </div>
                                        </Col>
                                    ))}
                        </Row>
                        {initialTerminalStatus !== isTerminalActive && (
                            <Row gutter={[16, 16]} className='mt-4 pl-2'>
                                <div className='flex items-center'>
                                    <p className='text-sm text-gray-500 pr-2'>Terminal Status -</p>
                                    <span className='text-sm'>{getTag(isTerminalActive)}</span>
                                </div>
                            </Row>
                        )}
                        <Row gutter={[16, 16]} className='mt-4'>
                            {updatedValues?.bluePrint?.map((item, index) => (
                                <section className='rounded-2xl bg-gray-100 w-full p-4' key={`${item.name}-${index}`}>
                                    <div className='flex justify-between'>
                                        <p className='text-sm'>{item?.name}</p>
                                        {item?.isUpdated && (
                                            <span className='text-sm'>
                                                {getTag(item?.enabled, 'Activated', 'Disabled')}
                                            </span>
                                        )}
                                    </div>
                                    {item?.isInstrumentsUpdated && (
                                        <section className='border border-solid border-gray-200 rounded-lg bg-white mt-3'>
                                            <Row>
                                                <Col span={24}>
                                                    <div className='p-3 flex justify-between bg-gray-100 rounded-t-lg'>
                                                        <p className='flex-1 text-sm text-gray-500'>
                                                            {item?.name} Type
                                                        </p>
                                                        <p className='text-sm flex-1 text-right text-gray-500 pr-6'>
                                                            Status
                                                        </p>
                                                    </div>
                                                </Col>
                                                {item.instruments
                                                    ?.filter((item) => item?.isUpdated)
                                                    ?.map((instrument, key) => {
                                                        return (
                                                            <Col
                                                                span={24}
                                                                key={`${instrument.name}-${key}`}
                                                                className='mt-0 pt-0'
                                                            >
                                                                <div className='flex justify-between p-4 border-b border-gray-300'>
                                                                    <span className='text-sm flex-1'>
                                                                        {instrument?.name}
                                                                    </span>
                                                                    <span className='text-sm flex-1 text-right'>
                                                                        {instrument?.isUpdated
                                                                            ? getTag(
                                                                                  instrument?.enabled,
                                                                                  'Activated',
                                                                                  'Disabled',
                                                                              )
                                                                            : ''}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        )
                                                    })}
                                            </Row>
                                        </section>
                                    )}
                                    {item?.isInternationalUpdated &&
                                        PaymentMethods.CREDIT_CARD === item.method &&
                                        item?.instruments?.length > 0 && (
                                            <section className='mt-1 flex gap-2 mt-3 border border-solid border-gray-200 rounded-lg p-2'>
                                                <span className='text-sm'>International -</span>
                                                {item?.isInternationalUpdated
                                                    ? getTag(
                                                          item?.instruments[0]?.data?.international_enabled,
                                                          'Activated',
                                                          'Disabled',
                                                      )
                                                    : ''}
                                            </section>
                                        )}
                                </section>
                            ))}
                        </Row>
                        {!!splitSettlementsInfo?.initialData?.supported &&
                            Object.keys(updatedValues?.splitCollection ?? {}).length > 0 && (
                                <>
                                    <h4 className='text-medium'>Split Collection</h4>
                                    <section className='rounded-2xl bg-gray-100 w-full p-4'>
                                        {updatedValues?.splitCollection?.isEnabledUpdated && (
                                            <div className='flex justify-between'>
                                                <p className='text-sm'>Enabled</p>
                                                <span className='text-sm'>
                                                    {getTag(
                                                        updatedValues?.splitCollection?.enabled,
                                                        'Activated',
                                                        'Disabled',
                                                    )}
                                                </span>
                                            </div>
                                        )}

                                        {updatedValues?.splitCollection?.isAccountsUpdated && (
                                            <section className='border border-solid border-gray-200 rounded-lg bg-white mt-3'>
                                                <Row gutter={[16, 16]} className='bg-gray-100 rounded-t-lg p-3'>
                                                    <Col span={8} className='text-sm text-gray-500'>
                                                        Label
                                                    </Col>
                                                    <Col span={8} className='text-sm text-gray-500 text-center'>
                                                        Account Id
                                                    </Col>
                                                    <Col span={8} className='text-sm text-gray-500 text-center'>
                                                        Status
                                                    </Col>
                                                </Row>
                                                {updatedValues?.splitCollection?.accounts?.map((account) => (
                                                    <Row
                                                        key={account?.pg_account_id}
                                                        gutter={[16, 16]}
                                                        className='p-4 border-b border-gray-300'
                                                    >
                                                        <Col span={8} className='text-sm'>
                                                            {account?.name}
                                                        </Col>
                                                        <Col span={8} className='text-sm text-center'>
                                                            {account?.pg_account_id}
                                                        </Col>
                                                        <Col
                                                            span={8}
                                                            className={`text-sm text-center ${
                                                                account?.active ? 'text-green-500' : 'text-red-500'
                                                            }`}
                                                        >
                                                            {account?.active ? 'True' : 'False'}
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </section>
                                        )}
                                    </section>
                                </>
                            )}
                    </>
                )}
            </Drawer>
            <Modal
                title="Sync Terminal"
                open={showSyncModal}
                onCancel={() => setShowSyncModal(false)}
                footer={null}
                centered
                width="600px"
            >
                {!syncDiffData || Object.keys(syncDiffData).length === 0 ? (
                    <NoChangesToSync />
                ) : !Object.entries(syncDiffData).some(
                    ([_, config]) => 
                        config.instruments_enabled?.length || 
                        config.instruments_disabled?.length || 
                        (config.enablement_status && config.enablement_status.from !== config.enablement_status.to)
                ) ? (
                    <NoChangesToSync />
                ) : (
                    <>
                        <p className="text-gray-600 mb-4">
                            Are you sure you want to sync this terminal? Following changes will be applied:
                        </p>
                        
                        <div className="mt-4">
                            {Object.entries(syncDiffData).map(([method, config]) => {
                                if (config.instruments_enabled?.length || 
                                    config.instruments_disabled?.length || 
                                    (config.enablement_status && config.enablement_status.from !== config.enablement_status.to)
                                ) {
                                    const methodConfig = bluePrint.find(
                                        m => m.method.toLowerCase() === method.toLowerCase()
                                    );
                                    const methodLabel = methodConfig?.name || method.replace(/_/g, ' ');

                                    return (
                                        <div key={method} className="mb-6 last:mb-2">
                                            <div className="flex items-center justify-between mb-2 bg-gray-50 p-3 rounded-t-lg">
                                                <h4 className="text-base font-medium capitalize m-0">
                                                    {methodLabel}
                                                </h4>
                                                {config.enablement_status && config.enablement_status.from !== config.enablement_status.to && (
                                                    <Tag
                                                        className="!px-2 !py-0.5 rounded-full text-xs"
                                                        variant={config.enablement_status.to ? "success" : "error"}
                                                    >
                                                        Will be {config.enablement_status.to ? "Enabled" : "Disabled"}
                                                    </Tag>
                                                )}
                                            </div>
                                            {(config.instruments_enabled?.length > 0 || config.instruments_disabled?.length > 0) && (
                                                <div className="border border-gray-100 rounded-b-lg">
                                                    {renderInstrumentChanges(config, method)}
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                                return null
                            })}
                        </div>
                        {syncMode === 'log' && <LogModeMessage />}
                        <Row className='flex justify-end gap-2 mt-6'>
                            <Col>
                                <Button className='w-40' onClick={() => setShowSyncModal(false)}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col>
                                <Button 
                                    variant='primary' 
                                    className='w-40' 
                                    onClick={resyncTerminal}
                                    disabled={syncMode === 'log' || isSyncing}
                                    loading={isSyncing}
                                >
                                    {isSyncing ? 'Syncing...' : 'Sync Terminal'}
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
            </Modal>
            <Modal
                title={'Deactivate Terminal ?'}
                footer={null}
                onCancel={() => setShowModal(false)}
                open={showModal ? true : false}
                centered
                width={'450px'}
            >
                <p>Are you sure you want to deactivate this terminal?</p>
                <Row className='flex justify-end gap-2 mt-4'>
                    <Col>
                        <Button className='w-40' onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Col>
                    <Col>
                        <Button variant='primary' className='w-40' onClick={deactivateTerminal}>
                            Deactivate Terminal
                        </Button>
                    </Col>
                </Row>
            </Modal>
            <Modal
                title="Restore Terminal"
                open={showRestoreModal}
                onCancel={() => setShowRestoreModal(false)}
                footer={null}
                centered
                width="600px"
            >
                {!syncDiffData || Object.keys(syncDiffData).length === 0 ? (
                    <div className="text-center py-6">
                        <p className="text-gray-600 mb-2">
                            No changes to restore
                        </p>
                        <p className="text-gray-500 text-sm">
                            Your terminal configuration is already in its original state.
                        </p>
                        <Row className='flex justify-center mt-6'>
                            <Col>
                                <Button className='w-40' onClick={() => setShowRestoreModal(false)}>
                                    Close
                                </Button>
                            </Col>
                        </Row>
                    </div>
                ) : !Object.entries(syncDiffData).some(
                    ([_, config]) => 
                        config.instruments_enabled?.length || 
                        config.instruments_disabled?.length || 
                        (config.enablement_status && config.enablement_status.from !== config.enablement_status.to)
                ) ? (
                    <div className="text-center py-6">
                        <p className="text-gray-600 mb-2">
                            No changes to restore
                        </p>
                        <p className="text-gray-500 text-sm">
                            Your terminal configuration is already in its original state.
                        </p>
                        <Row className='flex justify-center mt-6'>
                            <Col>
                                <Button className='w-40' onClick={() => setShowRestoreModal(false)}>
                                    Close
                                </Button>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <>
                        <p className="text-gray-600 mb-4">
                            Are you sure you want to restore this terminal? Following changes will be applied:
                        </p>
                        
                        <div className="mt-4">
                            {Object.entries(syncDiffData).map(([method, config]) => {
                                if (config.instruments_enabled?.length || 
                                    config.instruments_disabled?.length || 
                                    (config.enablement_status && config.enablement_status.from !== config.enablement_status.to)
                                ) {
                                    const methodConfig = bluePrint.find(
                                        m => m.method.toLowerCase() === method.toLowerCase()
                                    );
                                    const methodLabel = methodConfig?.name || method.replace(/_/g, ' ');

                                    return (
                                        <div key={method} className="mb-6 last:mb-2">
                                            <div className="flex items-center justify-between mb-2 bg-gray-50 p-3 rounded-t-lg">
                                                <h4 className="text-base font-medium capitalize m-0">
                                                    {methodLabel}
                                                </h4>
                                                {config.enablement_status && config.enablement_status.from !== config.enablement_status.to && (
                                                    <Tag
                                                        className="!px-2 !py-0.5 rounded-full text-xs"
                                                        variant={config.enablement_status.to ? "success" : "error"}
                                                    >
                                                        Will be {config.enablement_status.to ? "Enabled" : "Disabled"}
                                                    </Tag>
                                                )}
                                            </div>
                                            {(config.instruments_enabled?.length > 0 || config.instruments_disabled?.length > 0) && (
                                                <div className="border border-gray-100 rounded-b-lg">
                                                    {renderInstrumentChanges(config, method)}
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                                return null
                            })}
                        </div>
                        <Row className='flex justify-end gap-2 mt-6'>
                            <Col>
                                <Button className='w-40' onClick={() => setShowRestoreModal(false)}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col>
                                <Button 
                                    variant='primary' 
                                    className='w-40' 
                                    onClick={restoreTerminal}
                                    disabled={isRestoring}
                                    loading={isRestoring}
                                >
                                    {isRestoring ? 'Restoring...' : 'Restore Terminal'}
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
            </Modal>
        </>
    )
}
