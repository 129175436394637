import {
    Table,
    Select,
    Button,
    Row,
    Col,
    Tooltip,
    InfoCircleOutlined,
    PlusCircleOutlined,
    Modal,
    TextArea,
    Card,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { filterDateFormatter, makeAPICall, updateBreadcrumbs, validatePermission } from '@gokwik/utilities'
import { useDispatch, useSelector } from 'react-redux'
import { saveFiltersData } from '@store/filters'
import { previousPaymentLinkFilters } from '@store/filters/selectors'
import RenderSearchFilters from '@library/components/search-filter'
import { getMerchantDetails, isMerchantSelector, getUserDetails} from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { paymentLinkColumns } from './columnsData'
import { handleError } from '@library/utilities/helpers/handleError'
import CreatePaymentLinkDrawer from './createPaymentLinkDrawer'
import { handleCopy } from './link-details/data'
import { PermissionValues } from '@library/utilities/constants/constants'
import { CreatedPaymentLink } from '@library/utilities/interface'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'

const today = dayjs()
const formattedDate = filterDateFormatter(today)
const yesterday = today.subtract(1, 'day')
const { Option } = Select

export default function () {
    const [total, setTotal] = useState(0)
    const [paymentLinks, setPaymentLinks] = useState([])
    const dispatch = useDispatch()
    const prevFilters = useSelector(previousPaymentLinkFilters)
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantData = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const [openCreatePaymentLinkDrawer, setOpenCreatePaymentLinkDrawer] = useState<boolean>(false)
    const [successModal, setSuccessModal] = useState<boolean>(false)
    const [createdPaymentLink, setCreatedPaymentLink] = useState <CreatedPaymentLink>(null)
    const [parameters, setParameters] = useState({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        start_dt: formattedDate,
        end_dt: formattedDate,
        // merchantId: null,
        ...prevFilters,
    })

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'payment-links',
                href: '/payment/payment-links',
                text: 'Payment Links',
            },
        ])
    }, [])

    const reset = (e) => {
        e.preventDefault()
        const newParameters = {
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            start_dt: formattedDate,
            end_dt: formattedDate,
            // merchantId: null,
        }
        setParameters({ ...newParameters })
        makePaymentLinksApiCall(newParameters)
    }

    async function makePaymentLinksApiCall(parameters) {
        // if (!parameters.merchantId && !isMerchantUser) {
        //     message.error('Merchant is required')
        //     return
        // }

        try {
            // let response = dummyTransactions?.data;
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.paymentLinks.get,
                params: { ...parameters, m_id: merchantData?.m_id },
            })

            if (response.data?.data?.payment_links?.length > 0) {
                setPaymentLinks(response.data?.data?.payment_links)
                setTotal(response.data?.data?.total_count)
            } else {
                setPaymentLinks([])
                setTotal(0)
            }
            dispatch<any>(saveFiltersData('payment_links', { ...parameters }))
        } catch (error) {
           handleError(error)
        }
    }

    useEffect(() => {
        // if (parameters.merchantId || isMerchantUser) 
            makePaymentLinksApiCall(parameters)
    }, [])

    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters)) {
            setParameters({ ...prevFilters })
        }
        logEvent('kwik_payment_payment_link_clicked', 'click', 'Kwik Payment payment links', user_details?.email, merchantData?.m_id, merchantData?.short_name, user_details?.name, parameters);
    }, [prevFilters])

    const handleSearchClick = (e) => {
        e?.preventDefault()
        const newParams = { ...parameters, page: 1 }
        setParameters(newParams)
        makePaymentLinksApiCall({ ...newParams })
    }
    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        makePaymentLinksApiCall({ ...parameters, page: current, pageSize })
    }

    return (
        <>
            <CreatePaymentLinkDrawer
                onClose={() => {
                    setOpenCreatePaymentLinkDrawer(false)
                    handleSearchClick(null)
                }}
                openCreatePaymentLinkDrawer={openCreatePaymentLinkDrawer}
                setSuccessModal={setSuccessModal}
                setCreatedPaymentLink={setCreatedPaymentLink}
            />
            <Modal
                title={<p>Your payment link is ready!</p>}
                open={successModal && createdPaymentLink ? true : false}
                onOk={() => {
                    setCreatedPaymentLink(null)
                    setSuccessModal(false)
                }}
                onCancel={() => {
                    setCreatedPaymentLink(null)
                    setSuccessModal(false)
                }}
                centered
                width={'450px'}
                footer={() => (
                    <Row className='flex justify-end gap-2'>
                        <Button
                            onClick={() => handleCopy(createdPaymentLink?.shortUrl)}
                            variant='default'
                            style={{ margin: '0 auto' }}
                        >
                            Copy
                        </Button>
                    </Row>
                )}
            >
                <div className='w-full flex justify-center mt-5 mb-3'>
                    <div className='flex !border !border-solid !border-[#d9d9d9] rounded px-5 py-2'>
                        <p>{createdPaymentLink?.shortUrl}</p>
                    </div>
                </div>
            </Modal>
            <div className='w-full h-full'>
                <div className='overflow-auto'>
                    {
                    validatePermission(PermissionValues.paymentLinks.create) && 
                    (
                        <Row className='p-2 rounded bg-neutrals-15 mb-5' align={'middle'} justify={'space-between'}>
                            <Col sm={12} className='flex items-center gap-x-2'>
                                <Row className='text-black inter m-0 tracking-wide items-center'>
                                    <Tooltip
                                        title={'Create payment links to get paid from your customers immediately.'}
                                    >
                                        <InfoCircleOutlined className='text-blue fs20' />
                                    </Tooltip>

                                    <span className='ml-2'>Create new payment links here</span>
                                </Row>
                            </Col>
                            <Col className='flex justify-end' sm={2}>
                                <Button
                                    className='bg-primary'
                                    onClick={() => setOpenCreatePaymentLinkDrawer(true)}
                                    variant='primary'
                                >
                                    <PlusCircleOutlined />
                                    Create Payment Link
                                </Button>
                            </Col>
                        </Row>
                    )}

                    <div className='bg-white '>
                        <RenderSearchFilters
                            values={parameters}
                            setValues={(data, reset) =>
                                setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))
                            }
                            page='payment_links'
                            onSearch={handleSearchClick}
                            onReset={reset}
                        />
                    </div>
                    <div className='mt-5 bg-white rounded overflow-clip'>
                        <Table
                            columns={paymentLinkColumns}
                            dataSource={paymentLinks || []}
                            style={{
                                width: '100vw',
                            }}
                            pagination={{
                                current: parameters.page, // Current page number
                                pageSize: parameters.pageSize, // Number of items to display per page
                                total: total, // Total number of items in the data array
                                showSizeChanger: false,
                                onChange: handlePaginationChange,
                                position: ['topLeft', 'bottomLeft'],
                                showTotal: (total, range) => (
                                    <p className='ml-4'>{`Showing ${range[0]}-${range[1]} of ${total} Records`}</p>
                                ),
                            }}
                            scroll={{ x: 'max-content' }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
