import {
    Table,
    Select,
    Input,
    Button,
    Select as AntdSelect,
    Col,
    Row,
    Space,
    DatePicker,
    Tooltip,
    message,
    Modal,
    ColumnProps,
} from 'gokwik-ui-kit'
import { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { EditOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import {
    convertIsoToLocaleString,
    dashboardStore,
    filterDateFormatter,
    makeAPICall,
    updateBreadcrumbs,
    validatePermission
} from '@gokwik/utilities'
import { FilterCreatorModel, SearchFilters } from '@library/utilities/interface'
import { useDispatch, useSelector } from 'react-redux'
import { previousDangledTransactionsFilters } from '@store/filters/selectors'
import RenderSearchFilters from '@library/components/search-filter'
import { saveFiltersData } from '@store/filters'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { isMerchantSelector } from '@store/user/selectors'
import { handleError } from '@library/utilities/helpers/handleError'
import { formatAmount } from '@library/utilities/helpers/helper'

const today = dayjs()
const formattedDate = filterDateFormatter(today)

export default function () {
    const [total, setTotal] = useState(0)
    const [modalOpen, setModalOpen] = useState<any>()
    const [transactions, setTransactions] = useState([])
    const dispatch = useDispatch()
    const prevFilters = useSelector(previousDangledTransactionsFilters)
    const [parameters, setParameters] = useState({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        start_dt: formattedDate,
        end_dt: formattedDate,
        // merchantId: null,
        ...prevFilters,
    })
    const isMerchantUser = useSelector(isMerchantSelector)

    const columns: ColumnProps<any>[] = [
        {
            title: <span>Payment ID</span>,
            dataIndex: 'payment_id',
            render: (text: string) => {
                return <p>{text.toUpperCase()}</p>
            },
            width: 220,
            fixed: 'left',
        },
        {
            title: <span>Actions</span>,
            dataIndex: 'id',
            fixed: 'left',
            render: (id, record) => {
                return (
                    <Row className='gap-2'>
                       { validatePermission('refund_dangled_transactions') &&
                        record.payment_provider !== 'setu' &&
                        record.bank_status === 'payment_successful' &&
                        record.remark !== 'Twice merchant callback for same transaction.' && (
                        <Tooltip title='Update Transaction'>
                            <EditOutlined
                                onClick={() => {
                                    setModalOpen(record)
                                }}
                                style={{ color: 'red' }}
                                className='ml-4 cursor-pointer'
                            />
                        </Tooltip>
                        )
                        }
                    </Row>
                )
                
            },
        },
        {
            title: <span>Customer Ref ID</span>,
            dataIndex: 'cust_ref_id',
        },
        {
            title: <span>Phone</span>,
            dataIndex: 'phone',
        },
        {
            title: <span>Status</span>,
            dataIndex: 'bank_status',
        },
        {
            title: <span>Merchant Name</span>,
            dataIndex: 'Merchant.business_name',
        },
        {
            title: <span>Payment Mode</span>,
            dataIndex: 'payment_method',
        },
        {
            title: <span>Payment Provider</span>,
            dataIndex: 'payment_provider',
        },
        {
            title: <span>Amount</span>,
            dataIndex: 'amount',
            render: (e) => <p>{formatAmount(Number(e),2)}</p>,
        },
        {
            title: <span>Is Deleted</span>,
            dataIndex: 'is_deleted',
        },
        {
            title: <span>Created At</span>,
            dataIndex: 'created_at',
            render: (e) => convertIsoToLocaleString(e),
        },
        {
            title: <span>Updated At</span>,
            dataIndex: 'updated_at',
            render: (e) => convertIsoToLocaleString(e),
        },
        {
            title: <span>NPC ID</span>,
            dataIndex: 'npc_id',
        },
        {
            title: <span>Remarks</span>,
            dataIndex: 'remark',
        },
    ]

    const reset = (e) => {
        e.preventDefault()
        setParameters({
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            start_dt: formattedDate,
            end_dt: formattedDate,
        })
        dispatch<any>(saveFiltersData('dangled_transactions', {}))
        setTotal(0)
    }

    async function makeTransactionsApiCall(reset, merchantId = null) {
        try {
            // let response = dummyTransactions?.data;
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getDangledTransactions,
                params: parameters,
            })

            if (response.data?.data?.transactions?.length > 0) {
                setTransactions(response.data?.data?.transactions)
                setTotal(response.data?.data?.totalCount)
            } else {
                setTransactions([])
                setTotal(0)
            }
            dispatch<any>(saveFiltersData('dangled_transactions', { ...parameters }))
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
            makeTransactionsApiCall(null, null)
    }, [parameters.page])

    const handleSearchClick = (e) => {
        e?.preventDefault()
        makeTransactionsApiCall(null, null)
    }
    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
    }

    const onUpdateStatus = async (data) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.refundDangledTransaction,
            payload: {
                paymentId: data.payment_id,
                ...(data.npc_id && { npcId: data.npc_id }),
            },
        })
        if (response?.data?.data?.statusCode === 200) {
            message.success(response?.data?.data?.message)
        } else {
            message.error(response?.response?.data?.message)
        }
    }

    return (
        <>
            <div className='w-full  h-full'>
                <div style={{ margin: 0, marginTop: 0 }}>
                    <div className='overflow-auto'>
                        <div className='bg-white'>
                            <RenderSearchFilters
                                values={parameters}
                                setValues={(data, reset) =>
                                    setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))
                                }
                                page='dangled_transactions'
                                onSearch={handleSearchClick}
                                onReset={reset}
                            />
                        </div>

                        <div className={'mt-5 bg-white rounded overflow-clip'}>
                            <Table
                                columns={columns}
                                dataSource={transactions || []}
                                style={{
                                    width: '100vw',
                                }}
                                pagination={{
                                    current: parameters.page, // Current page number
                                    pageSize: parameters.pageSize, // Number of items to display per page
                                    total: total, // Total number of items in the data array
                                    showSizeChanger: false,
                                    onChange: handlePaginationChange,
                                    position: ['topLeft', 'bottomLeft'],
                                    showTotal: (total, range) => (
                                        <p className='ml-4'>{`Showing ${range[0]}-${range[1]} of ${total} Records`}</p>
                                    ),
                                }}
                                scroll={{ x: 'max-content' }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                title={<p className='fs14'>Are your sure, you want to update the status ?</p>}
                open={modalOpen ? true : false}
                onOk={() => {
                    onUpdateStatus(modalOpen)
                    setModalOpen(null)
                }}
                onCancel={() => {
                    setModalOpen(null)
                }}
                footer={() => (
                    <Row className='flex justify-end gap-2'>
                        <Button onClick={() => setModalOpen(null)} variant='default'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                onUpdateStatus(modalOpen)
                                setModalOpen(null)
                            }}
                            variant='primary'
                        >
                            Confirm
                        </Button>
                    </Row>
                )}
                centered
                width={'400px'}
            >
                <h3 className='mt-1'>
                    Order number # <span className='text-blue'>{modalOpen?.payment_id}</span>{' '}
                </h3>
            </Modal>
        </>
    )
}
