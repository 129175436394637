import { useEffect, useState, useMemo } from 'react'
import dayjs from 'dayjs'
import { filterDateFormatter, makeAPICall, updateBreadcrumbs, validatePermission } from '@gokwik/utilities'
import { Table, message, SummaryBlocks, Tooltip, Row, Modal, Button, EditOutlined, FieldTimeOutlined} from 'gokwik-ui-kit'
import { saveFiltersData } from '@store/filters'
import { useDispatch, useSelector } from 'react-redux'
import { previousRefundsFilters } from '@store/filters/selectors'
import RenderSearchFilters from '@library/components/search-filter'
import { getMerchantPlatform, isMerchantSelector, getMerchantDetails, getUserDetails } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { internalUserColumns, merchantUserColumns } from './refundColumns'
import { handleError } from '@library/utilities/helpers/handleError'
import EditStatusDrawer from '@library/components/editStatusDrawer'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
const today = dayjs()
const formattedDate = filterDateFormatter(today)

export default function () {
    const prevFilters = useSelector(previousRefundsFilters)
    const [total, setTotal] = useState(0)
    const [summaryData, setSummaryData] = useState({
        refunded_gmv: 0,
        average_tat: 0,
        completion_percentage: 0,
    })
    const [refund, setRefund] = useState([])
    const dispatch = useDispatch()
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantPlatform = useSelector(getMerchantPlatform)
    const merchantData = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const [editOpen, setEditOpen] = useState<any>()
    const [modalOpen, setModalOpen] = useState<any>()
    const [parameters, setParameters] = useState({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        st_date: formattedDate,
        end_date: formattedDate,
        ...prevFilters,
    })

    const merchantColumns = useMemo(() => {
        const temp = [...merchantUserColumns]
        temp.splice(2, 0, {
            title: <span>{merchantPlatform === 'shopify' ? 'Shopify Order ID' : 'Platform Order ID'}</span>,
            dataIndex: merchantPlatform === 'shopify' ? 'order_name' : 'merchant_order_id',
        })
        return temp
    }, [merchantPlatform])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'refunds',
                href: '/payment/refunds',
                text: 'Refunds',
            },
        ])
    }, [])

    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters)) {
            setParameters({ ...prevFilters })
        }
        logEvent('kwik_payment_refund_clicked', 'click', 'Kwik Payment refund', user_details?.email, merchantData?.m_id, merchantData?.short_name, user_details?.name, parameters);
    }, [prevFilters])

    const reset = (e) => {
        e.preventDefault()
        const newParams = {
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            st_date: formattedDate,
            end_date: formattedDate,
        }
        setParameters({ ...newParams })
        makeRefundsApiCall(newParams)
    }

    async function makeRefundsApiCall(parameters, merchantId = null) {

        try {
            // let response = dummyOrders?.data;
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getRefunds,
                params: parameters,
            })
            setSummaryData((prev) => ({
                ...prev,
                refunded_gmv: response.data?.data?.refundStats?.refunded_gmv || 0,
                average_tat: response.data?.data?.refundStats?.average_tat || 0,
                completion_percentage: response.data?.data?.refundStats?.completion_percentage || 0,
            }))
            if (response.data?.data?.refund?.length > 0) {
                setRefund(response.data?.data?.refund)
                setTotal(response.data?.data?.totalCount)
            } else {
                setRefund([])
                setTotal(0)
            }
            dispatch<any>(saveFiltersData('refunds', { ...parameters }))
        } catch (error) {
            handleError(error)
        
        }
    }

    useEffect(() => {
        makeRefundsApiCall(parameters)
    }, [])

    const handleSearchClick = (e) => {
        e?.preventDefault()
        //Email validation
        if (parameters?.['email']) {
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

            if (!parameters?.['email'].match(emailPattern)) {
                message.error('Invalid email format.')

                return
            }
        }

        //Phone number validation
        if (parameters?.['phone'] && parameters?.['phone']?.toString()?.length < 10) {
            message.error('Phone number should be 10-digits.')
            return
        }
        const newParams = { ...parameters, page: 1 }
        setParameters(newParams)
        makeRefundsApiCall({ ...newParams })
    }

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({
            ...prev,
            page: current,
            pageSize,
        }))
        makeRefundsApiCall({ ...parameters, page: current, pageSize })
    }

    const onUpdateRefundStatus = async (data) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.refundStatus,
            payload: {
                    paymentId: data.payment_id,
                    ...(data.npc_id && { npcId: data.npc_id }),
                    ...(data.gokwik_rid && { gokwikRid: data.gokwik_rid }),
                    },
        })
        if (response?.data?.status_code === 200) {
            message.success(response.data.message)
        } else {
            message.error(response?.response?.data.message)
        }
    }
    const earlyRefund = async (data) => {

        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.processRefund,
            payload: {
                  refundId: data.id,
                },
        })
        if (response?.data?.status_code === 200) {
            message.success(response.data.message)
        } else {
            message.error(response?.response?.data.message)
        }
      };
    const openEdit = (id, data) => {
        setEditOpen(data)
    }

    return (
        <div className='w-full h-full overflow-auto'>
            {isMerchantUser && (
            <div className='pt-5 bg-white'>
                <SummaryBlocks
                    data={[
                        {
                            title: 'Refunded GMV',
                            heroValue: summaryData.refunded_gmv,
                            heroValueTransform: 'amount',
                        },
                        {
                            title: 'Average TAT',
                            heroValue: (
                                <Tooltip title={(+summaryData.average_tat / 3600).toFixed(1)}>
                                    <p className='flex justify-center items-center'>
                                        <span className='summary-value'>
                                            {(+summaryData.average_tat / 3600).toFixed(1)} hours
                                        </span>
                                    </p>
                                </Tooltip>
                            ),
                        },
                        {
                            title: 'Completion Percentage',
                            heroValue: +(+summaryData.completion_percentage.toFixed(2)),
                            heroValueTransform: 'percentage',
                        },
                    ]}
                />
            </div>
            )}
            <div className='bg-white rounded'>
                <RenderSearchFilters
                    values={parameters}
                    setValues={(data, reset) => setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))}
                    page='refunds'
                    onSearch={handleSearchClick}
                    onReset={reset}
                />
            </div>

            <div className={'bg-white rounded overflow-clip'}>
                <Table
                    columns={isMerchantUser ? merchantColumns : [...internalUserColumns,
                        {
                            title: <span>Actions</span>,
                            dataIndex: 'id',
                            fixed: 'right',
                            render: (id, record) => {
                                return (
                                    <Row className={`m-2`}>
                                        { 
                                        
                                        ['pending', 'timeout', 'payment_initiated'].includes(
                                            record?.bank_status?.toLowerCase(),
                                        ) && (
                                            <>
                                                {validatePermission('update_status_refunds') && (
                                                <Tooltip title='Update Refunds'>
                                                    <FieldTimeOutlined
                                                        onClick={() => {
                                                            setModalOpen(record)
                                                        }}
                                                        style={{ color: 'green' }}
                                                    />
                                                </Tooltip>
                                                )}
                                                {validatePermission('edit_refunds') && (
                                                <Tooltip title='Edit Refunds'>
                                                    <EditOutlined
                                                        onClick={() => openEdit(id, record)}
                                                        style={{ color: 'red' }}
                                                        className='ml-4'
                                                    />
                                                </Tooltip> )}
                                            </>
                                        )}
                                        {
                                            validatePermission('early_refund') && 'payment_initiated' === record?.bank_status?.toLowerCase() && 
                                            (
                                                <Tooltip title='Prioritize Refunds'>
                                                    <EditOutlined
                                                        onClick = {() => earlyRefund(record)}
                                                        style={{ color: 'blue' }}
                                                        className='ml-4'
                                                    />
                                                </Tooltip> 
                                            )
                                        }
                                    </Row>
                                )
                            },
                        },

                    ]}
                    dataSource={refund || []}
                    style={{
                        width: '100vw',
                    }}
                    pagination={{
                        current: parameters.page, // Current page number
                        pageSize: parameters.pageSize, // Number of items to display per page
                        total: total, // Total number of items in the data array
                        showSizeChanger: false,
                        onChange: handlePaginationChange,
                        position: ['topLeft', 'bottomLeft'],
                        showTotal: (total, range) => (
                            <p className='ml-4'>{`Showing ${range[0]}-${range[1]} of ${total} Records`}</p>
                        ),
                    }}
                    scroll={{ x: 'max-content' }}
                />
            </div>
            {editOpen && (
                <EditStatusDrawer
                    title='Update Refund'
                    heading={
                        <>
                            <h4 className='fs16 mt-0'>
                                Order #:{' '}
                                <Tooltip title={editOpen?.order_number} className='text-blue'>
                                    {editOpen?.order_number}
                                </Tooltip>
                            </h4>
                            <h5 className='fs14'>
                                Refund Payment ID:{' '}
                                <span className='text-blue'>
                                    {' '}
                                    <Tooltip>{editOpen?.payment_id} </Tooltip>
                                </span>
                            </h5>
                        </>
                    }
                    open={!!editOpen}
                    onClose={(refetch: boolean) => {
                        refetch && makeRefundsApiCall(parameters)
                        setEditOpen(false)
                    }}
                    recordData={editOpen}
                    type='refund'
                />
            )}
            <Modal
                title={<p className='fs14'>Are your sure, you want to update the status ?</p>}
                open={modalOpen ? true : false}
                onOk={() => {
                    onUpdateRefundStatus(modalOpen)
                    setModalOpen(null)
                }}
                onCancel={() => {
                    setModalOpen(null)
                }}
                footer={() => (
                    <Row className='flex justify-end gap-2'>
                        <Button onClick={() => setModalOpen(null)} variant='default'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                onUpdateRefundStatus(modalOpen)
                                setModalOpen(null)
                            }}
                            variant='primary'
                        >
                            Confirm
                        </Button>
                    </Row>
                )}
                centered
                width={'400px'}
            >
                <h3 className='mt-1'>
                    Order number # <span className='text-blue'>{modalOpen?.order_number}</span>{' '}
                </h3>
                <h3 className='mt-1'>
                    Payment ID <span className='text-blue'>{modalOpen?.payment_id}</span>{' '}
                </h3>
            </Modal>
        </div>
    )
}
