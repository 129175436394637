import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { numberFormatter } from '@library/utilities/helpers/numberFormatter'
import { ColumnProps, Table } from 'gokwik-ui-kit'
dayjs.extend(utc)

const paymentMethods = {
    upi: 'UPI',
    cod: 'COD',
    bnpl: 'BNPL',
    card: 'Card',
    netbanking: 'Netbanking',
    wallet: 'Wallet',
    snapmint: 'Snapmint',
    credpay: 'Credpay',
    ppcod: 'PPCOD',
    bharatxpay: 'Bharatxpay',
}

const tableColumns: ColumnProps<any>[] = [
    {
        title: 'Method',
        dataIndex: 'method',
        key: 'payment_method',
        render: (val) => paymentMethods[val],
        width: 100,
    },
    {
        title: 'Total Attempts',
        dataIndex: 'payment_attempted',
        key: 'payment_attempted',
        render: (val) => numberFormatter(val),
        align: 'right',
        width: 120,
    },
    {
        title: 'Success + Failed',
        dataIndex: 'success_and_failed',
        key: 'success_and_failed',
        render: (val) => numberFormatter(val),
        align: 'right',
        width: 150,
    },
    {
        title: 'Success',
        dataIndex: 'orders',
        key: 'orders',
        render: (val) => numberFormatter(val),
        align: 'right',
        width: 100,
    },
    {
        title: 'Success %',
        dataIndex: 'success_rate',
        key: 'success_rate',
        render: (val) => `${val}%`,
        align: 'right',
        width: 100,
    },
    {
        title: 'GMV',
        dataIndex: 'gmv',
        key: 'gmv',
        render: (val) => numberFormatter(val),
        align: 'right',
        width: 80,
    },
]

const DistributionTable = ({ data }) => {
    const filteredTableData = data?.table_data.filter((methodData) => methodData.payment_attempted !== 0)

    if (filteredTableData) {
        filteredTableData.forEach((methodData) => {
            if (!paymentMethods[methodData.method]) {
                paymentMethods[methodData.method] = methodData.method;
            }
        });
    }
    return (
        <Table
            scroll={{
                x: 'min-content',
                y: 250,
            }}
            columns={tableColumns}
            dataSource={filteredTableData}
            pagination={false}
        />
    )
}

export default DistributionTable
