import { ColumnsType, Popover, Tooltip, message, CopyOutlined } from 'gokwik-ui-kit'
import { PaymentLinkDetails, Refund, Transaction } from '@library/utilities/interface'
import { convertIsoToLocaleString, titleCase } from '@gokwik/utilities'
import dayjs from 'dayjs'
import { formatAmount } from '@library/utilities/helpers/helper'

const statusMappingForColors = {
    confirmed: 1,
    success: 1,
    completed: 1,
    pending: 2,
    true: 1,
    false: 3,
    cancelled: 3,
    initiated: 2,
    payment_initiated: 2,
    payment_failed: 3,
    failed: 3,
}

const renderAmount = (val) => <span className='font-medium'>{formatAmount(val)}</span>
const renderStatus = (itm) => (
    <span
        className={
            statusMappingForColors[itm] === 1
                ? 'text-success-600'
                : statusMappingForColors[itm] === 2
                ? 'text-warning-500'
                : statusMappingForColors[itm] === 3
                ? 'text-error-700'
                : 'text-black'
        }
    >
        {titleCase(itm)}
    </span>
)

export const handleCopy = (textToCopy) => {
    try {
        navigator.clipboard.writeText(textToCopy)
        message.success('URL Copied')
    } catch (err) {
        message.error('Failed to copy to clipboard')
    }
}

export const paymentColumns: ColumnsType<any> = [
    {
        title: 'Payment ID',
        dataIndex: 'payment_id',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        width: 100,
        align: 'right',
        render: renderAmount,
    },
    {
        title: 'Payment Method',
        dataIndex: 'payment_method',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Provider',
        dataIndex: 'payment_provider',
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: renderStatus,
    },
    {
        title: 'Auto Refund',
        dataIndex: 'is_auto_refund',
        render: (val) => `${val}`,
    },
    {
        title: 'Is Deleted',
        dataIndex: 'is_deleted',
        render: (val) => `${val}`,
    },
    {
        title: 'Link',
        dataIndex: 'upi_link',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                <p className='w-56 truncate'>
                    <span>{name}</span>
                </p>
            </Tooltip>
        ),
    },
    {
        title: 'Created Date',
        dataIndex: 'created_at',
        defaultSortOrder: 'descend',
        sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
        render: (val) => convertIsoToLocaleString(val),
    },
]

export const refundColumns: ColumnsType<any> = [
    {
        title: 'Payment ID',
        dataIndex: 'payment_id',
        width: 250,
        ellipsis: {
            showTitle: false,
        },
        fixed: 'left',
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Reference ID',
        dataIndex: 'cust_ref_id',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Provider',
        dataIndex: 'payment_provider',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        width: 90,
        align: 'right',
        render: renderAmount,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        width: 160,
        render: (val: string) => renderStatus(val.toLowerCase()),
    },
    {
        title: 'Auto Refund',
        dataIndex: 'is_auto_refund',
        width: 150,
        render: (val) => `${val}`,
    },
    {
        title: 'Created At',
        dataIndex: 'created_at',
        width: 200,
        defaultSortOrder: 'descend',
        sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
        render: (val) => convertIsoToLocaleString(val),
    },
    {
        title: 'Refunded At',
        dataIndex: 'refunded_at',
        width: 200,
        render: (val) => (val ? convertIsoToLocaleString(val) : '-'),
    },
    {
        title: 'Initiated By',
        dataIndex: 'initiator',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Attempts',
        dataIndex: 'attempts',
        width: 90,
        ellipsis: {
            showTitle: false,
        },
        align: 'right',
        fixed: 'right',
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
]
