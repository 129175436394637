import { addBreadcrumb, makeAPICall, updateBreadcrumbs, validatePermission } from '@gokwik/utilities'
import {
    Button,
    Col,
    Row,
    Tag,
    Tooltip,
    LinkOutlined,
    PhoneOutlined,
    MailOutlined,
    DollarOutlined,
    UserOutlined,
    UnorderedListOutlined,
    Table,
    ColumnProps,
    NumberOutlined,
    HistoryOutlined,
} from 'gokwik-ui-kit'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { PaymentLink, Refund, Transaction } from '@library/utilities/interface'
import { paymentColumns, refundColumns } from './data'
import RefundDrawer from './refundDrawer'
import { getMerchantDetails, getMerchantPlatform, isMerchantSelector } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { PermissionValues } from '@library/utilities/constants/constants'
import { formatAmount } from '@library/utilities/helpers/helper'
const statusMappingForColors = {
    confirmed: 1,
    pending: 2,
    true: 1,
    false: 3,
    cancelled: 3,
    failed: 3,
}

const statusMappingForTag = {
    paid: 'success',
    created: 'default',
    cancelled: 'error',
}

interface MergedRepeatedRefund extends Refund {
    attempts: number
}

const mergeRepeatedRefunds = (refundsData: Refund[]): MergedRepeatedRefund[] => {
    const sortByRids = refundsData.reduce(
        (acc, refund) =>
            (acc = {
                ...acc,
                [refund.gokwik_rid]: acc[refund.gokwik_rid] ? [...acc[refund.gokwik_rid], refund] : [refund],
            }),
        {},
    )
    const mergedRefunds = Object.values(sortByRids).map((val: Refund[]) => {
        const primaryRefund = val.find((refund) => refund.bank_status !== 'payment_failed') || val[val.length - 1]
        if (primaryRefund.bank_status === 'payment_successful') {
            primaryRefund.status = 'Completed'
        } else if (primaryRefund.bank_status === 'payment_failed' || primaryRefund.bank_status === 'failed') {
            primaryRefund.status = 'Failed'
        } else if (primaryRefund.bank_status === 'pending' || primaryRefund.bank_status === 'timeout') {
            primaryRefund.status = 'Initiated'
        }
        return {
            ...primaryRefund,
            attempts: val.length,
        }
    })
    return mergedRefunds
}

const PaymentLinkDetails = () => {
    const { id } = useParams()
    const [paymentLinkData, setPaymentLinkData] = useState<PaymentLink>(null)
    const [openRefundDrawer, setOpenRefundDrawer] = useState(false)
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantPlatform = useSelector(getMerchantPlatform)
    const merchantDetails = useSelector(getMerchantDetails)
    const [refundBtnState, setRefundBtnState] = useState<{
        disabled: boolean
        refundStatus: string
        refundableAmount: number
    }>({
        disabled: false,
        refundStatus: '',
        refundableAmount: 0,
    })

    const fetchPaymentLinkDetails = useCallback(async () => {
        const response = await makeAPICall({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.paymentLinks.detailsById}/${id}`,
            params: { m_id: merchantDetails?.m_id, sortKey: 'created_at', sortOrder: -1 },
        })
        if (response.success) {
            const conditionedRefunds = mergeRepeatedRefunds(response.data.data.refunds)
            let totalRefundAbleAmount: number = 0
            const refundBtn = { ...refundBtnState }
            response.data.data?.transactions?.map((each: Transaction) => {
                if (
                    (each.bank_status === 'payment_successful' ||
                        (each.bank_status === 'timeout' && each.payment_provider === 'ybl')) &&
                    !each.is_deleted &&
                    !each.is_auto_refund
                ) {
                    if (each.bank_status === 'timeout' && each.payment_provider === 'ybl') {
                        each.status = 'Timeout'
                        //   setIsTransactionTimeout(true)
                    }
                    totalRefundAbleAmount += parseFloat('' + each.amount || '0.0')
                } else if (!each.is_deleted && !each.is_auto_refund) {
                    refundBtn.refundStatus = 'not-applicable'
                }
            })

            const triggeredRefund = +conditionedRefunds
                .reduce((acc, currentRefund) => {
                    if (
                        ['payment_successful', 'pending', 'timeout', 'payment_initiated'].includes(
                            currentRefund.bank_status,
                        ) &&
                        !currentRefund.is_auto_refund
                    ) {
                        acc = acc + +currentRefund.amount
                        if (['pending', 'timeout', 'payment_initiated'].includes(currentRefund.bank_status))
                            refundBtn.refundStatus = currentRefund.status
                    }
                    return acc
                }, 0)
                .toFixed(2)

            refundBtn.disabled = +totalRefundAbleAmount <= triggeredRefund
            setRefundBtnState({
                ...refundBtn,
                refundableAmount: (+totalRefundAbleAmount || 0) - (triggeredRefund || 0),
            })

            setPaymentLinkData({ ...response.data.data, refunds: conditionedRefunds })
        }
    }, [id])

    const customerInformation = useMemo(
        () => [
            {
                icon: UserOutlined,
                value: (JSON.parse(paymentLinkData?.paymentLinkDetails?.customer || '{}') || {})?.name,
                label: 'Customer Name',
            },
            {
                icon: PhoneOutlined,
                value: (JSON.parse(paymentLinkData?.paymentLinkDetails?.customer || '{}') || {}).phone,
                label: 'Phone',
            },
            {
                icon: MailOutlined,
                value: (JSON.parse(paymentLinkData?.paymentLinkDetails?.customer || '{}') || {}).email,
                label: 'Email Address',
            },
        ],
        [paymentLinkData, isMerchantUser],
    )

    const paymentLinkDetails = useMemo(
        () => [
            { icon: LinkOutlined, value: paymentLinkData?.paymentLinkDetails?.url, label: 'Payment Link' },
            {
                icon: DollarOutlined,
                value: paymentLinkData?.paymentLinkDetails?.amount,
                label: 'Amount',
                suffix: '₹ ',
            },
            {
                icon: UnorderedListOutlined,
                value: paymentLinkData?.paymentLinkDetails?.description,
                label: 'Description',
            },
            {
                icon: NumberOutlined,
                value: paymentLinkData?.paymentLinkDetails?.merchant_reference_id,
                label: 'Merchant Reference ID',
            },
            {
                icon: HistoryOutlined,
                value: paymentLinkData?.paymentLinkDetails?.status?.toUpperCase(),
                label: 'Status',
            },
        ],
        [paymentLinkData],
    )

    useEffect(() => {
        fetchPaymentLinkDetails()
    }, [id])

    useEffect(() => {
        updateBreadcrumbs((prev) =>
            prev[prev?.length - 1]?.text === String(id)
                ? prev
                : [
                      prev[0],

                      {
                          key: 'payment-links',
                          href: '/payment/payment-links',
                          text: 'Payment Links',
                      },
                      {
                          key: 'payment-link-details',
                          href: `/payment/payment-links/${id}`,
                          text: String(id),
                      },
                  ],
        )
    }, [])

    return (
        <>
            <RefundDrawer
                onClose={(refetch: boolean) => {
                    setOpenRefundDrawer(false)
                    refetch && fetchPaymentLinkDetails()
                }}
                id={id}
                openRefundDrawer={openRefundDrawer}
                paymentLinkId={paymentLinkData?.paymentLinkDetails?.id}
                merchant_id={paymentLinkData?.paymentLinkDetails?.merchant_id}
                fullRefundAmount={refundBtnState.refundableAmount}
            />
            <div className='order-details-page w-full  flex flex-col gap-y-4 inter'>
                <Row align={'middle'} justify={'space-between'} gutter={[0, 12]}>
                    <Col className='p-2 rounded bg-neutrals-15' sm={24}>
                        <Row align={'middle'} justify={'space-between'}>
                            <Col sm={12} className='flex items-center gap-x-2'>
                                <span className='text-black text-base inter font-semibold m-0 tracking-wide'>
                                    Payment Link #{id}
                                </span>
                                <Tag
                                    variant={statusMappingForTag[paymentLinkData?.paymentLinkDetails?.status]}
                                    className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                                >
                                    {paymentLinkData?.paymentLinkDetails?.status}
                                </Tag>
                            </Col>
                            {validatePermission(PermissionValues.refunds.initiateRefund) && (
                                <Col className='flex justify-end' sm={2}>
                                    <Tooltip
                                        title={
                                            refundBtnState.disabled &&
                                            (refundBtnState.refundStatus = 'not-applicable'
                                                ? 'No refundable transaction for this order.'
                                                : `New refund can not be triggered as refundable transaction's refund${
                                                      paymentLinkData?.refunds?.length > 1 ? 's are' : 'is'
                                                  } ${
                                                      refundBtnState.refundStatus === 'Initiated'
                                                          ? 'pending'
                                                          : 'successful'
                                                  }.`)
                                        }
                                    >
                                        <Button
                                            disabled={refundBtnState.disabled}
                                            variant='primary'
                                            onClick={() => setOpenRefundDrawer(true)}
                                        >
                                            Initiate Refund
                                        </Button>
                                    </Tooltip>
                                </Col>
                            )}
                        </Row>
                    </Col>

                    <Col className='flex flex-col gap-y-4 ' sm={24}>
                        <Row
                            className='p-2 rounded bg-neutrals-15'
                            gutter={[0, 12]}
                            align={'middle'}
                            justify={'space-between'}
                        >
                            <Col sm={24}>
                                <Row className='rounded w-full bg-neutrals-15 p-2' justify={'space-between'}>
                                    <Col span={10}>
                                        <Row gutter={[0, 12]}>
                                            <Col span={24}>
                                                <span className='text-black text-base inter font-semibold m-0'>
                                                    Basic Details
                                                </span>
                                            </Col>

                                            <Col className='flex flex-col gap-y-2' span={12}>
                                                {customerInformation.slice(0, 6).map((itm) => (
                                                    <div
                                                        className='flex items-center gap-2 w-full  text-sm inter font-normal'
                                                        key={itm.label}
                                                    >
                                                        <div className='text-gray-600 nowrap w-max flex'>
                                                            <itm.icon className='mr-2' width={20} height={20} />
                                                            <span className='nowrap no-wrap'>{itm.label}: </span>
                                                        </div>
                                                        <span className='text-black font-medium truncate flex-grow'>
                                                            <Tooltip title={itm.value}>{itm.value || ''}</Tooltip>
                                                        </span>{' '}
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={14}>
                                        <Row gutter={[0, 12]}>
                                            <Col span={24}>
                                                <span className='text-black text-base inter font-semibold m-0'>
                                                    Payment Link Details
                                                </span>
                                            </Col>
                                            <Col className='flex flex-col gap-y-2' span={12}>
                                                {paymentLinkDetails.slice(0, 3).map((itm) => (
                                                    <div
                                                        className='flex items-center w-full gap-2 text-sm inter font-normal'
                                                        key={itm.label}
                                                    >
                                                        <div className='text-gray-600'>
                                                            {/* <itm.icon className='mr-2' width={20} height={20} /> */}
                                                            <span>{itm.label}: </span>
                                                        </div>
                                                        <span className='text-black font-medium max-w-[50%] truncate'>
                                                        {itm.label === "Amount" ? formatAmount(Number(itm.value),2) : (itm.value ? `${itm.suffix || ''}${itm.value}` : '-')}
                                                        </span>
                                                    </div>
                                                ))}
                                            </Col>
                                            <Col className='flex flex-col gap-y-2' span={12}>
                                                {paymentLinkDetails.slice(3).map((itm) => (
                                                    <div
                                                        className='flex items-center w-full gap-2 text-sm inter font-normal'
                                                        key={itm.label}
                                                    >
                                                        <div className='text-gray-600'>
                                                            {/* <itm.icon className='mr-2' width={20} height={20} /> */}
                                                            <span>{itm.label}: </span>
                                                        </div>
                                                        <span className='text-black font-medium max-w-[50%] truncate'>
                                                            
                                                            { itm.value ? `${itm.suffix || ''}${itm.value}` : '-'}
                                                        </span>{' '}
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={24}>
                        <Row className='p-2 rounded bg-neutrals-15' gutter={[0, 12]} align={'middle'}>
                            <Col sm={24}>
                                <span className='text-black text-base inter font-semibold m-0'>Payments</span>
                            </Col>
                            <Col sm={24}>
                                <Table
                                    columns={
                                        isMerchantUser
                                            ? paymentColumns.filter(
                                                  (col: ColumnProps<any>) =>
                                                    // @ts-ignore
                                                      !['upi_link', 'is_deleted'].includes(col.dataIndex),
                                              )
                                            : paymentColumns
                                    }
                                    dataSource={paymentLinkData?.transactions || []}
                                    style={{
                                        width: '100vw',
                                    }}
                                    pagination={false}
                                    scroll={{ x: 'max-content' }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={24}>
                        <Row className='p-2 rounded bg-neutrals-15' gutter={[0, 12]} align={'middle'}>
                            <Col sm={24}>
                                <span className='text-black text-base inter font-semibold m-0'>Refunds</span>
                            </Col>
                            <Col sm={24}>
                                <Table
                                    columns={refundColumns}
                                    dataSource={paymentLinkData?.refunds || []}
                                    style={{
                                        width: '100vw',
                                    }}
                                    pagination={false}
                                    scroll={{ x: 'max-content' }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default PaymentLinkDetails
