import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import styles from './styles.module.css'
import { InfoCircleOutlined, Tooltip } from 'gokwik-ui-kit'
import utc from 'dayjs/plugin/utc'
import { capitalizeFirstLetter } from '@library/utilities/helpers/helper'
dayjs.extend(utc)

const categoryTooltips = {
    user: `Failures originating from the customer's side are classified under this bucket. E.g. cancellations, timeouts, incorrect OTP/PIN, insufficient funds etc.`,
    bank: 'Failures originating from banks denying transactions on the grounds of fraud, technical issues, downtimes etc.',
    others: 'Failures either due to business configurations, Gokwik or other PAs as well as unidentified failures are classified in this bucket.',
}

const subCategoryTooltips = {
    incorrect_details_entered: `Customer has entered incorrect payment details`,
    stolen_or_lost_card: `Customer's card reported lost/stolen to the bank`,
    processing_error: `Technical failure in processing the payment/authenticating the user usually from the card networks`,
    bank_downtime_or_connection_failure: `Customer's payment mode was facing a downtime`,
    users_bank_decline: `Generic issuing bank decline`,
    others: `Unclassified error`,
    transaction_limit_breached: `Customer's daily transaction limit exceeded`,
    user_dropped_or_cancelled: `Customer has either dropped off or was unable to complete the payment`,
    debit_failed: `Generic debit failure. Typical reasons include : Insufficient Funds, Incorrect PIN`,
    account_restrictions: `Customer's account or card is restricted by their bank for the transaction`,
    risk_decline: `Declined because of risk's check failed on merchant/customer`,
    generic_decline: `Generic authorization/authentication failure received from bank.`,
    method_not_supported: `Payment declined as method/card network not properly enabled`,
    account_expired: `Customer's card or account has expired at the time of transaction`,
    otp_pin_issue: `Customer has entered incorrect OTP`,
    insufficient_funds: `Customer did not have sufficient funds to complete the payment`,
}

const FailureReasonsComponent = ({ data }) => {
    const categories = data.failure_reasons.map((item) => item.category)

    const [selectedCategory, setSelectedCategory] = useState(categories[0])
    const [selectedCategoryData, setSelectedCategoryData] = useState(null)

    const handleCategoryClick = (category) => {
        setSelectedCategory(category)
    }

    useEffect(() => {
        const categoryData = data.failure_reasons.find((item) => item.category === selectedCategory)
        setSelectedCategoryData(categoryData)
    }, [selectedCategory, data])

    return (
        <div className='flex flex-col items-start inter w-full'>
            <div className={`w-full ${styles.failureReasonsContainer}`}>
                <div className={styles.leftPanel}>
                    <ul className={styles.categoriesList}>
                        {categories.map((category) => {
                            const categoryData = data.failure_reasons.find((item) => item.category === category)
                            return (
                                <li
                                    key={category}
                                    onClick={() => handleCategoryClick(category)}
                                    className={`${styles.categoryItem} ${
                                        category === selectedCategory ? styles.categoryItemSelected : ''
                                    }`}
                                >
                                    <div className={styles.categoryName}>
                                        <span>{category}</span>
                                        {categoryTooltips[category] && (
                                            <Tooltip title={categoryTooltips[category]}>
                                                <InfoCircleOutlined className='ml-2' height={14} width={14} />
                                            </Tooltip>
                                        )}
                                    </div>
                                    <div className={styles.failureCount}>
                                        {categoryData.total_count}
                                        <span className={styles.failurePercentage}> ({categoryData.percentage}%)</span>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className={styles.rightPanel}>
                    {selectedCategoryData &&
                        (selectedCategoryData.breakdown.length > 0 ? (
                            <ul className={styles.failureReasonsList}>
                                {selectedCategoryData.breakdown.map((data) => (
                                    <li key={data.reason} className={styles.failureReasonItem}>
                                        <div className={styles.failureReason}>{capitalizeFirstLetter(data.reason)}
                                        {subCategoryTooltips[data.reason.toLowerCase().replace(/'/g, '').replace(/[ /]/g, '_')] && (
                                                <Tooltip title={subCategoryTooltips[data.reason.toLowerCase().replace(/'/g, '').replace(/[ /]/g, '_')]}>
                                                    <InfoCircleOutlined className='ml-2' height={14} width={14} />
                                                </Tooltip>
                                        )}
                                        </div>
                                        <div className={styles.failureReasonCount}>
                                            {data.count}
                                            <span className={styles.failureReasonPercentage}>
                                                {' '}
                                                ({data.percentage}%)
                                            </span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className={styles.noFailureReasons}>No {selectedCategory} failures</div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default FailureReasonsComponent
