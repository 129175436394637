import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { Select, Input, Button, Select as AntdSelect, DatePicker, Row, Col, Drawer, Space } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
const { Option } = Select

export default function ({
    setSettlementInitiate,
    onSettlementInitiateSubmit,
    settlementInitiate,
    setOpenDrawer,
    handleCalendarChange,
    openDrawer,
    resetSettlementInititiate,
}) {
    const [merchantList, setMerchantList] = useState([])
    const getMerchantList = async () => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getMerchantList,
            })

            if (response?.data?.data?.length) {
                setMerchantList(response?.data?.data)
            }
        } catch (err) {
            console.warn(err)
        }
    }
    useEffect(() => {
        getMerchantList()
    }, [])

    return (
        <>
            <Drawer
                title='Initiate Settlement Form'
                placement='right'
                onClose={() => {
                    resetSettlementInititiate()
                    setOpenDrawer(false)
                }}
                open={openDrawer}
            >
                {/* <h4 className='fs20'>
                    Payment ID:{' '}
                    <Tooltip title={settlementInitiate?.payment_id} className='text-blue'>
                        {settlementInitiate?.payment_id?.slice(0, 10)}
                    </Tooltip>
                </h4> */}
                <Space direction='vertical' className='w-full'>
                    <Row>
                        <div className='mt-0'>
                            {/* <h4 className='leading-5 mt-0 inter'>
                        Chargebacks are a consumer protection tool that allow consumers to get their money back for
                        fraudulent charges or purchases that don’t live up to standards by submitting a dispute with
                        their card issuer.
                    </h4> */}
                            <h5 className='leading-5  inter text-amber-400'>
                                *Settlement cycle is subject to bank approval and can vary based on your business
                                vertical, risk factor etc. &nbsp;&nbsp;
                                {/* <Link href='https://gokwik.co' target='_blank'>
                                    Learn More.
                                </Link> */}
                            </h5>
                        </div>
                    </Row>
                    <Row>
                        <Col className='flex flex-col gap-y-4' sm={24}>
                            {/* <Select
                                value={settlementInitiate['merchantName']}
                                placeholder='Merchant Name'
                                size='middle'
                                className={'w-full'}
                                showSearch={true}
                                onChange={(newValue: any) => {
                                    setSettlementInitiate((prev) => ({ ...prev, merchantName: newValue }))
                                }}
                                label={'Merchant Name'}
                                allowClear
                            >
                                {merchantList &&
                                    merchantList?.length > 0 &&
                                    merchantList?.map((option) => (
                                        <Option value={option?.m_id} key={option?.id}>
                                            {option?.text || option?.business_name || option?.short_name}
                                        </Option>
                                    ))}
                            </Select> */}
                            <Input
                                label={'Settlement Day'}
                                type='number'
                                value={settlementInitiate['settlementDay']}
                                placeholder='Settlement Day'
                                onChange={(e) => {
                                    setSettlementInitiate((prev) => ({ ...prev, settlementDay: e.target.value }))
                                }}
                                allowClear
                            />
                            <Select
                                label={'Payment Provider'}
                                // size='middle'
                                className={'w-full'}
                                showSearch={true}
                                value={settlementInitiate['paymentProvider']}
                                placeholder='Payment Provider'
                                onChange={(value) => {
                                    setSettlementInitiate((prev) => ({ ...prev, paymentProvider: value }))
                                    if (value === 'icici') {
                                        setSettlementInitiate((prev) => ({ ...prev, payoutType: 'imps' }))
                                    } else if (value === 'ybl') {
                                        setSettlementInitiate((prev) => ({ ...prev, payoutType: 'upi' }))
                                    } else {
                                        setSettlementInitiate((prev) => ({ ...prev, payoutType: '' }))
                                    }
                                }}
                                options={[
                                    { value: 'icici', label: 'ICICI' },
                                    { value: 'ybl', label: 'YBL' },
                                ]}
                                allowClear
                            />
                            <Input
                                className='w-full'
                                label={'Payout Type'}
                                disabled
                                type='text'
                                value={settlementInitiate['payoutType']}
                                placeholder='Payout Type'
                                allowClear
                            />

                            <Row>
                                <label htmlFor='' style={{ margin: '8px 0' }}>
                                    Date Range
                                </label>

                                <DatePicker.RangePicker
                                    onCalendarChange={handleCalendarChange}
                                    onChange={([start, end, string]: any) => {
                                        setSettlementInitiate((prev) => ({ ...prev, start_dt: start }))
                                        setSettlementInitiate((prev) => ({ ...prev, end_dt: end }))
                                    }}
                                    format={'MMM DD, YY'}
                                    className='w-full'
                                />
                            </Row>

                            <div className='flex gap-2 w-full'>
                                <Button onClick={onSettlementInitiateSubmit} variant='primary' className='mt-5 w-[50%]'>
                                    Submit
                                </Button>
                                <Button
                                    onClick={() => {
                                        resetSettlementInititiate()
                                        setOpenDrawer(false)
                                    }}
                                    variant='default'
                                    className='mt-5 w-[50%]'
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Space>
            </Drawer>
        </>
    )
}
