import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

export async function createTest(provider: string, paymentMethod: string, merchantId: string, instruments?: string[]) {

    const response = await makeAPICall({
        url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.paymentTesting.createTest,
        method: 'post',
        payload: {
            mId: merchantId,
            provider,
            paymentMethod,
            ...(instruments ? { instruments } : {})
        },
    })
    return response
}

export async function abortTest(testId) {
    const response = await makeAPICall({
        url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.paymentTesting.abortTest + `/${testId}`,
        method: 'patch',
    });
    return response
}