import { Button, Drawer, PlayCircleOutlined, Checkbox, Select, Badge, message } from "gokwik-ui-kit"
import { PaymentInstrumentStatusEnum } from "./payment-testing.enum"
import { createTest } from "./payment-testing.service"
import _ from "lodash"
import { useSelector } from "react-redux"
import { getMerchantDetails } from "@store/user/selectors"
import { useState, useEffect } from "react"

interface Props {
    open: boolean;
    onClose: () => void;
    selectedProvider: string;
    selectedPaymentMethod: string;
    paymentInstruments: any[];
    setRunningTests: React.Dispatch<React.SetStateAction<Object>>;
}

function TestDrawer({ open, onClose, selectedProvider, selectedPaymentMethod, paymentInstruments, setRunningTests }: Props) {
    const merchantData = useSelector(getMerchantDetails)
    const [selectedInstruments, setSelectedInstruments] = useState<string[]>([])
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([
        PaymentInstrumentStatusEnum.UNASSESSED,
        PaymentInstrumentStatusEnum.FAILURE
    ])

    useEffect(() => {
        const initialSelectedInstruments = paymentInstruments
            .filter(instrument => {
                const status = instrument[selectedProvider.toLowerCase()].status;
                return status === PaymentInstrumentStatusEnum.UNASSESSED || status === PaymentInstrumentStatusEnum.FAILURE;
            })
            .map(instrument => instrument.key);
        setSelectedInstruments(initialSelectedInstruments);
    }, [paymentInstruments, selectedProvider])

    const handleStatusChange = (values: string[]) => {
        setSelectedStatuses(values)
        setSelectedInstruments(paymentInstruments
            .filter(instrument => {
                const status = instrument[selectedProvider.toLowerCase()].status;
                return values.includes(status) || (status === 'Unassessed' && values.includes(PaymentInstrumentStatusEnum.UNASSESSED));
            })
            .map(instrument => instrument.key)
        )
    }

    const handleInstrumentChange = (instrumentKey: string) => {
        setSelectedInstruments(prev => 
            prev.includes(instrumentKey) ? prev.filter(key => key !== instrumentKey) : [...prev, instrumentKey]
        )
    }

    const getStatusBadge = (status) => {
        switch (status) {
            case PaymentInstrumentStatusEnum.SUCCESS:
                return <Badge color="green" text="Success" />;
            case PaymentInstrumentStatusEnum.PARTIAL_SUCCESS:
                return <Badge color="yellow" text="Partial Success" />;
            case PaymentInstrumentStatusEnum.FAILURE:
                return <Badge color="orange" text="Failure" />;
            case null:
            default:
                return <Badge color="gray" text="Unassessed" />;
        }
    };

    return (
        <Drawer
            title={`Run Test`}
            placement="right"
            onClose={onClose}
            open={open}
            width={600}
        >
            <div className="flex flex-col h-full">
                <div className="flex-grow overflow-auto">
                    <div className="text-sm font-medium mb-2">
                        {_.capitalize(selectedProvider)} - {_.capitalize(selectedPaymentMethod)}
                    </div>
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select statuses"
                        value={selectedStatuses}
                        onChange={handleStatusChange}
                        options={[...Object.values(PaymentInstrumentStatusEnum)].map(status => ({
                            value: status,
                            label: `${status}`,
                        }))}
                        className="mb-4"
                    />
                    <div className="space-y-2">
                        {paymentInstruments.map((instrument) => (
                            <div 
                                key={instrument.key} 
                                className="bg-gray-50 rounded-lg p-3 transition-all hover:shadow-md flex items-center justify-between cursor-pointer"
                                onClick={() => handleInstrumentChange(instrument.key)}
                            >
                                <div className="flex items-center">
                                    <Checkbox
                                        checked={selectedInstruments.includes(instrument.key)}
                                        onChange={() => {}}
                                    />
                                    <span className="text-sm font-medium text-gray-700 ml-2">{instrument.name}</span>
                                </div>
                                {getStatusBadge(instrument[selectedProvider.toLowerCase()].status)}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="mt-auto pt-4">
                    <Button 
                        variant="primary" 
                        onClick={async () => {
                            try {
                                const response = await createTest(selectedProvider, selectedPaymentMethod, merchantData?.m_id, selectedInstruments);
                                const testId = response?.data?.data?.test_id;

                                if (response?.response?.data?.status_code == 400) {
                                    const error = response?.response?.data?.error?.message;
                                    message.error(error || 'Failed to start test. Please try again.');
                                    onClose();
                                    return;
                                }

                                setRunningTests((prev) => {
                                    const updatedTests = { ...prev };
                                    if (!updatedTests[selectedProvider]) {
                                        updatedTests[selectedProvider] = {};
                                    }
                                    updatedTests[selectedProvider][selectedPaymentMethod] = {
                                        testId,
                                        isRunning: true
                                    };
                                    return updatedTests;
                                });
                                message.success('Test started successfully');
                                onClose();
                            } catch (error) {
                                console.error('Failed to start test:', error);
                                message.error('Failed to start test. Please try again.');
                                onClose();
                            }
                        }}
                        block
                        className="transition-all hover:bg-blue-600 active:bg-blue-700 active:scale-95 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 hover:bg-blue-500 active:bg-blue-800"
                    >
                        Run Test <PlayCircleOutlined className="ml-2" />
                    </Button>   
                </div>
            </div>
        </Drawer>
    )
}

export default TestDrawer;
