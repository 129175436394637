import { FieldTimeOutlined } from '@ant-design/icons'
import { addBreadcrumb, convertIsoToLocaleString } from '@gokwik/utilities'
import { capitalizeFirstLetter, formatAmount } from '@library/utilities/helpers/helper'
import { ColumnProps, Tooltip } from 'gokwik-ui-kit'
import { Link } from 'react-router-dom'


const bankStatusConditioning = (status) => {
    let finalStatus = '';
    if (status === 'payment_successful') finalStatus = 'Completed';
    else if (['pending', 'timeout', 'payment_initiated'].includes(status)) {
        finalStatus = status === 'payment_initiated' ? 'User Dropped' : status;
    } else if (status === 'payment_failed') finalStatus = 'Failed';
    else finalStatus = status;
    return finalStatus;
  };
export const transactionColumnsInternal: ColumnProps<any>[] = [
    {
        title: <span>Order #</span>,
        dataIndex: 'order_number',
        width: 180,
        ellipsis: true,
        render: (text: string) => (
            <Link
                to={`/checkout/orders/${text}`}
                onClick={() =>
                    addBreadcrumb({
                        key: 'order-details',
                        href: `/checkout/orders/${text}`,
                        text,
                    })
                }
            >
                <a style={{ width: 'min-content' }}>
                    <Tooltip placement='topLeft' title={text}>
                        {text.toUpperCase()}
                    </Tooltip>
                </a>
            </Link>
        ),
        fixed: 'left',
    },
    {
        title: <span>Payment ID</span>,
        dataIndex: 'payment_id',
        render: (text: string) => {
            return <>{text.toUpperCase()}</>
        },
        width: 200,
        fixed: 'left',
    },
    {
        title: <span>Phone</span>,
        dataIndex: 'phone',
    },
    {
        title: <span>Merchant Name</span>,
        dataIndex: 'Merchant.business_name',
    },
    {
        title: <span>Payment Mode</span>,
        dataIndex: 'payment_method',
    },
    {
        title: <span>Payment Provider</span>,
        dataIndex: 'payment_provider',
    },
    {
        title: <span>Payment Status</span>,
        dataIndex: 'bank_status',
        render:(t) => <p className='capitalize'>{bankStatusConditioning(t)}</p>
    },
    {
        title: <span>Amount</span>,
        dataIndex: 'amount',
        render: (e) => <p>₹ {e}</p>,
    },
    {
        title: <span>Auto Refund</span>,
        dataIndex: 'is_auto_refund',
        render: (e) => <p>{e.toString()}</p>,
    },
    {
        title: <span>Is Deleted</span>,
        dataIndex: 'is_deleted',
    },
    {
        title: <span>Created At</span>,
        dataIndex: 'created_at',
        render: (e) => convertIsoToLocaleString(e),
    },
    {
        title: <span>Updated At</span>,
        dataIndex: 'updated_at',
        render: (e) => convertIsoToLocaleString(e),
    },
    {
        title: <span>Settlement At</span>,
        dataIndex: 'settlement.bank_timestamp',
        render: (e) => convertIsoToLocaleString(e),
    },
    {
        title: <span>Merchant ID</span>,
        dataIndex: 'merchant_id',
    },
    {
        title: <span>Customer ID</span>,
        dataIndex: 'customer_id',
    },
    {
        title: <span>Cust Ref ID</span>,
        dataIndex: 'cust_ref_id',
    },
    {
        title: <span>Failure Code</span>,
        dataIndex: 'failure_code',
    },
    {
        title: <span>Response Code</span>,
        dataIndex: 'response_code',
    },
    {
        title: <span>Timeout Status</span>,
        dataIndex: 'timeout_status',
    },
    {
        title: <span>Status Description</span>,
        dataIndex: 'status_description',
        render: (text) => (
            <Tooltip title={text}>
                <p>{text?.slice(0, 20)}</p>
            </Tooltip>
        ),
    },
    {
        title: <span>Failure Bucket 1</span>,
        dataIndex: 'bucket1',
        render: (t) => <p>{capitalizeFirstLetter(t)}</p>
    },
    {
        title: <span>Failure Bucket 2</span>,
        dataIndex: 'bucket2',
        render: (t) => <p>{capitalizeFirstLetter(t)}</p>
    },
    {
        title: <span>Status</span>,
        dataIndex: 'status',
    },
    {
        title: <span>Is Blocked</span>,
        dataIndex: 'is_blocked',
    },
    {
        title: <span>Short Link</span>,
        dataIndex: 'short_link',
    },
    {
        title: <span>UPI Link</span>,
        dataIndex: 'upi_link',
        render: (text) => (
            <Tooltip title={text}>
                <a className='link'>{text?.slice(0, 10)}</a>
            </Tooltip>
        ),
    },
    {
        title: <span>NPC ID</span>,
        dataIndex: 'npc_id',
    },
    {
        title: <span>Settlement id</span>,
        dataIndex: 'settlement_id',
    },
    {
        title: <span>Merchant mid</span>,
        dataIndex: 'Merchant.m_id',
    },
    {
        title: <span>Card Network</span>,
        dataIndex: 'cardNetwork',
    },
    {
        title: <span>Card Issuer</span>,
        dataIndex: 'cardIssuer',
    },
]

export const transactionColumnsMerchant: ColumnProps<any>[] = [
    {
        title: <span>Order #</span>,
        dataIndex: 'order_number',
        width: 180,
        ellipsis: true,
        render: (text: string) => (
            <Link
                to={`/checkout/orders/${text}`}
                onClick={() =>
                    addBreadcrumb({
                        key: 'order-details',
                        href: `/checkout/orders/${text}`,
                        text,
                    })
                }
            >
                <a style={{ width: 'min-content' }}>
                    <Tooltip placement='topLeft' title={text}>
                        {text.toUpperCase()}
                    </Tooltip>
                </a>
            </Link>
        ),
        fixed: 'left',
    },
    {
        title: <span>Payment ID</span>,
        dataIndex: 'payment_id',
        render: (text: string) => {
            return <>{text.toUpperCase()}</>
        },
        width: 200,
        fixed: 'left',
    },
    {
        title: <span>Phone</span>,
        dataIndex: 'phone',
    },
    {
        title: <span>Payment Mode</span>,
        dataIndex: 'payment_method',
    },
    {
        title: <span>Payment Provider</span>,
        dataIndex: 'payment_provider',
    },
    {
        title: <span>Amount</span>,
        dataIndex: 'amount',
        render: (e) => <p>{formatAmount(e,2)}</p>,
    },
    {
        title: <span>Bank Status</span>,
        dataIndex: 'bank_status',
    },
    {
        title: <span>Auto Refund</span>,
        dataIndex: 'is_auto_refund',
        render: (e) => <p>{e.toString()}</p>,
    },
    {
        title: <span>Created At</span>,
        dataIndex: 'created_at',
        render: (e) => convertIsoToLocaleString(e),
    },
    {
        title: <span>Status Description</span>,
        dataIndex: 'status_description',
    },
    {
        title: <span>Failure Bucket 1</span>,
        dataIndex: 'bucket1',
        render: (t) => <p>{capitalizeFirstLetter(t)}</p>
    },
    {
        title: <span>Failure Bucket 2</span>,
        dataIndex: 'bucket2',
        render: (t) => <p>{capitalizeFirstLetter(t)}</p>
    },
    {
        title: <span>Card Network</span>,
        dataIndex: 'cardNetwork',
    },
    {
        title: <span>Card Issuer</span>,
        dataIndex: 'cardIssuer',
    },
]
