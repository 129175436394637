import { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { DownloadOutlined, UploadOutlined, ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, CloseCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import {
    dashboardStore,
    makeAPICall,
    csvToJson,
    downloadToCSV,
    convertIsoToLocaleString,
    filterDateFormatter,
    downloadFromURL,
    validatePermission,
} from '@gokwik/utilities'
import { Table, Row, Button, Col, Upload, ColumnProps, message, Tag, Tooltip } from 'gokwik-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { getMerchantDetails, getMerchantPlatform, isMerchantSelector, getUserDetails } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { previousBulkRefundsFilters } from '@store/filters/selectors'
import { saveFiltersData } from '@store/filters'
import RenderSearchFilters from '@library/components/search-filter'
import { PermissionValues } from '@library/utilities/constants/constants'
import { handleError } from '@library/utilities/helpers/handleError'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
const today = dayjs()
const formattedDate = filterDateFormatter(today)

// const isInvalidString = (string) => string.length > 40 || !/^[a-zA-Z0-9#_ -]+$/.test(string.trimRight())
const isInvalidString = (string) => {
    string = String(string)

    if (typeof string !== 'string' || string.trim().length === 0) return true // Ensure it's a non-empty string
    return string.length > 40 || !/^[a-zA-Z0-9#_ -]+$/.test(string.trimEnd())
}

const downloadReport = async (url: string) => {
    const res = await makeAPICall({
        method: 'get',
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.downloadBulkRefund,
        params: { file_name: url },
    })
    if (res.success) {
        downloadFromURL(res.data.data, url.split('/')[2])
    } else message.error('Unable to download')
}

export default function () {
    const prevFilters = useSelector(previousBulkRefundsFilters)
    const [total, setTotal] = useState(0)
    const [refund, setRefunds] = useState([])
    const dispatch = useDispatch()
    const merchantId = useSelector(getMerchantDetails)?.id
    const merchantData = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const [parameters, setParameters] = useState({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        st_date: formattedDate,
        end_date: formattedDate,
        ...prevFilters,
    })
    const merchantPlatform = useSelector(getMerchantPlatform)
    const isShopify = merchantPlatform === 'shopify'
    const [activeCarouselItem, setActiveCarouselItem] = useState(0)
    const isMerchantUser = useSelector(isMerchantSelector)

    const carouselTexts = useMemo(
        () => [
            `Use Bulk refunds to make refunds in bulk using a CSV file. Once you upload a file, it is picked up for
          processing after 60 minutes.`,
            `You can cancel a batch upload in the 60 minutes before it is picked up for
          processing.`,
            ...(isShopify ? [`Shopify Order Number is required in the CSV File.`] : []),
            ` Pending transactions in timeout state will be processed in 3-5 days.`,
        ],
        [isShopify],
    )

    const columns: ColumnProps<any>[] = useMemo(
        () => [
            ...(!isMerchantUser
                ? [
                      {
                          title: <span>Merchant Name</span>,
                          dataIndex: 'Merchant',
                          render: (text) => <span>{text['business_name']}</span>,
                      },
                  ]
                : []),
            {
                title: <span>Name / Url</span>,
                dataIndex: 'file_path',
                width: 450,
                ellipsis: true,
                render: (text: string, record: any) => (
                    <p className='flex items-center'>
                        <span>
                            {text?.slice?.(0, 45)}
                            {text?.slice?.(0, 45).length < text.length ? '...' : ''}
                        </span>
                        {record.partial_failure && (
                            <span className='text-error-700 text-xs font-medium ml-2'>(Error)</span>
                        )}
                    </p>
                ),
            },
            {
                title: <span>Status</span>,
                dataIndex: 'status',
                render: (text: string) => (
                    <div>
                        {text.toLowerCase() === 'processed' ? (
                            <Tag variant='success' className='rounded-2xl !px-2 !py-1'>
                                Processed
                            </Tag>
                        ) : text.toLowerCase() === 'failed' || text.toLowerCase() === 'cancelled' ? (
                            <Tag variant='error' className='rounded-2xl !px-2 !py-1'>
                                {text}
                            </Tag>
                        ) : (
                            <Tag variant='warning' className='rounded-2xl !px-2 !py-1'>
                                {text}
                            </Tag>
                        )}
                    </div>
                ),

                align: 'center',
            },
            {
                title: <span>Created At</span>,
                dataIndex: 'created_at',
                render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,

                sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
                sortDirections: ['ascend', 'descend'],
            },
            {
                title: <span>Updated At</span>,
                dataIndex: 'updated_at',
                render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
                sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
                sortDirections: ['ascend', 'descend'],
            },
            {
                title: <span>Action</span>,
                dataIndex: 'file_path',
                render: (text: string,record:any) => {
                    console.log(record)
                    return (
                        <>
                    {record.status === "Initiated" && isMerchantUser ? 
                                <Tooltip title='Cancel Refund List'>
                           <p onClick={() => cancelReport(record.id)} className='text-red-500 cursor-pointer'>Cancel <CloseCircleOutlined  /></p>
                        </Tooltip> : (record.status === "Processed" && isMerchantUser &&
                                    <Tooltip title='Download'>
                                        <DownloadOutlined onClick={() => downloadReport(text)} />
                        </Tooltip>)}
                    {!isMerchantUser &&
                    (
                                <Tooltip title='Download'>
                                    <DownloadOutlined onClick={() => downloadReport(text)} />
                                </Tooltip>
                    )
                    }
                    </>
                    
                )},
            },
        ],
        [isMerchantUser],
    )

    const cancelReport = async (id)  =>{
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.cancelRefundList + "/"+id,
            })
            if(response.success){
                message.success('Refund Cancel Successfull');
                fetchBulkRefund(parameters)
            }else{
                message.error('Cancellation Failed');
            }
        } catch (error) {
            handleError(error)
        }
    }

    const reset = (e) => {
        e.preventDefault()
        const newParams = {
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            st_date: formattedDate,
            end_date: formattedDate,

        }
        setParameters({ ...newParams })
        fetchBulkRefund({ ...newParams })
    }

    const fetchBulkRefund = async (parameters) => {
        // if (!isMerchantUser && !parameters.mid) return message.error('Merchant name is required')
        logEvent('kwik_payment_bulk_refund_clicked', 'click', 'Kwik Payment Bulk Refund', user_details?.email, merchantData?.m_id, merchantData?.short_name, user_details?.name, parameters);
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getBulkRefund,
                params: parameters,
            })

            if (response.data?.data?.refund?.length > 0) {
                setRefunds(response.data?.data?.refund)
                setTotal(response.data?.data?.totalCount)
            } else {
                dispatch<any>(saveFiltersData('bulk_refunds', { ...parameters }))
                setRefunds([])
                setTotal(0)
            }
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
        // if (parameters.page && (isMerchantUser || parameters.mid)) {
        //     fetchBulkRefund(parameters)
        // }
        fetchBulkRefund(parameters)
    }, [])

    const validateBulkRefundFile = async (file: any): Promise<boolean> => {
        const mappedCols = {
            order_number: 'Order Number',
            shopify_order_number: 'Shopify Order Number',
            merchant_order_id: 'Merchant Order ID',
            refund_amount: 'Refund Amount',
        }
        const validateFile = () =>
            new Promise((resolve, reject) => {
                const reader = new FileReader()
                const data = new FormData()
                data.append('file', file)
                reader.readAsText(file)
                reader.onload = async (e) => {
                    if (typeof e.target.result === 'string') {
                        const csvData = csvToJson(e.target.result)
                        const headings = csvData[0] || {}
                        if (Object.keys(headings).length > 3)
                            return message.error('Invalid format. Please remove any additional column.')
                        if (!headings.hasOwnProperty(mappedCols.order_number))
                            return message.error('Please add order number column.')
                        if (isShopify && !headings.hasOwnProperty(mappedCols.shopify_order_number))
                            return message.error('Please add shopify order number column.')
                        else if (!isShopify && !headings.hasOwnProperty(mappedCols.merchant_order_id))
                            return message.error('Please add merchant order ID column.')
                        if (!headings.hasOwnProperty(mappedCols.refund_amount))
                            return message.error('Please add refund amount column.')
                        let showError = false
                        const verifiedData = csvData.map((curr, i) => {
                            let msg = ``
                            if (isShopify) {
                                if (!curr[mappedCols.order_number] && !curr[mappedCols.shopify_order_number])
                                    msg = 'Please add either order number or shopify order number'
                                else if (
                                    curr[mappedCols.order_number] &&
                                    isInvalidString(curr[mappedCols.order_number])
                                )
                                    msg = 'Invalid order number'
                                else if (
                                    curr[mappedCols.shopify_order_number] &&
                                    isInvalidString(curr[mappedCols.shopify_order_number])
                                )
                                    msg = 'Invalid shopify order number'
                            } else {
                                if (!curr[mappedCols.order_number] || !curr[mappedCols.merchant_order_id])
                                    msg = `Please add ${
                                        !curr[mappedCols.order_number] ? 'order number' : 'merchant order ID'
                                    }`
                                else if (
                                    curr[mappedCols.order_number] &&
                                    isInvalidString(curr[mappedCols.order_number])
                                )
                                    msg = 'Invalid order number'
                                else if (
                                    curr[mappedCols.merchant_order_id] &&
                                    isInvalidString(curr[mappedCols.merchant_order_id])
                                )
                                    msg = 'Invalid merchant order ID'
                            }
                            if (!curr[mappedCols.refund_amount] || isNaN(+curr[mappedCols.refund_amount])) {
                                msg = `${msg ? msg + ' | ' : ''}Please enter valid refund amount`
                            }
                            if (msg && !showError) showError = true
                            return { ...curr, error: msg }
                        })
                        if (showError) {
                            const headings = [
                                mappedCols.order_number,
                                isShopify ? mappedCols.shopify_order_number : mappedCols.merchant_order_id,
                                mappedCols.refund_amount,
                            ]
                            reject(false)

                            downloadToCSV(
                                [...headings, 'error'],
                                [...headings, 'Error'],
                                verifiedData,
                                'sample-bulk-refund',
                            )
                            return message.error('Invalid format. Errors added in downloaded CSV')
                        } else resolve(true)
                    }
                }
            })
        const valid = await validateFile()
        return !!valid
    }

    const triggerBulkRefund = async (file: any, fileName: string) => {
        try {
            let fileUrl = ''
            const filePath = `merchant/${merchantId}/bulk_refund_${new Date().getTime()}.csv`
            if (file) {
                const presignedUrlResponse = await makeAPICall({
                    url: process.env.REACT_APP_BASE_URL + APIEndPoints.refundPresignedUrl,
                    method: 'get',
                    params: {
                        url: filePath,
                    },
                })
                if (presignedUrlResponse.error) {
                    message.error('Unable to upload document')
                    return
                }
                fileUrl = presignedUrlResponse.data.data
                fetch(fileUrl, {
                    method: 'PUT',
                    body: file,
                })
            }

            const response = await makeAPICall({
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.initiateBulkRefund,
                method: 'post',
                payload: {
                    file_path: filePath,
                },
            })

            if (response?.success) {
                message.success(response.data.message || 'Refund Initiated Successfully!')
                fetchBulkRefund(parameters)
            } else {
                message.error(response.response?.data?.message || 'Something went wrong. Try again later!')
            }
        } catch (error) {
            handleError(error)
        }
    }

    const handleSearchClick = (e) => {
        e?.preventDefault()
        const newParams = { ...parameters, page: 1 }
        setParameters(newParams)
        fetchBulkRefund({ ...newParams })
    }

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        fetchBulkRefund({ ...parameters, page: current, pageSize })
    }

    const downloadSample = () => {
        const headings = ['Order Number', isShopify ? 'Shopify Order Number' : 'Merchant Order ID', 'Refund Amount']
        const dataKeys = ['order_number', 'platform_order_number', 'amount']
        const data = [
            {
                order_number: '198Gokwikktye',
                platform_order_number: '282Lkdjfielkfsk',
                amount: '445.99',
            },
        ]
        downloadToCSV(dataKeys, headings, data, 'sample-bulk-refund')
    }

    return (
        <div className='flex w-full flex-col gap-y-5'>
            {isMerchantUser && (
                <Row className='bg-white p-3 rounded-b' justify={'space-between'}>
                    <Col className='flex gap-x-2' lg={15}>
                        <ArrowLeftOutlined
                            onClick={() =>
                                setActiveCarouselItem((prev) => (prev ? prev - 1 : carouselTexts.length - 1))
                            }
                        />
                        <div className='flex flex-col gap-y-2 flex-grow '>
                            <p className='text-base font-bold'>
                                {activeCarouselItem + 1}/{carouselTexts.length} Important information
                            </p>
                            <p className='h-12'>{carouselTexts[activeCarouselItem]}</p>
                        </div>
                        <ArrowRightOutlined
                            onClick={() =>
                                setActiveCarouselItem((prev) => (prev + 1 === carouselTexts.length ? 0 : prev + 1))
                            }
                        />
                    </Col>
                    {validatePermission(PermissionValues.refunds.initiateRefund) && (
                        <Col className='flex items-center gap-x-3'>
                            <Upload
                                accept='.csv'
                                onChange={(info) => {
                                    // setUploadingStaus(info.file.status)
                                }}
                                showUploadList={false}
                                customRequest={async (options) => {
                                    const { onSuccess, onError, file, onProgress } = options
                                    const { size, name } = file as any
                                    const validFile = await validateBulkRefundFile(file)
                                    if (validFile) {
                                        triggerBulkRefund(file, name)
                                    }
                                }}
                            >
                                <Button variant='primary'>
                                    <UploadOutlined />
                                    Upload CSV
                                </Button>
                            </Upload>
                            <Button variant='default' onClick={downloadSample}>
                                <DownloadOutlined />
                                Download Sample
                            </Button>
                        </Col>
                    )}
                </Row>
            )}
            <div className='bg-white rounded '>
                <RenderSearchFilters
                    values={parameters}
                    setValues={(data, reset) => setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))}
                    page='bulk_refunds'
                    onSearch={handleSearchClick}
                    onReset={reset}
                />
            </div>

            <div className='bg-white rounded overflow-clip'>
                <Table
                    columns={columns}
                    dataSource={refund || []}
                    style={{
                        width: '100vw',
                    }}
                    pagination={{
                        current: parameters.page, // Current page number
                        pageSize: parameters.pageSize, // Number of items to display per page
                        total: total, // Total number of items in the data array
                        showSizeChanger: false,
                        onChange: handlePaginationChange,
                        position: ['topLeft', 'bottomLeft'],
                        showTotal: (total, range) => (
                            <p className='ml-4'>{`Showing ${range[0]}-${range[1]} of ${total} Records`}</p>
                        ),
                    }}
                    scroll={{ x: 'max-content' }}
                />
            </div>
        </div>
    )
}
