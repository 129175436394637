import React from 'react'
import { Tabs } from 'gokwik-ui-kit'
import Refund from './refund'
import BulkRefund from './bulkRefund'

const items = [
    {
        key: '1',
        label: 'Refund',
        children: <Refund />,
    },
    {
        key: '2',
        label: 'Bulk Refund',
        children: <BulkRefund />,
    },
]

export default () => (
    <>
        <Tabs
            tabBarStyle={{
                paddingLeft: '1rem',
                background: 'white',
                maxWidth: '100%',
                margin: 0,
                borderTopLeftRadius: '0.25rem',
                borderTopRightRadius: '0.25rem',
            }}
            className=' w-[100%] rounded-t'
            items={items}
        />
    </>
)
