import { updateBreadcrumbs } from '@gokwik/utilities'
import { getMerchantDetails, getUserDetails, isMerchantSelector } from '@store/user/selectors'
import { Button, Col, RedoOutlined, SearchOutlined, Table, TagTwoTone } from 'gokwik-ui-kit'
import { lazy, Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { previousPaymentOfferFilters } from '@store/filters/selectors'
import { getColumns } from './columns'
import { OfferContext, OfferDetails, OfferListing, OfferPageParent, OfferStatus } from './constants'
import { getOfferDetails, getOfferListing, updateMerchantOfferConfig, updateOffer } from './offer.service'
import RenderSearchFilters from '@library/components/search-filter'
import { saveFiltersData } from '@store/filters'

const DetailsDrawer = lazy(() => import('./details'))
const AddRemoveMerchant = lazy(() => import('./addRemoveMerchant'))

const defaultBreadCrumbs = [
    {
        key: 'settings',
        href: '/payment/settings',
        text: 'Payment Settings',
    },
    {
        key: 'offers',
        href: '/payment/settings/offers',
        text: 'Payment Offers',
    },
]

export default function PaymentOffers({ breadcrumbs, parent }: { breadcrumbs?: []; parent?: OfferPageParent }) {
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantData = useSelector(getMerchantDetails)
    const userData = useSelector(getUserDetails)
    const prevFilters = useSelector(previousPaymentOfferFilters)
    const [columns, setColumns] = useState([])
    const [merchantList, setMerchantList] = useState([])
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [offerListing, setOfferListing] = useState<OfferListing[]>([])
    const [openDetailsDrawer, setOpenDetailsDrawer] = useState<number>(null)
    const [openAddRemoveMerchantData, setOpenAddRemoveMerchantData] = useState<OfferDetails>(null)
    const [parameters, setParameters] = useState({
        page: 1,
        pageSize: 25,
        merchantId: null,
        categories: null,
        couponCode: null,
        ...prevFilters,
    })
    const dispatch = useDispatch()
    const newParameters = {
        page: 1,
        pageSize: 25,
        merchantId: null,
        categories: null,
        couponCode: null,
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [prev[0], ...(breadcrumbs ?? defaultBreadCrumbs)])

        return () => {
            dispatch<any>(saveFiltersData('payment_offers', { ...newParameters }))
        }
    }, [])

    useEffect(() => {
        const debounced = setTimeout(() => {
            setColumns(getColumns(isMerchantUser))
            getListing(parameters)
        }, 500)

        return () => clearTimeout(debounced)
    }, [isMerchantUser, merchantData])

    useEffect(() => {
        setMerchantList(
            userData?.allowed_merchants?.map((row) => {
                return {
                    label: row.short_name ?? row.business_name,
                    value: row.merchant_id,
                    original: row,
                }
            }),
        )
    }, [userData])

    const getListing = (params) => {
        setLoading(true)
        const categories = parameters.categories?.length ? parameters.categories.join(',') : undefined
        const listingParams = { ...params, categories }
        if (parent === OfferPageParent.PAYMENT) {
            listingParams.merchantId = merchantData?.m_id
        }
        getOfferListing(
            listingParams,
            (data, count) => {
                setOfferListing(data)
                setTotal(count)
                setLoading(false)
            },
            parent,
        )
        dispatch<any>(saveFiltersData('payment_offers', { ...params }))
    }

    const reset = (e) => {
        e.preventDefault()
        setParameters({ ...newParameters })
        getListing(newParameters)
    }

    const handleSearchClick = (e) => {
        e?.preventDefault()
        const newParams = { ...parameters, page: 1 }
        setParameters(newParams)
        getListing(newParams)
    }

    const handlePaginationChange = (current: number, pageSize?: number) => {
        const newParams = { ...parameters, page: current, pageSize }
        setParameters(newParams)
        getListing(newParams)
    }

    const updateStatus = (checked: boolean, id: string, success, errorCallback) => {
        if (parent === OfferPageParent.PAYMENT) {
            updateMerchantOfferConfig({
                payload: {
                    offerId: id,
                    merchantId: merchantData?.m_id,
                    is_enabled: checked,
                },
                success: () => (getListing(parameters), success?.()),
                errorCallback,
            })
        } else {
            updateOffer({
                id,
                payload: {
                    status: checked ? OfferStatus.PUBLISHED : OfferStatus.PAUSED,
                },
                success: () => (getListing(parameters), success?.()),
                errorCallback,
            })
        }
    }

    const addAvailableStatus = (filters) => {
        const inputOptions = filters?.find((filter) => filter.key === 'status')?.input_details?.options
        if (!inputOptions?.some((option) => option.value === 'available')) {
            inputOptions?.push({
                id: inputOptions?.length + 1,
                text: 'Available',
                value: 'available',
            })
        }
    }

    const addRemoveMerchant = (id: number) => {
        if (offerListing?.[id]) {
            getOfferDetails(offerListing[id].id, setOpenAddRemoveMerchantData)
        }
    }

    return (
        <OfferContext.Provider
            value={{
                merchantList,
                parent,
                viewOffer: (id) => setOpenDetailsDrawer(id + 1),
                addRemoveMerchant,
                updateStatus,
            }}
        >
            <div className='w-full'>
                <div className='flex justify-between items-center'>
                    <div>
                        <div className='flex items-baseline mb-1'>
                            <span className='pr-2'>
                                <TagTwoTone
                                    size={12}
                                    className='border-2 rounded-full w-6 p-1 bg-blue-500 bg-opacity-10'
                                />
                            </span>
                            <p className='text-base font-medium mb-1'>Payment Offers</p>
                        </div>
                        <p className='text-xs text-gray-400 font-normal'>
                            {isMerchantUser
                                ? 'View and modify the payment offers made for your customers'
                                : 'View and modify the payment offers of the merchants'}
                        </p>
                    </div>
                </div>
                <div className='mt-4 rounded-lg bg-white'>
                    <RenderSearchFilters
                        values={parameters}
                        setValues={(data, reset) =>
                            setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))
                        }
                        page='payment_offers'
                        onSearch={handleSearchClick}
                        onReset={reset}
                        classNames={{ filterWrapper: 'flex-1 min-w-28 !max-w-48' }}
                        extras={{
                            firstRowJustify: 'start',
                            filterModify: parent === OfferPageParent.PAYMENT ? addAvailableStatus : undefined,
                        }}
                    >
                        <Col className='ms-auto flex items-center gap-x-3'>
                            <Button onClick={handleSearchClick} variant='primary'>
                                <SearchOutlined />
                                Search
                            </Button>
                            <Button onClick={reset} variant='default'>
                                <RedoOutlined />
                                Reset
                            </Button>
                        </Col>
                    </RenderSearchFilters>
                </div>
                <div className='mt-4 mb-16 bg-white rounded-lg overflow-clip box-border'>
                    <Table
                        rowKey='id'
                        columns={columns}
                        dataSource={offerListing ?? []}
                        loading={loading}
                        style={{
                            width: '100vw',
                        }}
                        pagination={{
                            current: parameters.page,
                            pageSize: parameters.pageSize,
                            total,
                            showSizeChanger: false,
                            onChange: handlePaginationChange,
                            position: ['topLeft', 'bottomLeft'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                        }}
                        scroll={{ x: 800 }}
                    />
                </div>
            </div>
            <Suspense fallback={<></>}>
                <DetailsDrawer
                    data={offerListing?.[openDetailsDrawer - 1]}
                    handleClose={() => {
                        setOpenDetailsDrawer(null)
                    }}
                />
                {!isMerchantUser && (
                    <AddRemoveMerchant
                        data={openAddRemoveMerchantData}
                        onClose={(cancel) => (setOpenAddRemoveMerchantData(null), !cancel && getListing(parameters))}
                    />
                )}
            </Suspense>
        </OfferContext.Provider>
    )
}
