import { Table, ColumnProps, Button, message, Input, Switch } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { SuccessStatusCodes } from '@library/utilities/constants/constants'
export default function () {
    const columns: ColumnProps<any>[] = [
        {
            title: <span>Bank Name</span>,
            dataIndex: 'name',
        },
        {
            title: <span>Status</span>,
            dataIndex: 'active',
            render: (value, record) => (
                <Switch checked={value} onChange={(data) => updateData(record.id, 'active', data)} />
            ),
        },
        {
            title: <span>Rank</span>,
            dataIndex: 'rank',
            width: 100,
            render: (value, record) => (
                <Input value={value} type='number' onChange={(e) => updateData(record.id, 'rank', e.target.value)} />
            ),
        },
    ]
    const [mappings, setMappings] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const merchantData = useSelector(getMerchantDetails)

    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRows(selectedRows)
        setSelectedRowKeys(selectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => onSelectChange(selectedRowKeys, selectedRows),
    }

    useEffect(() => {
        getMappings()
    }, [])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/payment/settings',
                text: 'Payment Settings',
            },
            {
                key: 'providers',
                href: '/payment/settings/netbanking-mapping',
                text: 'Netbanking Mapping',
            },
        ])
    }, [])
    async function getMappings() {
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.getNetbankingMappings(merchantData.m_id),
            })
            if (SuccessStatusCodes.includes(response?.data?.status_code) && response.data?.data?.length > 0) {
                const mappingData = response.data.data.map((res, key) => ({ ...res, key }))
                setMappings(mappingData)
            } else {
                setMappings([])
            }
        } catch (error) {
            handleError(error)
        }
    }

    function updateData(id, key, value) {
        if(key === 'rank' && (Number(value) <= -2 || (value !== '' && Number(value) === 0))) {
            return;
        };
        setMappings((prevState) =>
            prevState.map((item) => {
                if (item.id === id) {
                    return { ...item, [key]: value }
                }
                return item
            }),
        )
    }

    async function updateMapping() {
        if (!merchantData?.m_id) {
            message.error('Merchant is required')
            return
        }
        try {
            const updatedData = selectedRows.map(({ id, rank, active }) => ({ id, rank, active }))
            let response = await makeAPICall({
                method: 'put',
                url: `${process.env.REACT_APP_AUX_BASE_URL}${APIEndPoints.updateMappings(merchantData?.m_id)}`,
                payload: { data: updatedData },
            })
            if (SuccessStatusCodes.includes(response?.data?.status_code)) {
                message.success('Mapping updated successfully')
                onSelectChange([],[])
                setTimeout(() => getMappings(), 100)
            } else {
                message.error('Failed to update mapping')
            }
        } catch (error) {
            handleError(error)
        }
    }
    return (
        <div className='w-full h-full'>
            <div className='flex justify-between items-center'>
                <div>
                    <p className='text-base font-medium mb-1'>Netbanking Mapping</p>
                    <p className='text-xs text-gray-400 font-normal'>
                        Select the rows you want to update and click on Update Mapping.
                    </p>
                </div>
                <Button onClick={() => updateMapping()} variant='primary' disabled={!selectedRows.length}>
                    Update Mapping
                </Button>
            </div>
            <div className='mt-4 bg-white rounded overflow-clip'>
                <Table
                    showHeader
                    rowClassName={(record) => (record?.active ? '' : 'bg-gray-200')}
                    columns={columns}
                    dataSource={mappings}
                    style={{
                        width: '100vw',
                    }}
                    pagination={false}
                    rowSelection={rowSelection}
                />
            </div>
        </div>
    )
}
