import dayjs from 'dayjs'
import styles from './styles.module.css'
import utc from 'dayjs/plugin/utc'
import { InfoCircleOutlined, ReactApexChart } from 'gokwik-ui-kit'
import Chart from './chart'
import { Charts } from './chartsData'
dayjs.extend(utc)

const stepsData = {
    payment_page_landed: {
        label: 'Payment Page Landed',
        step: 1,
        color: '#DE425B',
    },
    prepaid_selected: { label: 'Prepaid Selected', step: 2, color: '#FC8813' },
    cod_selected: { label: 'COD Converted', step: 2, color: '#FEA539' },
    nothing_selected: { label: 'Nothing Selected', step: 2, color: '#FFF1D4' },
    prepaid_payment_attempted: { label: 'Prepaid Attempted', step: 3, color: '#AAAE46' },
    nothing_attempted: { label: 'No Attempts', step: 3, color: '#D0D185' },
    successful_prepaid_orders: {
        label: 'Successful Prepaid Orders',
        step: 4,
        color: '#58A53D',
    },
    payment_failures: { label: 'Payment Failures', step: 4, color: '#9AD482' },
    intent_drop_offs: { label: 'Intent Dropoffs', step: 4, color: '#DEF2D5' },
}

const chartSeries = [
    {
        name: 'Prepaid selection %',
        color: 'var(--primary-blue-100)',
        definition: 'Prepaid Method Selected/Payment Page Landed',
    },
    {
        name: 'COD selection %',
        color: 'var(--primary-blue-400)',
        definition: 'COD Method Selected/Payment Page Landed',
    },
    {
        name: 'Payment page conversion %',
        color: 'var(--primary-blue-800)',
        definition: 'Total Orders/Payment Page Landed',
    },
]

const chartLegends = ({ series }) => {
    return (
        <div className={styles.chartSeriesInfo}>
            {series.map((item, index) => (
                <div className={styles.seriesItem} key={index}>
                    <div className={styles.colorBox} style={{ backgroundColor: item.color }}></div>
                    <span className={styles.seriesLabel}>{item.name}</span>
                    <div className={styles.infoIcon}>
                        <InfoCircleOutlined height={12} width={12} />
                        <div className={styles.tooltip}>{item.definition}</div>
                    </div>
                </div>
            ))}
        </div>
    )
}

const PaymentPageFunnel = ({ data, dates, view_mode, resolution }) => {
    if (view_mode === 'line') {
        const chart = Charts.find(chart => chart.dataKey === 'paymentFunnel');
        return (
            <div>
                <Chart
                    {...chart.chartProps}
                    resolution={resolution}
                    wrapperClassName='min-h-[315px] h-full'
                    dates={dates}
                    data={data?.funnel_breakdown}
                    seriesOptions={chart.seriesOptions}
                    chartKey={chart.dataKey}
                />
                <div className={styles.chartSeriesInfo}>{chartLegends({ series: chartSeries })}</div>
            </div>
        )
    }

    const values = data.funnel_steps
    const orderedKeys = Object.keys(stepsData)
    const funnelData = orderedKeys.map((stepKey) => ({
        name: stepsData[stepKey].label,
        count: values[stepKey],
        step: stepsData[stepKey].step,
        color: stepsData[stepKey].color,
    }))
    const getTotalCount = (step) => {
        return funnelData.filter((s) => s.step === step).reduce((total, s) => total + s.count, 0)
    }
    const maxCounts = Array.from({ length: 4 }, (_, index) => getTotalCount(index + 1))

    const bar = 105
    const gap = 150
    const diff = gap - bar
    const areaSeries = [
        { x: diff / 2, y: values?.payment_page_landed || 0 },
        { x: diff / 2 + bar, y: values?.payment_page_landed || 0 },
        {
            x: (3 * diff) / 2 + bar,
            y: (values?.prepaid_selected || 0) + (values?.cod_selected || 0),
        },
        { x: (3 * diff) / 2 + 2 * bar, y: values?.prepaid_selected || 0 },
        { x: (5 * diff) / 2 + 2 * bar, y: values?.prepaid_payment_attempted || 0 },
        { x: (5 * diff) / 2 + 3 * bar, y: values?.prepaid_payment_attempted || 0 },
        { x: (7 * diff) / 2 + 3 * bar, y: values?.successful_prepaid_orders || 0 },
        { x: (7 * diff) / 2 + 4 * bar, y: values?.successful_prepaid_orders || 0 },
    ]
    const funnelSeries = funnelData.reduce((acc, step, index) => {
        const seriesData = {
            name: step.name,
            data: Array.from({ length: step.step - 1 }, () => 0).concat([step.count]),
            percentage: ((step.count / getTotalCount(step.step)) * 100).toFixed(1),
            color: step.color,
        }
        return [...acc, seriesData]
    }, [])
    const xcategories = [gap / 2, (3 * gap) / 2, (5 * gap) / 2, (7 * gap) / 2]
    const stepsWithCategories = Object.values(stepsData).reduce((acc, category) => {
        if (!acc[category.step - 1]) {
            acc[category.step - 1] = []
        }
        acc[category.step - 1].push(category)
        return acc
    }, [])
    const legendItems = stepsWithCategories.map((step, stepIndex) => (
        <div key={stepIndex} className={styles.legendStep}>
            {step.map(
                (category, categoryIndex) =>
                    values[category] !== 0 && (
                        <div key={categoryIndex} className={styles.legendItem}>
                            <span className={styles.colorSquare} style={{ backgroundColor: category.color }}></span>
                            <span className={styles.labelName}>{category.label}</span>
                        </div>
                    ),
            )}
        </div>
    ))

    const generateStepAnnotation = (step, maxCount) => ({
        x: xcategories[step - 1],
        y: maxCount,
        marker: { size: 0 },
        label: {
            text: `Step ${step}`,
            position: 'top',
            style: {
                fontSize: '14px',
                fontWeight: 500,
                color: '#667085',
            },
            borderWidth: 0,
        },
    })

    const areaChartData: {
        options: ApexCharts.ApexOptions
        series: any
    } = {
        options: {
            annotations: {
                points: [
                    ...Array.from(
                        { length: 4 },
                        (_, index) => maxCounts[index] !== 0 && generateStepAnnotation(index + 1, maxCounts[index]),
                    ),
                ],
            },
            chart: {
                type: 'area',
                width: '100%',
                toolbar: {
                    show: false,
                },
            },
            xaxis: {
                labels: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'straight',
                width: 0,
            },
            markers: {
                size: 0,
            },
            fill: {
                type: 'solid',
                opacity: 0.2,
            },
            yaxis: {
                show: false,
                max: maxCounts[0],
                min: 0,
            },
            grid: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
            legend: {
                show: false,
            },
        },
        series: [
            {
                name: 'Count',
                data: areaSeries,
            },
        ],
    }

    const funnelChartData = {
        options: {
            chart: {
                stacked: true,
                width: '100%',
                toolbar: {
                    show: false,
                },
            },
            xaxis: {
                categories: xcategories,
                labels: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
                state: {
                    hover: {
                        filter: 'none',
                    },
                },
            },
            dataLabels: {
                enabled: true,
                position: 'center',
                formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                    const category = funnelSeries[seriesIndex].name
                    const percentage = funnelSeries[seriesIndex].percentage
                    if (['No Attempts', 'Intent Dropoffs', 'Payment Failures', 'Nothing Selected'].includes(category)) {
                        return ''
                    }
                    return `${percentage}%`
                },
                style: {
                    colors: ['#FFFFFF'],
                    fontSize: '16px',
                    fontFamily: 'Inter',
                },
            },
            fill: {
                opacity: 1,
            },
            legend: {
                show: false,
            },
            yaxis: {
                show: false,
                max: maxCounts[0],
            },
            grid: {
                show: false,
            },
            tooltip: {
                shared: false,
                intersect: false,
                y: {
                    formatter: function (val) {
                        return val
                    },
                },
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    const stepNumber = dataPointIndex + 1
                    const categoriesDetails = funnelData
                        .filter((item) => item.step === stepNumber && item.count > 0)
                        .map((item) => {
                            const percentage = ((item.count * 100) / getTotalCount(item.step)).toFixed(2)
                            return `
                                <div class='flex justify-between'>
                                    <div class='align-items-left'>
                                        <span class='${styles.colorCircle}' style='background-color:${item.color}'></span>
                                        <span class='justify-end pr-6 mb-1 text-[9px] text-gray-500'>
                                            ${item.name}
                                        </span>
                                    </div>
                                    <span class='justify-end pr-2 text-[10px] text-right mt-1 mb-1 w-1/3'>
                                        ${percentage}%
                                    </span>
                                </div>`
                        })

                    return `
                        <div class='charts-tool-tip bg-white flex flex-col inter p-2'>
                            ${categoriesDetails.join('')}
                        </div>`
                },
            },
        },
        series: funnelSeries,
    }
    const overlapStyle = {
        position: 'absolute',
        top: 0,
        left: 25,
        width: 'calc(100% - 50px)',
    }
    return (
        <div className='relative'>
            <ReactApexChart
                options={areaChartData.options}
                series={areaChartData.series}
                type='area'
                height={300}
                style={overlapStyle}
            />
            <ReactApexChart options={funnelChartData.options} series={funnelChartData.series} type='bar' height={300} />
            <div className={styles.legendContainer}>{legendItems}</div>
        </div>
    )
}

export default PaymentPageFunnel
