import { convertIsoToLocaleString, filterDateFormatter, makeAPICall, updateBreadcrumbs, validatePermission } from '@gokwik/utilities'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Table, Button, Row, Tag, Col, Tooltip, ColumnProps, message, Modal, EditOutlined, FieldTimeOutlined, SearchOutlined, RedoOutlined, DeliveredProcedureOutlined, InfoCircleOutlined   } from 'gokwik-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { previousSettlementsFilters } from '@store/filters/selectors'
import { saveFiltersData } from '@store/filters'
import RenderSearchFilters from '@library/components/search-filter'
import SettlementInitiate from './initiateSettlement'
import { isMerchantSelector } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { internalUserColumns, merchantUserColumns } from './columnsData'
import { handleError } from '@library/utilities/helpers/handleError'
import EditStatusDrawer from '@library/components/editStatusDrawer'
const today = dayjs()
const formattedDate = filterDateFormatter(today)

export default function () {
    const [total, setTotal] = useState(0)
    const [settlementData, setSettlementData] = useState([])
    const prevFilters = useSelector(previousSettlementsFilters)
    const [openDrawer, setOpenDrawer] = useState(false)
    const isMerchantUser = useSelector(isMerchantSelector)
    const [editOpen, setEditOpen] = useState<any>()
    const [modalOpen, setModalOpen] = useState<any>()
    const [parameters, setParameters] = useState({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        start_dt: formattedDate,
        end_dt: formattedDate,
        ...prevFilters,
    })
    const [settlementInitiate, setSettlementInitiate] = useState({
        payment_id: '',
        settlementDay: '',
        paymentProvider: '',
        payoutType: '',
        start_dt: '',
        end_dt: '',
    })
    const dispatch = useDispatch()
    const handleDrawer = () => {
        setOpenDrawer(true)
    }

    async function makeSettlementsApiCall() {
        if (!parameters.page) return
        try {
            // let response = dummyOrders?.data;
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getSettlements,
                params: {
                    ...parameters,
                    start_dt: parameters.start_dt,
                    end_dt: parameters.end_dt,
                },
            })

            if (response.data?.data?.settlements?.length > 0) {
                setSettlementData(response.data?.data?.settlements)
                setTotal(response.data?.data?.totalCount)
            } else {
                setSettlementData([])
                setTotal(0)
            }
            dispatch<any>(saveFiltersData('settlements', { ...parameters }))
        } catch (error) {
            handleError(error)
        }
    }

    const reset = (e) => {
        e.preventDefault()
        setParameters({
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            start_dt: formattedDate,
            end_dt: formattedDate,
        })
        setTotal(0)
        dispatch<any>(saveFiltersData('settlements', {}))
    }

    const handleSearchClick = (e) => {
        e?.preventDefault()
        makeSettlementsApiCall()
        }
    useEffect(() => {
        if (parameters.page >= 1) {
            makeSettlementsApiCall()
        }
    }, [parameters.page])

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        // setPage(current)
        // setPageSize(pageSize)
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
    }
    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters))
            setParameters({ ...prevFilters })
    }, [prevFilters])

    const handleCalendarChange = (dates, dateStrings) => {
        const startDate = dayjs(dateStrings[0])
        const endDate = dayjs(dateStrings[1])
        const diffInDays = endDate.diff(startDate, 'day')

        if (diffInDays > 6) {
            message.warning('Maximum 7 days range is allowed')
            setSettlementInitiate((prev) => ({ ...prev, start_dt: '', end_dt: '' }))
        } else {
            return
        }
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settlements',
                href: '/payment/settlements',
                text: 'Settlements',
            },
        ])
    }, [])

    const resetSettlementInititiate = () => {
        setSettlementInitiate({
            payment_id: '',
            settlementDay: '',
            paymentProvider: '',
            payoutType: '',
            start_dt: '',
            end_dt: '',
        })
    }
    const setDate = (dateToChange) => {
        const date1 = new Date(dateToChange);
        const originalDateStr = date1.toISOString().replace('T', ' ').substring(0, 19);
        const originalDate = new Date(originalDateStr.replace(' ', 'T') + 'Z');
        const adjustedDate = new Date("2024-05-27T00:00:00Z");

        const formattedDate = adjustedDate.toISOString();
        return formattedDate;
    }
    const onSettlementInitiateSubmit = async () => {
        if (!settlementInitiate.settlementDay) {
            message.error('Settlement day is required')
            return
        }
        if (!settlementInitiate.paymentProvider) {
            message.error('Payment Provider is required')
            return
        }
        const params = {
            paymentProvider: settlementInitiate.paymentProvider,
            payoutType: settlementInitiate.payoutType,
            settlementDay: settlementInitiate.settlementDay,
            timeFrame: {
                startDate: setDate(settlementInitiate.start_dt), 
                endDate: setDate(settlementInitiate.end_dt)
            },
        }
        const payload = {
            paymentProvider: settlementInitiate.paymentProvider,
            payoutType: settlementInitiate.payoutType,
            settlementDay: parseInt(settlementInitiate.settlementDay),
            timeFrame: {
                startDate: setDate(settlementInitiate.start_dt), 
                endDate: setDate(settlementInitiate.end_dt)
            },
        }

        try 
        {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.initiateSettlement,
                params: params,
                payload: payload,
            })
           if(response.status === 200)
           {
            if(!response?.data?.data)
            {message.warning(response?.data?.message)}
            else message.success(response?.data?.message)
            setOpenDrawer(false)
            resetSettlementInititiate()
           } 
           else {
            message.error(response?.response?.data?.message || 'Insufficient Information')
            setOpenDrawer(false)
            resetSettlementInititiate()
           }  
        } 
        catch (err) {
            console.warn(err)
        }
    }

    const onUpdateStatus = async (data) => {
        if(!data?.npc_id)
        {
            message.error("NPC ID is not present");
            return;
        }
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.settlementStatus,
            payload: {
                paymentId: data.payment_id,
                ...(data.npc_id && { npcId: data.npc_id }),
              },
        })
        if (response?.data?.status_code === 200) {
            message.success(response.data.message)
        } else {
            message.error(response?.response?.data.message)
        }
    }

    const openEdit = (id, data) => {
        setEditOpen(data)
    }

    return (
        <div className='w-full h-full'>
            {!isMerchantUser && (
                <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                    <Col sm={12} className='flex items-center gap-x-2'>
                        <Row className='text-black inter m-0 tracking-wide items-center'>
                            <Tooltip
                                title={
                                    'Settlement is the process through which a merchant receives money paid by their end users for a particular product/service. There are a number of entities involved in the settlement process.'
                                }
                            >
                                <InfoCircleOutlined className='text-blue fs20' />
                            </Tooltip>

                            <span className='ml-2'>Initiate fast payment specific settlements here</span>
                        </Row>
                    </Col>
                    <Col className='flex justify-end' sm={2}>
                        <Button className='bg-primary-200' onClick={handleDrawer} variant='primary'>
                            <DeliveredProcedureOutlined />
                            Initiate Settlement
                        </Button>
                    </Col>
                </Row>
            )}

            <Row className='bg-white rounded mt-5'>
                <RenderSearchFilters
                    values={parameters}
                    setValues={(data, reset) => setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))}
                    page='settlements'
                    onSearch={handleSearchClick}
                    onReset={reset}
                >
                    <>
                        <Col className='flex items-center gap-x-3'>
                            <Button onClick={handleSearchClick} variant='primary'>
                                <SearchOutlined />
                                Search
                            </Button>
                            <Button onClick={reset} variant='default'>
                                <RedoOutlined />
                                Reset
                            </Button>
                            {/* <Button className='bg-primary-200' onClick={handleDrawer} variant='primary'>
                                <DeliveredProcedureOutlined />
                                Initiate Settlement
                            </Button> */}
                        </Col>
                    </>
                </RenderSearchFilters>
            </Row>

            <div className='table-section mt-5 bg-white rounded overflow-clip'>
                <Table
                    columns={isMerchantUser ? merchantUserColumns : [
                        ...internalUserColumns,
                        {
                            title: <span>Actions</span>,
                            dataIndex: 'id',
                            fixed: 'right',
                            width: 100,
                            render: (id, record) => {
                                return (
                                    <Row className={`m-0`}>
                                        { 
                                        ['pending', 'timeout', 'payment_initiated'].includes(
                                            record?.bank_status?.toLowerCase(),
                                        ) && (
                                            <> {validatePermission('update_status_settlements') && (
                                                <Tooltip title='Update Settlement'>
                                                    <FieldTimeOutlined
                                                        onClick={() => {
                                                            setModalOpen(record)
                                                        }}
                                                        style={{ color: 'green' }}
                                                    />
                                                </Tooltip>
                                                )}
                                                {validatePermission('edit_settlements') && (
                                                <Tooltip title='Edit Settlement'>
                                                    <EditOutlined
                                                        onClick={() => openEdit(id, record)}
                                                        style={{ color: 'red' }}
                                                        className='ml-4'
                                                    />
                                                </Tooltip>
                                                )}
                                            </>
                                        )}
                                    </Row>
                                )
                            },
                        },
                    ]}
                    dataSource={settlementData || []}
                    pagination={{
                        current: parameters.page, // Current page number
                        pageSize: parameters.pageSize, // Number of items to display per page
                        total: total, // Total number of items in the data array
                        showSizeChanger: false,
                        onChange: handlePaginationChange,

                        position: ['topLeft', 'bottomLeft'],
                        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                    }}
                    scroll={{ x: 3500 }}
                    // scroll={{ x: 'max-content' }}
                />
            </div>
            {!isMerchantUser && (
                <SettlementInitiate
                    setSettlementInitiate={setSettlementInitiate}
                    onSettlementInitiateSubmit={onSettlementInitiateSubmit}
                    settlementInitiate={settlementInitiate}
                    setOpenDrawer={setOpenDrawer}
                    handleCalendarChange={handleCalendarChange}
                    openDrawer={openDrawer}
                    resetSettlementInititiate={resetSettlementInititiate}
                />
            )}
            {editOpen && (
                <EditStatusDrawer
                    title='Update Settlements'
                    heading={
                        <>
                            <h5 className='fs14'>
                                Settlement ID:{' '}
                                <span className='text-blue'>
                                    {' '}
                                    <Tooltip>{editOpen?.settlement_id} </Tooltip>
                                </span>
                            </h5>
                        </>
                    }
                    open={!!editOpen}
                    onClose={(refetch: boolean) => {
                        refetch && makeSettlementsApiCall()
                        setEditOpen(false)
                    }}
                    recordData={editOpen}
                    type='settlement'
                />
            )}
            <Modal
                title={<p className='fs14'>Are your sure, you want to update the status ?</p>}
                open={modalOpen ? true : false}
                onOk={() => {
                    onUpdateStatus(modalOpen)
                    setModalOpen(null)
                }}
                onCancel={() => {
                    setModalOpen(null)
                }}
                footer={() => (
                    <Row className='flex justify-end gap-2'>
                        <Button onClick={() => setModalOpen(null)} variant='default'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                onUpdateStatus(modalOpen)
                                setModalOpen(null)
                            }}
                            variant='primary'
                        >
                            Confirm
                        </Button>
                    </Row>
                )}
                centered
                width={'400px'}
            >
                <h3 className='mt-1'>
                    Settlement Id <span className='text-blue'>{modalOpen?.settlement_id}</span>{' '}
                </h3>
                <h3 className='mt-1'>
                    Payment ID <span className='text-blue'>{modalOpen?.payment_id}</span>{' '}
                </h3>
            </Modal>
        </div>
    )
}
