import React from 'react'
import { Tabs } from 'gokwik-ui-kit'
import Transactions from './transactions'
import DangledTransactions from './dangledTransactions'
import { useSelector } from 'react-redux'
import { isMerchantSelector } from '@store/user/selectors'

const items = [
    {
        key: '1',
        label: 'Transactions',
        children: <Transactions />,
    },
    {
        key: '2',
        label: 'Dangled Transactions',
        children: <DangledTransactions />,
    },
]

export default () => {
    const isMerchantUser = useSelector(isMerchantSelector)
    if (isMerchantUser) return <Transactions />
    return (
        <Tabs
            tabBarStyle={{
                paddingLeft: '1rem',
                background: 'white',
                maxWidth: '100%',
                margin: 0,
                borderTopLeftRadius: '0.25rem',
                borderTopRightRadius: '0.25rem',
            }}
            className=' w-[100%]'
            items={items}
        />
    )
}
