import { type Tag } from 'gokwik-ui-kit'
import { createContext, type PropsWithoutRef } from 'react'

export type TagProp = PropsWithoutRef<typeof Tag>['defaultProps']

export enum OfferStatus {
    DRAFT = 'draft',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    EXPIRED = 'expired',
    PUBLISHED = 'published',
    PAUSED = 'paused',
    AVAILABLE = 'available',
}

export enum OfferPageParent {
    PAYMENT = 'payment',
    GLOBAL = 'global',
    OTHER = 'other',
}

export interface OfferListing {
    id: string
    coupon_code: string
    created_at: string
    end_date: string
    offer_category: string
    start_date: string
    status: string
    show_status?: OfferStatus
    title: string
    valid_payment_methods: string[]
    is_global_offer?: boolean
}

export interface MerchantOfferListing {
    id: string
    offer: Offer
    merchant_offer_config: MerchantOfferConfig
    show_status: string
}

export interface Offer {
    id: string
    title: string
    start_date: string
    end_date: string
    coupon_code: string
    status: string
    view_in_listing: boolean
    created_at: string
    valid_payment_methods: string[]
    offer_category: string
    is_global_offer: boolean
}

export interface MerchantOfferConfig {
    id: string
    is_enabled: boolean
    view_in_listing: boolean
    merchant_id: string
    offer_id: string
    is_deleted: boolean
}

export enum OfferMerchantActions {
    ADD_MERCHANT = 'addMerchants',
    REMOVE_MERCHANT = 'removeMerchants',
}

export interface OfferDetails {
    id: string
    title: string
    description: string
    start_date: string
    end_date: string
    tnc?: string
    coupon_code: string
    valid_for_all_customers: boolean
    valid_for_all_merchants: boolean
    merchant_config_details: Omit<MerchantOfferListing, 'show_status'>[]
    coupon_per_customer: number
    max_coupon_count: number
    can_clubbed_with_other_offer: boolean
    coupon_based_offer: boolean
    auto_apply: boolean
    country: string
    status: string
    view_in_listing: boolean
    override_badge_text: boolean
    created_at: string
    updated_at: string
    valid_payment_methods: string[]
    valid_bank_codes: string[] | null
    offer_category: string
    offer_partner: string
    rule_id: string
    discount_details?: DiscountDetails
    created_by: string
    stackability: boolean
}

export interface DiscountDetails {
    discount_worth: string
    max_discount?: string
}

export const OfferContext = createContext({
    merchantList: [],
    parent: null,
    viewOffer: (_id: number) => null,
    addRemoveMerchant: (_id: number) => null,
    updateStatus: (_checked: boolean, _id: string, _success?: CallableFunction, _err?: CallableFunction) => null,
})

export const formatPaymentMethod = (method) => {
    let result = method.replace('cc', 'Credit Card').replace('dc', 'Debit Card').replace('upi', 'Prepaid')
    if (result.includes('cod')) result = result.toUpperCase()
    else if (result.includes('-'))
        result = result
            .split('-')
            .map((str) => str.slice(0, 1).toUpperCase() + str.slice(1))
            .join(' ')
    else result = result.slice(0, 1).toUpperCase() + result.slice(1)
    return result
}
