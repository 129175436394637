import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useMerchantConfigApi } from '@library/utilities/hooks'
import { Button, Col, Row, Select, message } from 'gokwik-ui-kit'
import { useCallback, useEffect, useState } from 'react'

const formatPaymentMethod = (val) => {
    let result = val.replace('cc', 'Credit Card').replace('dc', 'Debit Card').replace('upi', 'Prepaid')
    if (result.includes('cod')) result = result.toUpperCase()
    else if (result.includes('-'))
        result = result
            .split('-')
            .map((str) => str.slice(0, 1).toUpperCase() + str.slice(1))
            .join(' ')
    else result = result.slice(0, 1).toUpperCase() + result.slice(1)
    return result
}

const PaymentMethodsConfig = () => {
    const { config, loading, refetch } = useMerchantConfigApi({ useCache: true })

    const [paymentProviders, setPaymentProviders] = useState([])
    const [updatedConfig, setUpdatedConfig] = useState({ payment_methods: [] })

    useEffect(() => {
        if (config) {
            setUpdatedConfig(config)
        }
    }, [config])

    const fetchPaymentProviders = useCallback(async () => {
        const response = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.paymentProviders,
            method: 'get',
        })

        if (response.success) {
            setPaymentProviders([
                'cod',
                'ppcod-upi',
                ...(response.data.data || []).map((method) => method.paymentMethod),
            ])
        }
    }, [])
    const saveConfig = useCallback(async (payload) => {
        const response = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.paymentMethodChange,
            method: 'post',
            payload,
        })
        if (response.success) {
            message.success(response.data.data)
        } else {
            message.error(response.data.message || 'Unable to save payment settings settings')
        }
    }, [])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/payment/settings',
                text: 'Payment Settings',
            },
            {
                key: 'configs',
                href: '/payment/settings/configs',
                text: 'Configs'
            },
        ])
        fetchPaymentProviders()
    }, [])

    return (
        <Row gutter={[16, 24]} className='bg-white py-2'>
            <Col span={24}>
                {!!paymentProviders.length && (
                    <Select
                        mode='multiple'
                        label='Payment Methods'
                        value={updatedConfig?.payment_methods || []}
                        onChange={(value) => setUpdatedConfig({ payment_methods: value })}
                        options={paymentProviders.map((method) => ({
                            label: formatPaymentMethod(method),
                            value: method,
                        }))}
                    />
                )}
            </Col>
            <Col>
                <Button variant='primary' onClick={() => saveConfig(updatedConfig)}>
                    Request Activation
                </Button>
            </Col>
        </Row>
    )
}
export default PaymentMethodsConfig
