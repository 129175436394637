import { createAsyncThunk, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, RootStoreType } from '../index'
import { analyticsAPIs, fetchAnalyticsData } from './api'
import dayjs, { Dayjs } from 'dayjs'

export interface AnalyticsSlice {
    [key: string]: {
        [key: string]: any
        filterValues?: { [key: string]: string }
        status?: 'idle' | 'loading' | 'failed'
    }
}

const assignToState = ({
    state,
    key,
    nestedKeys,
    payload,
    status,
}: {
    state: AnalyticsSlice
    status: 'idle' | 'loading' | 'failed'
    key: string
    nestedKeys: string[]
    payload: any
}) => {
    if (nestedKeys && nestedKeys.length > 0) {
        nestedKeys.forEach((nestedKey) => {
            state[key].status = status
            if (payload && payload.data) state[key][nestedKey] = payload.data[nestedKey]
        })
    } else {
        state[key].status = status
        state[key].data = payload.data
    }
    state[key].filterValues = payload.filterValues
    return state
}

const initialState: AnalyticsSlice = {
    summary: {
        data: null,
        status: 'idle',
    },
    paymentFunnel: {
        data: null,
        status: 'idle',
    },
    ratesGraph: {
        data: null,
        status: 'idle',
    },
    distributionGraph: {
        data: null,
        status: 'idle',
    },
    distributionTable: {
        data: null,
        status: 'idle',
    },
    dropOffGraph: {
        data: null,
        status: 'idle',
    },
    failureReasons: {
        data: null,
        status: 'idle',
    },
}

export const fetchAnalyticsDataAsync = createAsyncThunk(
    'paymentAnalytics/fetchAnalytics',
    async (
        { key, params }: { key: string; nestedKey?: string; params?: { [key: string]: any } },
        { rejectWithValue },
    ) => {
        const response = await fetchAnalyticsData({ key, params })
        if (response.success) return response
        else return rejectWithValue(response)
    },
)

export const fetchAllAnalyticsData =
    ({ params, filterValues
}: { params: { start_date: string; end_date: string; payment_method?: string; }, filterValues: { [key: string]: { [key: string]: string } } }) =>
    async (dispatch: AppDispatch) => {
        Object.keys(analyticsAPIs).forEach((key) => {
            const existingParams = filterValues[key];
            const tempParams: { start_date: string; end_date: string; payment_method?: string;  resolution?: string;} = {
                ...existingParams,
                ...params
            }
            if (key === 'ratesGraph' || key === 'distributionGraph' || key === 'paymentFunnel' || key === 'dropOffGraph') {
                if (dayjs(params.end_date).diff(params.start_date, 'days') < 3) {
                    tempParams.resolution = 'h'
                } else if (dayjs(params.end_date).diff(params.start_date, 'days') < 31) {
                    tempParams.resolution = 'd'
                } else {
                    tempParams.resolution = 'w'
                }
            }
            dispatch(fetchAnalyticsDataAsync({ key, params: tempParams }))
        })
    }

export const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAnalyticsDataAsync.pending, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : analyticsAPIs[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: {}, status: 'loading' })
            })
            .addCase(fetchAnalyticsDataAsync.fulfilled, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : analyticsAPIs[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: action.payload, status: 'idle' })
            })
            .addCase(fetchAnalyticsDataAsync.rejected, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : analyticsAPIs[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: {}, status: 'failed' })
            })
    },
})

export default analyticsSlice.reducer
