import { AnalyticsCard, AnalyticsDateRangePicker, Col, Row, Select, SummaryBlocks, Tag } from 'gokwik-ui-kit'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Charts, SummaryBlocksData } from './chartsData'
import { useAppDispatch } from '@library/utilities/hooks'
import { fetchAllAnalyticsData, fetchAnalyticsDataAsync } from '@store/analytics'
import { useSelector } from 'react-redux'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { getAnalyticsData } from '@store/analytics/selectors'
import Chart from './chart'
import { getMerchantConfig } from '@store/user/selectors'
import { MerchantConfigKeys } from '@library/utilities/constants/constants'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
const today = dayjs()
const yesterday = today.subtract(1, 'day')

const paymentMethods = {
    all: 'Payment Method: All',
    upi: 'Payment Method: UPI',
    card: 'Payment Method: Card',
    bnpl: 'Payment Method: BNPL',
    ppcod: 'Payment Method: PPCOD',
    snapmint: 'Payment Method: Snapmint',
    wallet: 'Payment Method: Wallet',
    credpay: 'Payment Method: Credpay',
    netbanking: 'Payment Method: Netbanking',
    bharatxpay: 'Payment Method: Bharatxpay',
}

const sources = {
    all: 'Source: All',
    web: 'Source: Web',
    app: 'Source: App',
}

const filtersClassNames = {
    rate_type: 'w-48',
    resolution: 'w-28',
    split_by: 'w-40',
    view_mode: 'w-32'
}

const format = 'YYYY-MM-DD'
const PaymentAnalytics = () => {
    const paymentAnalyticsConfig: string[] =
        useSelector(getMerchantConfig(MerchantConfigKeys.paymentAnalytics.selectedCharts)) || []
    const [dates, setDates] = useState<{ from: Dayjs; to: Dayjs; label?: string }>({
        from: yesterday,
        to: yesterday,
        label: yesterday.format('DD MMM YYYY'),
    })
    const [paymentMethod, setPaymentMethod] = useState('all')
    const [source, setSource] = useState('all')
    const analyticsData = useSelector(getAnalyticsData)

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'payment-analytics',
                href: '/payment/analytics',
                text: 'Payment Analytics',
            },
        ])
    }, [])
    const dispatch = useAppDispatch()
    useEffect(() => {
        let chartFilterValues
        Charts.filter((chart) => paymentAnalyticsConfig.includes(chart.configKey)).map((chart) => {
            const data = analyticsData[chart.dataKey]
            const filterValues = data.filterValues || {}
            chartFilterValues = {
                ...chartFilterValues,
                [chart.dataKey]: { ...filterValues },
            }
        })
        dispatch(
            fetchAllAnalyticsData({
                params: {
                    start_date: dates.from.format(format),
                    end_date: dates.to.format(format),
                    payment_method: paymentMethod,
                    source: source
                },
                filterValues: chartFilterValues,
            }),
        )
    }, [dates, paymentMethod, source])

    const blocksData = useMemo(() => {
        return SummaryBlocksData.map((block) => {
            const data = analyticsData[block.dataKey]
            const heroValue = block.nestedKey
                ? analyticsData[block.dataKey]?.[block.nestedKey]
                : analyticsData[block.dataKey]
            return {
                title: block.heading,
                tooltipText: block.tooltipText,
                heroValue,
                heroValueTransform: block.heroValueTransform,
                loading: data.status === 'loading',
            }
        })
    }, [analyticsData])

    const formatLastUpdatedData = (datetimestamp) => {
        const date = dayjs(datetimestamp).utc()
        const formattedDate = date.format('DD MMM YYYY [at] hh:mm A')
        return formattedDate
    }

    return (
        <div className='w-full'>
            <Row className='payment-analytics h-max pb-4' gutter={[16, 24]}>
                <Col span={10} className='flex items-end'>
                    <Tag variant='default' className='!bg-white !border !border-solid !border-[#d9d9d9] !text-black'>
                        {' '}
                        Last updated:&nbsp;{' '}
                        <span>{formatLastUpdatedData(analyticsData?.distributionGraph?.data?.last_updated_at)}</span>
                    </Tag>
                </Col>
                <Col className='h-max' span={14}>
                    <Row justify={'end'} gutter={8}>
                        <Col span={8}>
                            <Select
                                optionRender={(option) => option.label.toString().split(':')[1]}
                                className='w-full'
                                value={paymentMethod}
                                options={Object.entries(paymentMethods).map(([value, label]) => ({
                                    label,
                                    value,
                                }))}
                                onChange={(value) => setPaymentMethod(value)}
                            />
                        </Col>
                        <Col span={6}>
                            <Select
                                optionRender={(option) => option.label.toString().split(':')[1]}
                                className='w-full'
                                value={source}
                                options={Object.entries(sources).map(([value, label]) => ({
                                    label,
                                    value,
                                }))}
                                onChange={(value) => setSource(value)}
                            />
                        </Col>
                        <Col>
                            <AnalyticsDateRangePicker
                                values={[dates.from, dates.to]}
                                onApply={(values, label) => setDates({ from: values[0], to: values[1], label })}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <SummaryBlocks data={blocksData} />
                </Col>
                {Charts.filter((chart) => paymentAnalyticsConfig.includes(chart.configKey)).map((chart) => {
                    const data = analyticsData[chart.dataKey]
                    const filterValues = data.filterValues || {}
                    const filters = useMemo(() => {
                        const data = chart.dynamicFilters?.(dates, filterValues) || []
                        return data.map((filter) => ({ ...filter, className: filtersClassNames[filter.key] }))
                    }, [dates, filterValues])
                    return (
                        <Col key={chart.dataKey} span={12}>
                            <AnalyticsCard
                                title={chart.dynamicHeading?.(filterValues) || chart.heading}
                                titleTooltip={chart.dynamicTooltipText?.(filterValues) || chart.tooltipText}
                                lastUpdatedAt={dayjs.utc(data.data?.last_updated_at)}
                                noData={!data.data?.last_updated_at}
                                loading={data.status === 'loading'}
                                filters={filters}
                                filterValues={filterValues}
                                filterOnChange={(key, value) => {
                                    dispatch(
                                        fetchAnalyticsDataAsync({
                                            key: chart.dataKey,
                                            params: {
                                                ...filterValues,
                                                [key]: value,
                                                start_date: dates.from.format(format),
                                                end_date: dates.to.format(format),
                                                payment_method: paymentMethod,
                                                source: source,
                                            },
                                        }),
                                    )
                                    // setFilterValues((prev) => ({ ...prev, [key]: value }))
                                }}
                            >
                                {chart.component ? (
                                    <chart.component
                                        data={data.data}
                                        dates={dates}
                                        {...filterValues}
                                        paymentMethod={paymentMethods[paymentMethod].split(': ')[1]}
                                    />
                                ) : (
                                    <Chart
                                        {...chart.chartProps}
                                        {...filterValues}
                                        wrapperClassName='min-h-[315px] h-full'
                                        dates={dates}
                                        data={data.data?.stats}
                                        seriesOptions={
                                            chart.dynamicSeriesOptions
                                                ? chart.dynamicSeriesOptions({
                                                      payment_method: paymentMethods[paymentMethod].split(': ')[1],
                                                  })
                                                : chart.seriesOptions
                                        }
                                    />
                                )}
                            </AnalyticsCard>
                        </Col>
                    )
                })}
            </Row>
        </div>
    )
}

export default PaymentAnalytics
