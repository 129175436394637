import { type ColumnProps, Drawer, Tag } from 'gokwik-ui-kit'
import { getProviderName, getTagVariant } from './settlements.service'
import { type SettlementProviderSummary } from './types'
import { Fragment } from 'react/jsx-runtime'
import { formatAmount } from '@library/utilities/helpers/helper'
import { getMerchantDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'

export default function ProviderSummary({ summary, setOpen }: { summary: SettlementProviderSummary; setOpen: (open) => void }) {
    const merchantData = useSelector(getMerchantDetails)
    const dataRows: ColumnProps<any>[] = [
        {
            title: 'Transactions',
            dataIndex: 'total_ingested_transactions',
        },
        {
            title: 'Gross Amount',
            dataIndex: 'gross_amount',
            render: (val) => <span className='text-green-500'>{formatAmount(val)}</span>,
        },
        {
            title: 'Refunded Amount',
            dataIndex: 'refunded_amount',
            render: (val) => <span className='text-red-500'>{formatAmount(val)}</span>,
        },
        {
            title: 'Tax',
            dataIndex: 'tax',
            render: (val) => <span className='text-red-500'>{formatAmount(val)}</span>,
        },
        {
            title: 'Fee',
            dataIndex: 'fee',
            render: (val) => <span className='text-red-500'>{formatAmount(val)}</span>,
        },
        {
            title: 'Other Fee',
            dataIndex: 'other_fee',
            render: (val) => <span className='text-red-500'>{formatAmount(val)}</span>,
        },
        {
            title: 'Split Payouts',
            dataIndex: 'split_payouts',
            render: (val) => <span className='text-red-500'>{val ? formatAmount(val) : '-'}</span>,
        },
        {
            title: 'Chargebacks',
            dataIndex: 'chargebacks',
            render: (val) => <span className='text-red-500'>{val ? formatAmount(val) : '-'}</span>,
        },
        {
            title: 'Net Settled Amount',
            dataIndex: 'total_net_settled_amount',
            render: (val) => <span>{val ? formatAmount(val) : '-'}</span>,
        },
        ...(merchantData?.payments_settlement_dashboard_config?.show_recon_status === true ? [{
            title: 'Recon Status',
            dataIndex: 'recon_percentage',
            render: (val) => <Tag variant={getTagVariant(val)}>{val}% match</Tag>,
        }] : []),
    ]
    return (
        <>
            <Drawer
                title={<span className='capitalize'>{getProviderName(summary?.payment_provider)} summary</span>}
                placement='right'
                onClose={() => {
                    setOpen(null)
                }}
                open={!!summary}
            >
                {!!summary && (
                    <div className='flex flex-wrap items-center gap-y-8'>
                        {dataRows.map((row, i) => (
                            <Fragment key={i}>
                                <div className='w-1/2'>
                                    {typeof row.title === 'function' ? row.title({}) : row.title}
                                </div>
                                <div className='w-1/2 text-right'>
                                    {row.render
                                        ? row.render(summary?.[row.dataIndex], summary, 0)
                                        : summary?.[row.dataIndex]}
                                </div>
                            </Fragment>
                        ))}
                    </div>
                )}
            </Drawer>
        </>
    )
}
