import { validatePermission } from '@gokwik/utilities'
import { MerchantConfigKeys, PermissionValues } from '@library/utilities/constants/constants'
import PaymentAnalytics from '@pages/analytics'
import Chargeback from '@pages/chargebacks/chargebacks'
import Collections from '@pages/collections'
import CollectionDetails from '@pages/collections/collection-details'
import PaymentLinks from '@pages/payment-links'
import PaymentLinkDetails from '@pages/payment-links/link-details'
import Refund from '@pages/refund'
import NotFound from '@pages/notFound'
import Settings from '@pages/settings'
import Settlements from '@pages/settlement/settlement'
import Transactions from '@pages/transactions'
import {
    getMerchantConfigs,
    getMerchantDetails,
    getMode,
    getUserDetails,
    isMerchantSelector,
} from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

const AppRoute = () => {
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const mode = useSelector(getMode)
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantConfigs = useSelector(getMerchantConfigs)

    if (!Object.keys(userDetails || {}).length) return <></>

    return (
        <Routes key={`${mode}-${JSON.stringify(merchantDetails)}`}>
            <Route
                path='/payment/refunds'
                element={
                    validatePermission(PermissionValues.refunds.view) ? <Refund /> : <NotFound hasPermission={false} />
                }
            />
            <Route
                path='/payment/transactions'
                element={
                    validatePermission(PermissionValues.transactions.view) ? (
                        <Transactions />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />

            <Route
                path='/payment/analytics'
                element={
                    validatePermission(PermissionValues.paymentAnalytics.view) &&
                    (!isMerchantUser || merchantConfigs[MerchantConfigKeys.paymentAnalytics.view]) ? (
                        <PaymentAnalytics />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />

            <Route
                path='/payment/payment-links'
                element={
                    validatePermission(PermissionValues.paymentLinks.view) ? (
                        <PaymentLinks />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />
            <Route
                path='/payment/payment-links/:id'
                element={
                    validatePermission(PermissionValues.paymentLinks.view) ? (
                        <PaymentLinkDetails />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />

            <Route
                path='/payment/settlements'
                element={
                    validatePermission(PermissionValues.settlements.view) ? (
                        <Settlements />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />
            <Route path='/payment/collections' Component={Collections} />
            <Route path='/payment/collections/:id' Component={CollectionDetails} />
            <Route path='/payment/settings/*' Component={Settings} />

            <Route
                path='/payment/chargebacks'
                element={!isMerchantUser ? <Chargeback /> : <NotFound hasPermission={false} />}
            />
            <Route path='/payment/*' element={<NotFound />} />
        </Routes>
    )
}

export default AppRoute
