import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

export const analyticsAPIs: {
    [key: string]: {
        url: string
        errMsg: string
        nestedKeys?: string[]
        defaultParams?: { [key: string]: any }
        formatData?: (data: any) => any
        isVd?: boolean
    }
} = {
    summary: {
        url: APIEndPoints.paymentAnalytics.summary,
        errMsg: 'Unable to fetch payment summary',
        nestedKeys: [
            'prepaid_percentage',
            'prepaid_orders',
            'prepaid_gmv',
            'success_rate',
            'total_orders',
            'total_gmv',
        ],
        defaultParams: {
            payment_method: 'all',
        },
    },
    paymentFunnel: {
        url: APIEndPoints.paymentAnalytics.paymentFunnel,
        errMsg: 'Unable to fetch payment funnel',
        defaultParams: {
            payment_method: 'all',
            view_mode: 'funnel',
            resolution: 'h'
        },
        formatData: (data: any) => ((data?.funnel_steps && !data?.funnel_steps?.payment_page_landed) ? null : data),
    },
    ratesGraph: {
        url: APIEndPoints.paymentAnalytics.ratesGraph,
        errMsg: 'Unable to fetch Rate Data',
        defaultParams: {
            payment_method: 'all',
            resolution: 'h',
            rate_type: 'success',
        },
    },
    distributionGraph: {
        url: APIEndPoints.paymentAnalytics.distributionGraph,
        errMsg: 'Unable to fetch Methodwise Distribution',
        defaultParams: {
            payment_method: 'all',
            resolution: 'h',
            split_by: 'orders',
        },
    },
    distributionTable: {
        url: APIEndPoints.paymentAnalytics.distributionTable,
        errMsg: 'Unable to fetch Methodwise Distribution',
        defaultParams: {
            payment_method: 'all',
        },
    },
    dropOffGraph: {
        url: APIEndPoints.paymentAnalytics.dropOffGraph,
        errMsg: 'Unable to fetch Drop off Data',
        defaultParams: {
            payment_method: 'all',
            resolution: 'h',
        },
    },
    failureReasons: {
        url: APIEndPoints.paymentAnalytics.failureReasons,
        errMsg: 'Unable to fetch Failure Distribution',
        defaultParams: {
            payment_method: 'all',
        },
    },
}

export const fetchAnalyticsData = async ({
    key,
    params,
    nestedKey,
}: {
    key: string
    nestedKey?: string
    params?: { [key: string]: any }
}) => {
    const { url, nestedKeys, defaultParams, formatData } = analyticsAPIs[key]
    const response = await makeAPICall({
        method: 'get',
        url: process.env.REACT_APP_BASE_URL + url,
        params: {
            ...(defaultParams || {}),
            ...(params || {}),
        },
        skipLoader: true,
        skipMode: true,
    })
    if (response.success) {
        response.data.data = nestedKeys
            ? nestedKeys.reduce((result, curKey) => ({ ...result, [curKey]: response.data?.data?.[curKey] }), {})
            : response.data.data
        const resolution = response.data.data.resolution || defaultParams.resolution || params.resolution;
        return {
            success: true,
            data: formatData && response.data.data ? formatData(response.data.data) : response.data.data,
            nestedKeys,
            filterValues: {
                ...(defaultParams || {}),
                ...(params || {}),
                resolution: resolution,
            },
        }
    }
    return { error: true, nestedKeys }
}
