import { Table, Button, Row, Tooltip, message, Modal, SummaryBlocks } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { EditOutlined, FieldTimeOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { filterDateFormatter, makeAPICall, updateBreadcrumbs, validatePermission } from '@gokwik/utilities'
import { useDispatch, useSelector } from 'react-redux'
import { saveFiltersData } from '@store/filters'
import { previousTransactionsFilters } from '@store/filters/selectors'
import RenderSearchFilters from '@library/components/search-filter'
import { isMerchantSelector,getMerchantDetails, getUserDetails} from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import EditStatusDrawer from '@library/components/editStatusDrawer'
import { transactionColumnsInternal, transactionColumnsMerchant } from './columnsData'
import { handleError } from '@library/utilities/helpers/handleError'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'

const today = dayjs()
const formattedDate = filterDateFormatter(today)

export default function () {
    const [total, setTotal] = useState(0)
    const [summaryData, setSummaryData] = useState({
        hop3sr: 0,
        prepaidGMV: 0,
        successfulTxns: 0,
    })
    const [transactions, setTransactions] = useState([])
    const [editOpen, setEditOpen] = useState<any>()
    const [modalOpen, setModalOpen] = useState<any>()
    const dispatch = useDispatch()
    const prevFilters = useSelector(previousTransactionsFilters)
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantDetails = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const [parameters, setParameters] = useState({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        start_dt: formattedDate,
        end_dt: formattedDate,
        // merchantId: null,
        ...prevFilters,
    })

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'transactions',
                href: '/payment/transactions',
                text: 'Transactions',
            },
        ])
    }, [])

    const reset = (e) => {
        e.preventDefault()
        const newParameters = {
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            start_dt: formattedDate,
            end_dt: formattedDate,
        }
        setParameters(newParameters)
        makeTransactionsApiCall(newParameters)
    }

    async function makeTransactionsApiCall(parameters) {
        try {
            // let response = dummyTransactions?.data;
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getTransactions,
                params: parameters,
            })
            setSummaryData({
                hop3sr: response.data?.data?.transactionStats?.hop3sr || 0,
                prepaidGMV: response.data?.data?.transactionStats?.prepaidGMV || 0,
                successfulTxns: response.data?.data?.transactionStats?.successfulTxns || 0,
            })

            if (response.data?.data?.transactions?.length > 0) {
                setTransactions(response.data?.data?.transactions)
                setTotal(response.data?.data?.totalCount)
            } else {
                setTransactions([])
                setTotal(0)
            }
            dispatch<any>(saveFiltersData('transactions', { ...parameters }))
        } catch (error) {
            handleError(error)
        
        }
    }

    useEffect(() => {
            makeTransactionsApiCall(parameters)
    }, [])

    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters)) {
            setParameters({ ...prevFilters })
        }
        logEvent('kwik_payment_transactions_clicked', 'click', 'Kwik Payment Transactions', user_details?.email, merchantDetails?.m_id, merchantDetails?.short_name, user_details?.name, parameters);
    }, [prevFilters])

    const handleSearchClick = (e) => {
        e?.preventDefault()
        const newParameters = {
            ...parameters,
            page: 1,
        }
        setParameters(newParameters)
        makeTransactionsApiCall({ ...newParameters })
    }
    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        makeTransactionsApiCall({ ...parameters, page: current, pageSize })
    }

    const openEdit = (id, data) => {
        setEditOpen(data)
    }

    const onUpdateStatus = async (data) => {
        let checkPayload = {
            id: data.id,
            ...(data.cust_ref_id && { custRefId: data.cust_ref_id }),
            ...(data.npc_id && { npcId: data.npcId }),
        };
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.transactionCheckStatus,
            payload: {
                id: data.id,
                ...(data.cust_ref_id && { custRefId: data.cust_ref_id }),
                ...(data.npc_id && { npcId: data.npcId }),
            },
        })
        if (response?.data?.status_code === 200) {
            message.success(response.data.message)
        } else {
            message.error(response?.response?.data.message)
        }
    }

    return (
        <>
            <div className='w-full h-full'>
                <div className='overflow-auto'>
                    {isMerchantUser &&
                    (
                    <SummaryBlocks
                        data={[
                            {
                                heroValue: summaryData?.successfulTxns,
                                title: 'Successful Transactions',
                            },
                            {
                                heroValue: summaryData?.prepaidGMV,
                                title: 'Prepaid GMV',
                                heroValueTransform: 'amount',
                            },
                            {
                                heroValue: summaryData?.hop3sr,
                                title: 'HOP 3 SR',
                                heroValueTransform: 'percentage',
                            },
                        ]}
                    />
                    )}
                    <div className='bg-white rounded'>
                        <RenderSearchFilters
                            values={parameters}
                            setValues={(data, reset) =>
                                setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))
                            }
                            page='transactions'
                            onSearch={handleSearchClick}
                            onReset={reset}
                        />
                    </div>
                    <div className='bg-white rounded overflow-clip'>
                        <Table
                            columns={
                                isMerchantUser
                                    ? transactionColumnsMerchant
                                    : [
                                          ...transactionColumnsInternal,
                                          {
                                              title: <span>Actions</span>,
                                              dataIndex: 'id',
                                              fixed: 'right',
                                              render: (id, record) => {
                                                  return (
                                                      <Row className={`m-0 `}>
                                                          { 
                                                            ['pending', 'timeout', 'payment_initiated'].includes(
                                                                record?.bank_status?.toLowerCase(),
                                                            ) && (
                                                                <> {validatePermission('update_status_transactions') && (
                                                                    <Tooltip title='Update Transaction'>
                                                                        <FieldTimeOutlined
                                                                            onClick={() => {
                                                                                setModalOpen(record)
                                                                            }}
                                                                            style={{ color: 'green' }}
                                                                        />
                                                                    </Tooltip>
                                                                    )}
                                                                    {validatePermission('edit_transactions') && (
                                                                    <Tooltip title='Edit Transaction'>
                                                                        <EditOutlined
                                                                            onClick={() => openEdit(id, record)}
                                                                            style={{ color: 'red' }}
                                                                            className='ml-4'
                                                                        />
                                                                    </Tooltip>
                                                                    )}
                                                                </>
                                                            )}
                                                      </Row>
                                                  )
                                              },
                                          },
                                      ]
                            }
                            dataSource={transactions || []}
                            style={{
                                width: '100vw',
                            }}
                            pagination={{
                                current: parameters.page, // Current page number
                                pageSize: parameters.pageSize, // Number of items to display per page
                                total: total, // Total number of items in the data array
                                showSizeChanger: false,
                                onChange: handlePaginationChange,
                                position: ['topLeft', 'bottomLeft'],
                                showTotal: (total, range) => (
                                    <p className='ml-4'>{`Showing ${range[0]}-${range[1]} of ${total} Records`}</p>
                                ),
                            }}
                            scroll={{ x: 'max-content' }}
                        />
                    </div>
                </div>
            </div>
            {editOpen && (
                <EditStatusDrawer
                    title='Update Transaction'
                    heading={
                        <>
                            <h4 className='fs16 mt-0'>
                                Order #:{' '}
                                <Tooltip title={editOpen?.order_number} className='text-blue'>
                                    {editOpen?.order_number}
                                </Tooltip>
                            </h4>
                            <h5 className='fs14'>
                                Transaction ID:{' '}
                                <span className='text-blue'>
                                    {' '}
                                    <Tooltip>{editOpen?.payment_id} </Tooltip>
                                </span>
                            </h5>
                        </>
                    }
                    open={!!editOpen}
                    onClose={(refetch: boolean) => {
                        refetch && makeTransactionsApiCall(parameters)
                        setEditOpen(false)
                    }}
                    recordData={editOpen}
                    type='transaction'
                />
            )}
            <Modal
                title={<p className='fs14'>Are your sure, you want to update the status ?</p>}
                open={modalOpen ? true : false}
                onOk={() => {
                    onUpdateStatus(modalOpen)
                    setModalOpen(null)
                }}
                onCancel={() => {
                    setModalOpen(null)
                }}
                footer={() => (
                    <Row className='flex justify-end gap-2'>
                        <Button onClick={() => setModalOpen(null)} variant='default'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                onUpdateStatus(modalOpen)
                                setModalOpen(null)
                            }}
                            variant='primary'
                        >
                            Confirm
                        </Button>
                    </Row>
                )}
                centered
                width={'400px'}
            >
                <h3 className='mt-1'>
                    Order number # <span className='text-blue'>{modalOpen?.order_number}</span>{' '}
                </h3>
                <h3 className='mt-1'>
                    Payment ID <span className='text-blue'>{modalOpen?.payment_id}</span>{' '}
                </h3>
            </Modal>
        </>
    )
}
