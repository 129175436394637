import DistributionChart from './methodwiseSplitGraph'
import DistributionTable from './methodwiseSplitTable'
import PaymentPageFunnel from './paymentPageFunnel'
import FailureReasonsComponent from './topFailureReasons'

export const SummaryBlocksData: {
    heading: string
    tooltipText: string
    dataKey: string
    nestedKey?: string
    heroValueTransform?: 'percentage' | 'number' | 'amount'
}[] = [
    {
        heading: 'Total orders',
        tooltipText: 'Sum of orders during the period',
        dataKey: 'summary',
        nestedKey: 'total_orders',
        heroValueTransform: 'number',
    },
    {
        heading: 'Total GMV',
        tooltipText: 'Total GMV during the period',
        dataKey: 'summary',
        nestedKey: 'total_gmv',
        heroValueTransform: 'amount',
    },
    {
        heading: 'Prepaid %',
        tooltipText: 'Total prepaid orders/Total orders',
        heroValueTransform: 'percentage',
        dataKey: 'summary',
        nestedKey: 'prepaid_percentage',
    },
    {
        heading: 'Prepaid orders',
        tooltipText: 'Total prepaid orders',
        heroValueTransform: 'number',
        dataKey: 'summary',
        nestedKey: 'prepaid_orders',
    },
    {
        heading: 'Prepaid GMV',
        tooltipText: 'Total prepaid GMV',
        dataKey: 'summary',
        nestedKey: 'prepaid_gmv',
        heroValueTransform: 'amount',
    },
    {
        heading: 'Prepaid Success %',
        tooltipText: 'Total prepaid orders/(Total success + failed attempts)',
        dataKey: 'summary',
        nestedKey: 'success_rate',
        heroValueTransform: 'percentage',
    },
]
export const Charts: {
    [key: string]: any
    chartProps?: {
        chartType: string
        labelType: 'percentage' | 'number' | 'amount'
        dashArray?: number[]
        colors?: string[]
    }
}[] = [
    {
        dataKey: 'paymentFunnel',
        configKey: 'funnel',
        dynamicHeading: (value) => {
            const headings = {
                funnel: 'Payment Page Funnel',
                line: 'Payment Funnel Trends',
            }
            return headings[value.view_mode]
        },
        dynamicTooltipText: (value) => {
            const tooltips = {
                funnel: `Analyse funnel step throughs & drop offs at different stages on payment page`,
                line: 'Analyse method selection and payment conversion trends over time',
            }
            return tooltips[value.view_mode]
        },
        component: PaymentPageFunnel,
        chartProps: {
            chartType: 'line',
            labelType: 'percentage',
            colors: ['var(--primary-blue-100)', 'var(--primary-blue-400)', 'var(--primary-blue-800)']
        },
        seriesOptions: [
            {
                name: 'Prepaid selection %',
                dataKey: 'prepaid_percentage',
            },
            {
                name: 'COD selection %',
                dataKey: 'cod_percentage',
            },
            {
                name: 'Payment page conversion %',
                dataKey: 'conversion_percentage',
            }
        ],
        dynamicFilters: (dates, value) => {
            const filters: any[] = [
                {
                key: 'view_mode',
                value: 'funnel',
                options: [
                    { label: 'Funnel View', value: 'funnel' },
                    { label: 'Line View', value: 'line' },
                ],
            }];
            if(value?.view_mode === 'line') {
                filters.push({
                    key: 'resolution',
                    value: 'h',
                    options: [
                        { label: 'Hourly', value: 'h', disabled: dates.to.diff(dates.from, 'days') > 2 },
                        { label: 'Daily', value: 'd' },
                        { label: 'Weekly', value: 'w' },
                    ],
                });
            }
            return filters;
        },
    },
    {
        dataKey: 'ratesGraph',
        configKey: 'rateGraph',
        dynamicHeading: (value) => {
            const headings = {
                success: 'Success %',
                conversion: 'Attempt Success %',
            }
            return headings[value.rate_type]
        },
        dynamicTooltipText: (value) => {
            const tooltips = {
                success: 'Total prepaid orders/(Total success + failed attempts)',
                conversion: 'Total prepaid orders/Total prepaid attempts',
            }
            return tooltips[value.rate_type]
        },
        chartProps: {
            chartType: 'line',
            labelType: 'percentage',
            dashArray: [0, 3],
        },
        dynamicSeriesOptions: (filterData) =>
            filterData.payment_method === 'All'
                ? [
                      {
                          name: 'Overall',
                          dataKey: 'overall_rate',
                          addOnTooltip: 'overall_attempts',
                      },
                  ]
                : [
                      {
                          name: filterData.payment_method,
                          dataKey: 'method_rate',
                          addOnTooltip: 'method_attempts',
                      },
                      {
                          name: 'Overall',
                          dataKey: 'overall_rate',
                          addOnTooltip: 'overall_attempts',
                      },
                  ],

        dynamicFilters: (dates) => [
            {
                key: 'rate_type',
                value: 'success',
                options: [
                    { label: 'Success %', value: 'success' },
                    { label: 'Attempt Success %', value: 'conversion' },
                ],
            },
            {
                key: 'resolution',
                value: 'd',
                options: [
                    { label: 'Hourly', value: 'h', disabled: dates.to.diff(dates.from, 'days') > 2 },
                    { label: 'Daily', value: 'd' },
                    { label: 'Weekly', value: 'w' },
                ],
            },
        ],
    },
    {
        dataKey: 'distributionGraph',
        configKey: 'paymentMethodwiseSplitGraph',
        dynamicHeading: (value) => {
            const headings = {
                orders: 'Orders Distribution',
                method_selected: 'Method Selection Distribution',
                payment_attempted: 'Method Attempt Distribution',
                gmv: 'GMV Distribution',
            }
            return headings[value.split_by]
        },
        dynamicTooltipText: (value) => {
            const tooltips = {
                orders: 'Orders distribution across payment methods',
                method_selected: 'Method selection distribution across payment methods',
                payment_attempted: 'Method attempt distribution across payment methods',
                gmv: 'GMV distribution across payment methods',
            }
            return tooltips[value.split_by]
        },
        component: DistributionChart,
        dynamicFilters: (dates) => [
            {
                key: 'split_by',
                value: 'orders',
                options: [
                    { label: 'Orders', value: 'orders' },
                    { label: 'Attempts', value: 'payment_attempted' },
                    { label: 'GMV', value: 'gmv' },
                ],
            },
            {
                key: 'resolution',
                value: 'h',
                options: [
                    { label: 'Hourly', value: 'h', disabled: dates.to.diff(dates.from, 'days') > 2 },
                    { label: 'Daily', value: 'd' },
                    { label: 'Weekly', value: 'w' },
                ],
            },
        ],
    },
    {
        heading: 'Payment Method Distribution Summary',
        dataKey: 'distributionTable',
        configKey: 'paymentMethodwiseSplitTable',
        tooltipText: `Summary of payment method distribution & key metrics`,
        component: DistributionTable,
    },
    {
        heading: 'Prepaid Drop-offs',
        dataKey: 'dropOffGraph',
        configKey: 'dropOffSplit',
        tooltipText: `Graph shows the trend of failure (prepaid attempts failure) versus intent (prepaid attempts with no failures) user drop-offs in prepaid attempts`,
        chartProps: {
            chartType: 'line',
            labelType: 'percentage',
        },
        seriesOptions: [
            {
                name: 'Intent drop off',
                dataKey: 'intent_drop_percentage',
                addOnTooltip: 'intent_drop_count',
            },
            {
                name: 'Failure drop off',
                dataKey: 'failure_drop_percentage',
                addOnTooltip: 'failure_drop_count',
            },
        ],
        dynamicFilters: (dates) => [
            {
                key: 'resolution',
                value: 'd',
                options: [
                    { label: 'Hourly', value: 'h', disabled: dates.to.diff(dates.from, 'days') > 2 },
                    { label: 'Daily', value: 'd' },
                    { label: 'Weekly', value: 'w' },
                ],
            },
        ],
    },
    {
        heading: 'Failure Distribution (beta)',
        dataKey: 'failureReasons',
        configKey: 'failureReasons',
        tooltipText: `Review top failure reasons recorded for prepaid order failure`,
        component: FailureReasonsComponent,
    },
]
