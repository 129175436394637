import React, { useEffect, useRef } from 'react'
import { BrowserRouter } from 'react-router-dom'
import 'gokwik-ui-kit/dist/esm/bundle.css'
import './main.css'
import { Provider, useDispatch } from 'react-redux'
import store from './store'
import { setFilters } from './store/filters'
import { dashboardStore, filterStore } from '@gokwik/utilities'
import { setUserData } from '@store/user'
import Routes from './routes'

const InitialiseStore = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setFilters(filterStore.getState().filters?.checkout || {}))
        dispatch(setUserData(dashboardStore.getState().userData || {}))
        const unsubcribe = dashboardStore.subscribe((state) => {
            dispatch(setUserData(state.userData))
        })
        return () => unsubcribe?.()
    }, [])
    return <></>
}
export default function Root(props: any) {
    const appContainerRef = useRef<HTMLDivElement>()
    useEffect(() => {
        setTimeout(() => {
            appContainerRef.current.style.maxWidth = `calc(100vw - ${
                document.getElementById('side-nav').getBoundingClientRect().width
            }px)`
        }, 100)
    }, [])
    return (
        <Provider store={store}>
            <div
                className='app-container max-h-screen h-screen  overflow-y-auto pt-20 px-5 w-full bg-[#003c7138]'
                ref={appContainerRef}
                style={{
                    maxWidth: `calc(100vw - ${document.getElementById('side-nav')?.offsetWidth}px)`,
                }}
            >
                <InitialiseStore />
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
            </div>
        </Provider>
    )
}
