export const WALLETS = [
  { name: 'Airtel Money', key: 'wallet-airtelmoney' },
  { name: 'Amazon Pay', key: 'wallet-amazonpay' },
  { name: 'Freecharge', key: 'wallet-freecharge' },
  { name: 'HDFC PayZapp', key: 'wallet-hdfcpayzapp' },
  { name: 'ITZ Cash', key: 'wallet-itzcash' },
  { name: 'Jio Money', key: 'wallet-jiomoney' },
  { name: 'MobiKwik', key: 'wallet-mobikwik' },
  { name: 'Ola Money', key: 'wallet-olamoney' },
  { name: 'Oxigen', key: 'wallet-oxigen' },
  { name: 'Paytm', key: 'wallet-paytm' },
  { name: 'PhonePe', key: 'wallet-phonepe' },
  { name: 'Yes Bank', key: 'wallet-yesbank' },
];