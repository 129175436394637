const APIEndPoints = {
    getFilters: '/api/dashboard/dashboard_filters',
    getBulkRefund: '/api/dashboard/refund/bulk',
    getRefunds: '/api/dashboard/refund/all',
    cancelRefundList: '/api/dashboard/refund/cancel-list',
    getTransactions: '/api/dashboard/transaction/all',
    getTransaction: '/api/dashboard/transaction/',
    getSettlements: '/api/dashboard/settlement/all',
    initiateSettlement: '/api/dashboard/settlement/merchant/initiate',
    getMerchantList: '/api/dashboard/merchant/merchant-list',
    refundDangledTransaction: '/api/dashboard/refund/dangle-transaction',
    getDangledTransactions: '/api/dashboard/transaction/dangle',
    updateStatus: (type: string) => `/api/dashboard/${type}/update-status`,
    transactionCheckStatus: '/api/dashboard/transaction/status-check',
    refundPresignedUrl: '/api/dashboard/refund/presigned-url',
    initiateBulkRefund: '/api/dashboard/refund/initiate-bulk-refund',
    downloadBulkRefund: '/api/dashboard/refund/download-url',
    paymentLinks: {
        get: '/api/dashboard/payment-links',
        refundInitiate: '/api/dashboard/payment-link/initiate',
        detailsById: '/api/dashboard/payment-link/details',
        create: '/api/dashboard/payment-link/create',
    },
    paymentAnalytics: {
        summary: '/api/dashboard/merchantStats/payment-summary-metrics',
        paymentFunnel: '/api/dashboard/merchantStats/payment-funnel-metrics',
        ratesGraph: '/api/dashboard/merchantStats/rates-graph',
        distributionGraph: '/api/dashboard/merchantStats/payment-methodwise-split-graph',
        distributionTable: '/api/dashboard/merchantStats/payment-methodwise-split-table',
        dropOffGraph: '/api/dashboard/merchantStats/drop-off-graph',
        failureReasons: '/api/dashboard/merchantStats/top-failure-reasons',
    },
    paymentProviders: '/api/dashboard/payment-providers',
    merchantConfigs: '/api/dashboard/merchant-config', //get, post
    paymentMethodChange: '/api/dashboard/request-payment-config-change',
    retryRefund: '/api/dashboard/refund/retry',
    settlementStatus: '/api/dashboard/settlement/check-status',
    refundStatus: '/api/dashboard/refund/check-status',
    settlementRetry: '/api/dashboard/settlement/retry',
    processRefund: '/api/dashboard/refund/process',
    triggerChargeback: '/api/dashboard/refund/chargeback',
    paymentTesting: {
        getToken: '/testing/token',
        createTest: '/testing/create-test',
        getLastTestResults: '/testing/get-last-tests',
        abortTest: '/testing/abort-test',
    },
    terminalConfigs: {
        getTerminals: (merchantId: string) => `/v1/merchants/${merchantId}/terminals`,
        deleteTerminal: (id: string) => `/v1/terminals/${id}`,
        getBluePrints: (provider: string) => `/v1/terminals/blueprints/${provider}`,
        createTerminal: '/v1/terminals',
        getTerminal: (id: string) => `/v1/terminals/${id}`,
        updateTerminal: (id: string) => `/v1/terminals/${id}`,
        createGlobalTerminal: '/v1/terminals/global',
        updateGlobalTerminal: (id: string) => `/v1/terminals/global/${id}`,
        supportedAccounts: () => `/v1/terminals/split-settlements/supported-accounts`
    },
    supportedProviders: '/v1/terminals/supported',
    userEvent: '/api/dashboard/event',
    syncTerminal: '/v1/methods-onboarding',
    getNetbankingMappings: (mId: string) => `/v1/netbanking/${mId}/banks`,
    updateMappings: (mId: string) => `/v1/netbanking/${mId}/banks`,
    routing: {
        getMethods: (mId: string) => `/v1/payment/routes/${mId}/methods`,
        getRoutes: (mId: string, method: string) => `/v1/payment/routes/${mId}/methods/${method}`,
        updateRoute: (mId: string, routeId: number) => `/v1/payment/routes/${mId}/${routeId}`,
        getInstruments: (mId: string, methodName: string) =>
            `/v1/payment/routes/${mId}/methods/${methodName}/instruments`,
        createRoute: (mId: string) => `/v1/payment/routes/${mId}`,
        deactivateRoute: (mId: string, terminalId: string) => `/v1/payment/routes/${mId}/${terminalId}/deactivate`,
        supportedProviders: (mId: string, methodName: string, instrumentName: string) =>
            `/v1/payment/routes/${mId}/methods/${methodName}/instruments/${instrumentName}`,
    },
    splitSettlements: {
        getMerchantInvoices: (mId: string) => `/v1/collection-invoices/${mId}`,
        getAvailableAccounts: (mId: string) => `/v1/collection-invoices/${mId}/recovery-accounts`,
        createInvoice: (mId: string) => `/v1/collection-invoices/${mId}`,
        updateInvoice: (mId: string, invoiceId: string) => `/v1/collection-invoices/${mId}/${invoiceId}`,
        getCollectionSummary: (mId: string, invoiceId: string) => `/v1/collection-invoices/${mId}/${invoiceId}/summary`,
        getCollections: (mId: string, invoiceId: string, pageSize = 10, lastDate: string) =>
            `/v1/collection-invoices/${mId}/${invoiceId}/day-wise-deductions?page_size=${pageSize}${
                lastDate ? `&last_date=${lastDate}` : ''
            }`,
        getLogs: (mId: string, invoiceId: string,) => `/v1/collection-invoices/${mId}/${invoiceId}/change-logs`    
    },
    offers: {
        getAllOffers: '/v1/offers',
        merchantOffers: '/v1/merchant-offer-configs',
        offerDetails: (id: string) => `/v1/offers/${id}`,
        addMerchants: '/v1/offers/add-merchants',
        removeMerchants: '/v1/offers/remove-merchants',
    },
    settlements: {
        summary: '/v1/settlement-dashboard/summary',
        listing: '/v1/settlement-dashboard/list',
        search: '/v1/settlement-dashboard/search-settlement',
        details: '/v1/settlement-dashboard/details',
    },
}
export default APIEndPoints
