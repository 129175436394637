export const PermissionValues = {
    refunds: {
        view: 'view_refund',
        initiateRefund: 'refund',
    },
    settlements: {
        view: 'view_settlements',
    },
    transactions: {
        view: 'view_transactions',
    },
    paymentAnalytics: {
        view: 'view_payment_analytics',
    },
    paymentLinks: {
        view: 'view_payment_links',
        create: 'create_payment_links'
    },
}

export const MerchantConfigKeys = {
    paymentAnalytics: {
        view: 'show_payment_analytics_v2',
        selectedCharts: 'selected_payment_v2_charts',
    },
}

export enum PaymentLinkModeEnum {
    UPI_DEEPLINK = 'upi-deeplink',
    STANDARD = 'standard',
}
export const PaymentProviders = {
    SNAPMINT: 'snapmint',
    BILLDESK: 'billdesk',
    BHARATX: 'bharatx',
    CREDPAY: 'credpay',
    EASEBUZZ: 'easebuzz',
    PAYU: 'payu'
}

export const PaymentMethods = {
    CREDIT_CARD: 'credit_card',
    DEBIT_CARD: 'debit_card',
}

export const SuccessStatusCodes = [201, 200]