import {
    Drawer,
    Form,
    Input,
    Switch,
    Button,
    Modal,
    Row,
    message,
    Col,
    DatePicker,
    Checkbox,
    Select,
    InfoCircleOutlined,
    Tooltip,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { makeAPICall } from '@gokwik/utilities'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails, isMerchantSelector } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import dayjs from 'dayjs'
import { emailRegex, phoneRegex } from '@library/utilities/constants/regex'
import { handleError } from '@library/utilities/helpers/handleError'
import { PaymentLinkModeEnum } from '@library/utilities/constants/constants'

const currencySymbolMapping = {
    INR: '₹',
}
// to be taken from backend in future
const availableCurrencies = Object.keys(currencySymbolMapping).map((key) => {
    return { value: key, label: currencySymbolMapping[key] }
})
const frequencyForReminder = [1, 2, 4, 8].map((i) => ({ value: i, label: `${i}` }))

function createPaymentLinkDrawer({ onClose, openCreatePaymentLinkDrawer, setSuccessModal, setCreatedPaymentLink }) {
    const defaultInputValue = {
        amount: '',
        currency: 'INR',
        name: null,
        phone: null,
        email: '',
        description: null,
        reference_id: null,
        expiry: dayjs(Date.now() + 24 * 60 * 60 * 1000), // Set default expiry to current time + 24 hours
        notify: {
            sms: true,
            email: false,
            whatsapp: false,
        },
        reminder: {
            noReminder: false,
            frequency: 1,
            timeUnit: 'hours',
        },
        mode: PaymentLinkModeEnum.STANDARD,
    }
    const [paymentLinkData, setPaymentLinkData] = useState(defaultInputValue)
    const [proceed, setProceed] = useState(false)
    const [form] = Form.useForm()
    const merchantData = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)

    const onCheck = async () => {
        try {
            await form.validateFields()
            setProceed(true)
        } catch (errorInfo) {
            const { errorFields } = errorInfo
            if (errorFields?.length > 0) {
                const firstErrorField = errorFields[0]?.name[0]
                form.scrollToField(firstErrorField, {
                    behavior: 'smooth',
                    block: 'center',
                })
                message.error('Please check the fields and try again.')
            }
        }
    }

    const disabledDate = (current) => {
        return current && current < new Date().setHours(0, 0, 0, 0)
    }

    const getDisabledHours = () => {
        const now = new Date()
        const disabledHours = []
        for (let i = 0; i < now.getHours(); i++) {
            disabledHours.push(i)
        }
        return disabledHours
    }

    const getDisabledMinutes = (current) => {
        const now = new Date()
        const disabledMinutes = []
        if (current && current.hour() === now.getHours()) {
            for (let i = 0; i < now.getMinutes(); i++) {
                disabledMinutes.push(i)
            }
        }
        return disabledMinutes
    }

    const disabledDateTime = (current) => {
        const now = new Date()
        const isSameDay = current && current.toDate().toDateString() === now.toDateString()
        return {
            disabledHours: () => (isSameDay ? getDisabledHours() : []),
            disabledMinutes: () => (isSameDay ? getDisabledMinutes(current) : []),
        }
    }

    useEffect(() => {
        if (paymentLinkData.expiry <= dayjs(Date.now() + 24 * 60 * 60 * 1000)) {
            setPaymentLinkData((prev) => ({
                ...prev,
                reminder: {
                    ...paymentLinkData.reminder,
                    timeUnit: 'hours',
                },
            }))
        }
    }, [paymentLinkData.expiry])

    const handleInputChange = (key, e) => {
        try {
            let inputValue
            if (key === 'expiry') {
                inputValue = dayjs(e['$d'])
            } else {
                inputValue = e.target ? e.target.value : e
            }
            setPaymentLinkData((prev) => ({ ...prev, [key]: inputValue }))
        } catch (error) {
            console.error('Error handling input change:', key, error)
        }
    }

    const handleModeChange = (checked: boolean) => {
        setPaymentLinkData((prev) => ({
            ...prev,
            mode: checked ? PaymentLinkModeEnum.STANDARD : PaymentLinkModeEnum.UPI_DEEPLINK,
        }))
    }

    const handleCloseDrawer = () => {
        setPaymentLinkData(defaultInputValue)
        onClose()
    }

    const isStandardMode = () => {
        return paymentLinkData.mode === PaymentLinkModeEnum.STANDARD
    }

    useEffect(() => {
        form.setFieldsValue({
            mode: isStandardMode(),
        })
    }, [paymentLinkData.mode, form])

    const createPaymentLink = async () => {
        try {
            const parameters = {
                merchantId: merchantData?.m_id,
                amount: parseFloat(paymentLinkData.amount),
                currency: paymentLinkData.currency,
                merchantReferenceId: paymentLinkData?.reference_id?.trim(),
                mode: paymentLinkData.mode,
                customer: {
                    name: paymentLinkData.name?.trim(),
                    phone: paymentLinkData.phone,
                    email: paymentLinkData.email,
                },
                notify: paymentLinkData.notify,
                expireAt: paymentLinkData.expiry.unix(),
                description: paymentLinkData?.description?.trim(),
                createdBy: userDetails.email,
            }
            const response = await createPaymentLinkApiCall(parameters)
            if (response?.success) {
                setCreatedPaymentLink(response?.data?.data)
                // uncomment when scheduler changes for payment link notification are live                
                // message.success('User has been notified on the selected communication channel')
                setProceed(false)
                setSuccessModal(true)
                handleCloseDrawer()
            } else {
                message.error(response.response?.data?.message || 'Something went wrong. Try again later!')
            }
        } catch (error) {
            handleError(error)
        }
    }

    const createPaymentLinkApiCall = async (parameters) => {
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.paymentLinks.create,
                payload: parameters,
            })
            return response
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <>
            <Drawer
                title='Create Payment Link'
                placement='right'
                onClose={handleCloseDrawer}
                open={openCreatePaymentLinkDrawer}
                width={'30%'}
            >
                <Form layout={'vertical'} form={form} itemProp=''>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please enter amount',
                            },

                            {
                                validator: (_, e) => {
                                    let value = e?.target?.value
                                    if (value && !isNaN(parseFloat(value)) && parseFloat(value) > 20) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(
                                        `Amount must be greater than ${
                                            currencySymbolMapping[paymentLinkData.currency]
                                        }20`,
                                    )
                                },
                            },
                        ]}
                        name='amount'
                        valuePropName='input'
                        label={'Amount'}
                    >
                        <Row gutter={8}>
                            <Col>
                                <Select
                                    className='w-full'
                                    value={paymentLinkData.currency}
                                    onChange={(e) => handleInputChange('currency', e)}
                                    options={availableCurrencies}
                                ></Select>
                            </Col>
                            <Col flex='auto'>
                                <Input
                                    type='number'
                                    defaultValue={'0'}
                                    placeholder={'Enter amount'}
                                    value={paymentLinkData.amount}
                                    onChange={(e) => handleInputChange('amount', e)}
                                />
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item name='name' valuePropName='input' label={'Customer Name'}>
                        <Input
                            placeholder={`Enter customer's name`}
                            value={paymentLinkData.name}
                            onChange={(e) => handleInputChange('name', e)}
                        />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please enter phone number',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, e) {
                                    const value = e?.target?.value
                                    if (phoneRegex.test(value)) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject('Please enter a valid phone number')
                                },
                            }),
                        ]}
                        name='phone'
                        valuePropName='input'
                        label={'Customer Phone'}
                    >
                        <Input
                            placeholder={`Enter customer's phone`}
                            value={paymentLinkData.phone}
                            onChange={(e) => handleInputChange('phone', e)}
                        />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, e) {
                                    const value = e?.target?.value
                                    if (!value || emailRegex.test(value)) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject('Please enter a valid email')
                                },
                            }),
                        ]}
                        name='email'
                        valuePropName='input'
                        label={'Customer Email'}
                    >
                        <Input
                            placeholder={`Enter customer's email (optional)`}
                            value={paymentLinkData.email}
                            onChange={(e) => handleInputChange('email', e)}
                        />
                    </Form.Item>

                    <Form.Item name='description' valuePropName='input' label={'Payment for'}>
                        <Input
                            placeholder={'Description'}
                            value={paymentLinkData.description}
                            onChange={(e) => handleInputChange('description', e)}
                        />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                max: 50,
                                message: 'Reference ID must not exceed 50 characters',
                            },
                        ]}
                        name='reference_id'
                        valuePropName='value'
                        label={'Reference ID'}
                    >
                        <Input
                            placeholder={'Enter reference id (optional)'}
                            value={paymentLinkData.reference_id}
                            onChange={(e) => handleInputChange('reference_id', e)}
                        />
                    </Form.Item>

                    <Form.Item
                        name='expiry'
                        valuePropName='input'
                        label={'Link Expiry'}
                        rules={[
                            {
                                validator(_, value) {
                                    if (!value || new Date(value) > new Date()) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject('Expiry date and time must be greater than the current time')
                                },
                            },
                        ]}
                    >
                        <DatePicker
                            value={paymentLinkData.expiry}
                            format='YYYY-MM-DD HH:mm'
                            onChange={(date, dateString) => handleInputChange('expiry', date)}
                            showTime={{ use12Hours: false }}
                            allowClear={false}
                            disabledDate={disabledDate}
                            disabledTime={disabledDateTime}
                        />
                    </Form.Item>

                    <Form.Item 
                    style={{ display: 'none' }} // uncomment when scheduler changes for payment link notification are live
                    name='notify' 
                    valuePropName='input' 
                    label={'Notify via'}>
                        <Checkbox.Group
                            value={[
                                paymentLinkData.notify.sms ? 'sms' : null,
                                paymentLinkData.notify.email ? 'email' : null,
                                paymentLinkData.notify.whatsapp ? 'whatsapp' : null,
                            ]}
                            onChange={(values) =>
                                setPaymentLinkData((prev) => ({
                                    ...prev,
                                    notify: {
                                        sms: true,
                                        whatsapp: values.includes('whatsapp'),
                                        email: values.includes('email'),
                                    },
                                }))
                            }
                        >
                            <Checkbox value={'sms'}>SMS</Checkbox>
                            <Checkbox value={'email'}>Email</Checkbox>
                            <Checkbox value={'whatsapp'}>WhatsApp</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item
                        style={{ display: 'none' }} // uncomment when scheduler changes for payment link notification are live
                        name='reminder'
                        valuePropName='input'
                        label={'Send payment reminder to user every'}
                    >
                        <Row gutter={8} align={'middle'}>
                            <Col>
                                <Select
                                    value={paymentLinkData.reminder?.frequency}
                                    onChange={(e) => {
                                        setPaymentLinkData((prev) => ({
                                            ...prev,
                                            reminder: {
                                                ...paymentLinkData.reminder,
                                                frequency: e,
                                            },
                                        }))
                                    }}
                                    options={frequencyForReminder}
                                    disabled={!!paymentLinkData.reminder.noReminder}
                                ></Select>
                            </Col>
                            <Col className='mr-5'>
                                <Select
                                    value={paymentLinkData.reminder?.timeUnit}
                                    onChange={(e) => {
                                        setPaymentLinkData((prev) => ({
                                            ...prev,
                                            reminder: {
                                                ...paymentLinkData.reminder,
                                                timeUnit: e,
                                            },
                                        }))
                                    }}
                                    options={
                                        paymentLinkData.expiry <= dayjs(Date.now() + 24 * 60 * 60 * 1000)
                                            ? [{ value: 'hours', label: 'Hours' }]
                                            : [
                                                  { value: 'days', label: 'Days' },
                                                  { value: 'hours', label: 'Hours' },
                                              ]
                                    }
                                    disabled={!!paymentLinkData.reminder.noReminder}
                                ></Select>
                            </Col>
                            <Col>
                                <Checkbox.Group
                                    value={[paymentLinkData.reminder.noReminder ? 'no_reminder' : null]}
                                    onChange={(values) => {
                                        setPaymentLinkData((prev) => ({
                                            ...prev,
                                            reminder: {
                                                ...paymentLinkData.reminder,
                                                noReminder: values.includes('no_reminder'),
                                            },
                                        }))
                                    }}
                                >
                                    <Checkbox value={'no_reminder'}>No Reminder</Checkbox>
                                </Checkbox.Group>
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item
                        name='mode'
                        valuePropName='checked'
                        label={
                            <span>
                                Select Payment Mode
                                <Tooltip title='Toggle to either show only UPI or show all payment methods (UPI, CC/DC, Wallets)'>
                                    <InfoCircleOutlined className='ml-1' />
                                </Tooltip>
                            </span>
                        }
                    >
                        <div className='flex items-center gap-3'>
                            <Switch
                                style={{ backgroundColor: '#004B8D' }}
                                className='bg-primary-500'
                                checkedChildren='All Payment Options'
                                unCheckedChildren='UPI Only'
                                defaultChecked
                                checked={isStandardMode()}
                                onChange={handleModeChange}
                            />
                            <span className='fs12' style={{ flex: 1 }}>
                                {isStandardMode()
                                    ? 'All Payment Methods (UPI, CC/DC, Wallets)'
                                    : 'Only UPI Payment Method'}
                            </span>
                        </div>
                    </Form.Item>

                    <Form.Item className='w-full' name='submit' valuePropName='button'>
                        <Row align={'middle'} justify={'space-between'} gutter={8}>
                            <Col sm={12}>
                                <Button className='w-full' variant='primary' onClick={onCheck}>
                                    Create
                                </Button>
                            </Col>
                            <Col sm={12}>
                                <Button className='w-full' variant='default' onClick={handleCloseDrawer}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Drawer>
            <Modal
                title={<p>Are you sure, you want to create a payment link?</p>}
                open={proceed ? true : false}
                onCancel={() => {
                    setProceed(false)
                }}
                centered
                width={'450px'}
                footer={() => (
                    <Row className='flex justify-end gap-2'>
                        <Button onClick={() => setProceed(null)} variant='default'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                createPaymentLink()
                                setProceed(null)
                            }}
                            variant='primary'
                        >
                            Confirm
                        </Button>
                    </Row>
                )}
            >
                <h3 className='mt-1'>Amount: {paymentLinkData.amount}</h3>
            </Modal>
        </>
    )
}

export default createPaymentLinkDrawer
