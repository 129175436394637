export enum PaymentMethodEnumV2 {
    UPI = 'upi',
    CREDIT_CARD = 'credit_card',
    DEBIT_CARD = 'debit_card',
    BNPL = 'bnpl',
    WALLET = 'wallet',
    NETBANKING = 'netbanking',
    CREDPAY = 'credpay',
    SNAPMINT = 'snapmint',
    BHARATX = 'bharatx',
    CREDIT_CARD_EMI = 'credit_card_emi',
    DEBIT_CARD_EMI = 'debit_card_emi',
    CARDLESS_EMI = 'cardless_emi',
}

export enum PaymentProviderEnum {
    SETU = 'setu',
    YBL = 'ybl',
    ICICI = 'icici',
    PAYU = 'payu',
    EASEBUZZ = 'easebuzz',
    CREDPAY = 'credpay',
    SNAPMINT = 'snapmint',
    TWID = 'twid',
    QWIKCILVER = 'qwik_cilver',
    GYFTR = 'gyftr',
    BILLDESK = 'billdesk',
    REWARD = 'reward',
    BHARATX = 'bharatx',
}

export enum PaymentInstrumentStatusEnum {
    SUCCESS = 'success',
    FAILURE = 'failure',
    PARTIAL_SUCCESS = 'partial_success',
    UNASSESSED = 'Unassessed',
}