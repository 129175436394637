import {
    Table,
    type ColumnProps,
    Col,
    Collapse,
    Row,
    DatePicker,
    Tooltip,
    Button,
    RedoOutlined,
    SearchOutlined,
    DownOutlined,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { convertIsoToLocaleDateString, filterDateFormatter, updateBreadcrumbs } from '@gokwik/utilities'
import dayjs from 'dayjs'
import { getProviderName, getSettlementListing, getSettlementSearchListing, getSettlementSummary } from './settlements.service'
import { getMerchantDetails } from '@store/user/selectors'
import { useDispatch, useSelector } from 'react-redux'
import {
    SettlementSummary,
    SettlementFilters,
    SettlementProviderSummary,
    SettlementListing,
    DateFilters,
} from './types'
import RenderSearchFilters from '@library/components/search-filter'
import { previousSettlementsFiltersNew } from '@store/filters/selectors'
import { saveFiltersData } from '@store/filters'
import ProviderSummary from './providerSummary'
import { Link } from 'react-router-dom'
import { formatAmount } from '@library/utilities/helpers/helper'

const today = dayjs()
const lastWeek = today.subtract(6, 'day')
const formattedTodayDate = filterDateFormatter(today)
const formattedLastWeekDate = filterDateFormatter(lastWeek)
export default function () {
    const merchantData = useSelector(getMerchantDetails)
    const summaryColumns: ColumnProps<any>[] = [
        {
            title: 'Payment provider',
            dataIndex: 'provider',
            render: () => 'All',
        },
        {
            title: 'Total Transactions',
            dataIndex: 'total_transactions',
        },
        {
            title: 'Net Settled Amount',
            dataIndex: 'total_net_settled_amount',
            render: (value) => <p>{formatAmount(value)}</p>,
        },
        {
            title: 'Last Settlement Date',
            dataIndex: 'last_settlement_date',
            render: (value) =>  convertIsoToLocaleDateString(value),
        },
        ...(merchantData?.payments_settlement_dashboard_config?.show_recon_status === true ? [{
            title: 'Reconciled',
            dataIndex: 'recon_percentage',
            render: (value) => `${value}%`,
        }] : []),
    ]
    const providerLevelColumns: ColumnProps<any>[] = [
        {
            title: 'Payment provider',
            dataIndex: 'payment_provider',
            width: 150,
            ellipsis: true,
            render: (value, record) => (
                <Button
                    className='p-0 m-0 !outline-0 truncate text-left'
                    type='link'
                    onClick={() => setSelectedProviderSummary(record)}
                >
                    {getProviderName(value)}
                </Button>
            ),
            fixed: 'left',
        },
        {
            title: 'Total Transactions',
            dataIndex: 'total_successful_transactions',
            render: (val, record) => <p>{Number.isNaN(val) ? '-' : val ? val : record?.total_successful_rewards}</p>,
            sorter: (a, b) => b?.total_successful_transactions - a?.total_successful_transactions
        },
        {
            title: 'Net Settled Amount',
            dataIndex: 'total_net_settled_amount',
            render: (val) => <p>{Number.isNaN(val) ? '-' : formatAmount(val)}</p>,
            sorter: (a, b) => b?.total_net_settled_amount - a?.total_net_settled_amount
        },
        {
            title: 'Last Settlement Date',
            dataIndex: 'last_settlement_date',
            render: (value) => `${ value ? convertIsoToLocaleDateString(value) : '-'}`,
        },
        ...(merchantData?.payments_settlement_dashboard_config?.show_recon_status === true ? [{
            title: 'Reconciled',
            dataIndex: 'recon_percentage',
            render: (value) => `${Number.isNaN(value) ? '-' : value?.toFixed(2)}%`,
        }] : []),
    ]
    const DetailsColumns: ColumnProps<any>[] = [
        {
            title: <span>Settlement ID</span>,
            dataIndex: 'settlement_id',
            width: 150,
            ellipsis: true,
            render: (text: string) => (
                <Tooltip placement='topLeft' title={text}>
                    <Link to={'/payment/settlements/' + text}>{text}</Link>
                </Tooltip>
            ),
            fixed: 'left',
        },
        {
            title: <span>Net Settled Amount</span>,
            dataIndex: 'net_settled_amount',
            render: (val) => <p>{formatAmount(val)}</p>,
        },
        {
            title: <span>UTR Number</span>,
            dataIndex: 'utr_number',
        },
        {
            title: <span>Settled On</span>,
            dataIndex: 'settled_on',
            render: (value) => convertIsoToLocaleDateString(value),
        },
        {
            title: <span>Provider</span>,
            dataIndex: 'payment_provider',
        },
        ...(merchantData?.payments_settlement_dashboard_config?.show_recon_status === true ? [{
            title: <span>Recon Status</span>,
            dataIndex: 'recon_percentage',
            render: (value) => `${value}% Match`,
        }] : []),
    ]
    const [collapseItems, setCollapseItems] = useState([])
    const [isExpanded, setIsExpand] = useState(false)
    const [selectedProviderSummary, setSelectedProviderSummary] = useState<SettlementProviderSummary>(null)
    const [summaryCollapsed, setSummaryCollapsed] = useState<SettlementSummary>(null)
    const [summary, setSummary] = useState<SettlementProviderSummary[]>([])
    const [settlements, setSettlements] = useState<SettlementListing[]>([])
    const [filteredSettlements, setFilteredSettlements] = useState<SettlementListing[]>([])
    const [total, setTotal] = useState(0)
    const [dateFilters, setDateFilters] = useState<DateFilters>({
        start_date: formattedLastWeekDate,
        end_date: formattedTodayDate,
    })
    const prevFilters = useSelector(previousSettlementsFiltersNew)
    const [parameters, setParameters] = useState<SettlementFilters>({
        utr_number: '',
        payment_id: '',
        order_number: '',
        payment_provider: 'all',
        ...prevFilters,
    })
    const dispatch = useDispatch()

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settlements',
                href: '/payment/settlements',
                text: 'Settlements',
            },
        ])
    }, [])

    useEffect(() => {
        fetchSettlementSummary(dateFilters)
        fetchSettlementsList(dateFilters)
    }, [dateFilters?.start_date, dateFilters?.end_date])

    useEffect(() => {
        if((isExpanded || summaryCollapsed) && summary.length > 0) {
            updateCollapseItems(isExpanded)
        }
    }, [isExpanded, summaryCollapsed, summary])

    async function fetchSettlementSummary(params: DateFilters) {
        const queryParams = {
            start_date: filterDateFormatter(params.start_date),
            end_date: filterDateFormatter(params.end_date),
            merchant_id: merchantData?.id
        }
        getSettlementSummary(queryParams, (val) => {
            setSummaryCollapsed(val?.summary)
            const summaryData: SettlementProviderSummary[] = Object.entries(val)
                ?.filter(([key]) => key !== 'summary')
                ?.map(([key, data = {}]) => ({ ...(data as SettlementProviderSummary) }))
            setSummary(summaryData)
        })
    }

    async function fetchSettlementsList(params: DateFilters) {
        const queryParams = {
            start_date: filterDateFormatter(params.start_date),
            end_date: filterDateFormatter(params.end_date),
            merchant_id: merchantData?.id
        }
        getSettlementListing(queryParams, (data) => {
            setSettlements(data)
            setTotal(data?.length)
        })
    }

    const getAppliedFilters = (params: SettlementFilters) => {
        const { payment_id, order_number, utr_number } = params
        const filteredParams: Partial<SettlementFilters> = {}

        if (payment_id) {
            filteredParams.payment_id = payment_id
        }
        if (order_number) {
            filteredParams.order_number = order_number
        }
        if (utr_number) {
            filteredParams.utr_number = utr_number
        }
        return filteredParams
    }

    async function fetchSearchedSettlementsList(params: SettlementFilters) {
        const filteredParams = getAppliedFilters(params)
        if (Object.keys(filteredParams).length === 0) return
        getSettlementSearchListing(filteredParams, (data) => {
            setSettlements(data)
            setTotal(data?.length)
        })
        dispatch<any>(saveFiltersData('settlements_new', { ...params }))
    }

    const handleDateChange = (dates: any) => {
        const [start_date, end_date] = dates
        setDateFilters({ start_date: start_date, end_date: end_date })
    }

    const handlePaginationChange = (page: number, pageSize?: number) => {
        const params = { ...parameters, page, pageSize }
        setParameters(params)
    }

    const handleSearchClick = (e) => {
        e?.preventDefault()
        const params = { ...parameters, page: 1 }
        setParameters(params)
        fetchSearchedSettlementsList(params)
    }

    const handleSearchFilterChange = (data, reset) => {
        if (Object.keys(data).includes('payment_provider')) {
            if(data.payment_provider === 'all' || !data.payment_provider) {
                setFilteredSettlements([])
                setParameters((prev) => ({
                    ...prev,
                    payment_provider: 'all',
                }))
                return;
            }
            setFilteredSettlements(settlements.filter((settlement) => settlement.payment_provider === data.payment_provider));
            setParameters((prev) => ({
                ...prev,
                payment_provider: data.payment_provider,
            }))
            return;
        }
        setParameters((prev) => {
            const params = {
                ...(reset ? {} : prev),
                ...data,
                payment_provider: data.payment_provider || 'all',
            }
            const filteredParams = getAppliedFilters(params)
            if (Object.keys(filteredParams).length === 0) {
                fetchSettlementsList(dateFilters)
            }
            return params;
        })
    }

    const resetSearchFilters = (e?: any) => {
        e?.preventDefault()
        const params = {
            utr_number: '',
            payment_id: '',
            order_number: '',
            payment_provider: 'all',
        }
        setParameters(params)
        fetchSettlementsList(dateFilters)
    }

    function updateCollapseItems(isExpanded: boolean) {
        let items = []
        try {
            items.push({
                label: (
                    <section className='flex flex-col justify-center w-full'>
                        <section className='flex justify-start'>
                            <p className='text-md font-medium'>Provider Level Summary</p>
                        </section>
                        {!isExpanded && (
                            <div className='w-full border-1 border-solid border-[#d9d9d9] rounded-lg overflow-hidden bg-white mt-2'>
                                <div className='flex w-full border-0 border-b border-solid border-[#d9d9d9] font-semibold text-left bg-white'>
                                    {summaryColumns.map((column, index) => (
                                        <div
                                            key={index}
                                            className='w-1/4 flex-1 p-3 text-[#737a87] font-medium bg-[#fafafa]'
                                        >
                                            {typeof column.title === 'function' ? column.title({}) : column.title}
                                        </div>
                                    ))}
                                </div>
                                {!!summaryCollapsed ? (
                                    <div className='flex w-full'>
                                        {summaryColumns.map((column, index) => (
                                            <div key={index} className='w-1/4 flex-1 p-3'>
                                                {typeof column.render === 'function'
                                                    ? column.render(
                                                          summaryCollapsed?.[column.dataIndex] ?? '',
                                                          summaryCollapsed,
                                                          column.dataIndex,
                                                      )
                                                    : summaryCollapsed?.[column.dataIndex] ?? ''}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className='flex justify-center items-center p-8'>
                                        <div className='text-center'>
                                            <p className='text-gray-500'>No data</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </section>
                ),
                children: (
                    <section className='bg-white'>
                        <Row>
                            <Col span={24}>
                                <Table
                                    rowKey='invoice_id'
                                    columns={providerLevelColumns}
                                    dataSource={summary}
                                    style={{
                                        width: '100vw',
                                    }}
                                    pagination={false}
                                    scroll={{ x: 'max-content' }}
                                />
                            </Col>
                        </Row>
                    </section>
                ),
            })
        } catch (error) {
            items = []
        }
        setCollapseItems(items)
    }

    return (
        <>
            <div className='w-full h-full'>
                <Row align='middle' justify='space-between'>
                    <h3 className='m-0'>Settlement Summary</h3>
                    <DatePicker.RangePicker
                        size='middle'
                        value={[dayjs(dateFilters.start_date), dayjs(dateFilters.end_date)]}
                        onChange={([start_date, end_date]: any) => {
                            handleDateChange([start_date, end_date])
                        }}
                        format={'DD MMM YYYY'}
                        allowClear={false}
                    />
                </Row>
                <Col span={24}>
                    <div className='mt-4 bg-white rounded overflow-clip'>
                        <Collapse
                            className='rounded-lg'
                            items={collapseItems}
                            expandIconPosition='end'
                            expandIcon={(props) => <DownOutlined className={props?.isActive ? 'rotate-180' : ''} />}
                            onChange={(key) => {
                                const isExpanded = key?.length > 0
                                setIsExpand(isExpanded)
                            }}
                        />
                    </div>
                </Col>
                <Col span={24}>
                    <h3 className=''>Settlements</h3>
                    <div className='mt-4 bg-white rounded overflow-clip'>
                        <RenderSearchFilters
                            values={parameters}
                            setValues={handleSearchFilterChange}
                            page='settlements_new'
                            onSearch={handleSearchClick}
                            onReset={resetSearchFilters}
                            classNames={{ filterWrapper: 'flex-1 min-w-28 !max-w-48' }}
                            extras={{ firstRowJustify: 'start' }}
                        >
                            <Col className='ms-auto flex items-center gap-x-3'>
                                <Button onClick={handleSearchClick} variant='primary'>
                                    <SearchOutlined />
                                    Search
                                </Button>
                                <Button onClick={resetSearchFilters} variant='default'>
                                    <RedoOutlined />
                                    Reset
                                </Button>
                            </Col>
                        </RenderSearchFilters>
                        <Table
                            rowKey='invoice_id'
                            columns={DetailsColumns}
                            dataSource={filteredSettlements.length > 0 ? filteredSettlements : settlements}
                            style={{
                                width: '100vw',
                            }}
                            pagination={{
                                current: 1,
                                pageSize: filteredSettlements.length > 0 ? filteredSettlements.length : total,
                                total: filteredSettlements.length > 0 ? filteredSettlements.length : total,
                                position: ['bottomRight'],
                                showSizeChanger: false,
                                onChange: handlePaginationChange,
                                showTotal: (total, range) => (
                                    <p className='ml-4'>{`Showing ${range[0]}-${range[1]} of ${total} Records`}</p>
                                ),
                            }}
                            scroll={{ x: 'max-content' }}
                        />
                    </div>
                </Col>
            </div>
            <ProviderSummary summary={selectedProviderSummary} setOpen={(val) => setSelectedProviderSummary(val)} />
        </>
    )
}
